import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNavbar from '../../Navbar/Navbar';
import { isMobile as isMobileDevice } from 'react-device-detect';
import LoadingSpinner from '../../../components/common/LoadingSpinner';
import { readCertificate } from '../../../api/Certificate';
import './CertificateReader.css';
import useIsIframe from '../../CounsellorUser/CounsellorsClientPortFolio/common/IsIframe';
import { isUserTokenValid } from '../../../components/common/ValidateToken';

interface Certificate {
  profileName: string;
  email: string;
  skills: string[];
  certificateDetails: string;
  issuedby: string;
  authorizedBy: string;
  certificate: string;
  photoURL: string;
  certificate_id: string;
  verified: boolean;
}

interface ApiResponse {
  message: string;
  success: boolean;
  data: Certificate[];
}

const CertificateReader: React.FC = () => {
  const [isMobile, setIsMobile] = useState(isMobileDevice);
  const [certificates, setCertificates] = useState<Certificate[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const isIframe = useIsIframe();
  const shouldShowMobileView = isMobile || isIframe;
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const fetchCertificates = async () => {
      try {
        const isValid = await isUserTokenValid();
        if (!isValid) {
          navigate('/login');
          return;
        }

        // Get the email from localStorage
        const userEmail = localStorage.getItem('Email'); // Fetch email from localStorage
        if (!userEmail) {
          setError('User email not found in localStorage');
          setIsLoading(false);
          return;
        }

        // Pass the email to the readCertificate function
        const response: ApiResponse = await readCertificate(userEmail);
        if (response.success) {
          setCertificates(response.data);
        } else {
          setError(response.message);
        }
      } catch (err) {
        setError(err instanceof Error ? err.message : 'An error occurred');
      } finally {
        setIsLoading(false);
      }
    };

    fetchCertificates();
  }, [navigate]);

  const handleCertificateClick = (certificateId: string) => {
    console.log('Certificate ID:', certificateId);
    // Redirect to the /verify/:id route with the certificate_id
    navigate(`/verify/${certificateId}`);
  };

  return (
    <div className="certificate-reader">
      {isLoading && (
        <div className="loading-overlay">
          <LoadingSpinner />
        </div>
      )}
      <CreateNavbar page="home" />
      <div className={`certificates-container ${shouldShowMobileView ? 'mobile' : ''}`}>
        <div className="certificates-wrapper">
          {error ? (
            <div className="error">{error}</div>
          ) : certificates.length > 0 ? (
            <div className="certificates-grid">
              {certificates.map((certificate, index) => (
                <div key={index} className="certificate-card">
                  <div className="card-content">
                    <h3 className="certificate-title">{certificate.profileName}</h3>
                    <div className="certificate-info">
                      <p className="certificate-details">
                        <strong>Certificate Details:</strong> {certificate.certificateDetails}
                      </p>
                      <p className="skills">
                        <strong>Skills:</strong> {certificate.skills.join(', ')}
                      </p>
                      <p className="issued-by">
                        <strong>Issued by:</strong> {certificate.issuedby}
                      </p>
                      <p className="authorized-by">
                        <strong>Authorized by:</strong> {certificate.authorizedBy}
                      </p>
                      <p className="certificate-id">
                        <strong>Certificate ID:</strong> {certificate.certificate_id}
                      </p>
                      <p
                        className="certificate-link"
                        onClick={() => handleCertificateClick(certificate.certificate_id)}
                      >
                        View Certificate
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-certificates">
              <p className="no-certificates-text">No certificate records available</p>
            </div>
          )}

        </div>
      </div>
    </div>
  );
};

export default CertificateReader;