import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import defaultAvatar from '../../assets/img/Avatar.png';
import Logo from '../../assets/img/skillJourneyMainLogo.svg';
import homeIcon from '../../assets/img/Contact/home.svg';
import jobsIcon from '../../assets/img/Contact/jobsIcon.svg';
import Training from '../../assets/img/Contact/Training.svg';
import Message from '../../assets/img/Contact/message.svg';
import About from '../../assets/img/Contact/about.svg';
import Fingerprint from '../../assets/img/Contact/Fingerprint.svg';
import Logout from '../../assets/img/Contact/Logout.svg';
import Journey from '../../assets/img/Contact/standard.svg';
import premium from '../../assets/img/Contact/premium.svg';
import back from '../../assets/img/Contact/back.svg';
import feedback from '../../assets/img/Contact/Feedback.svg';
import counseelingLogo from "../../assets/img/counselling.svg";
import resumeLogo from "../../assets/img/resume.png";
import useIsIframe from '../CounsellorUser/CounsellorsClientPortFolio/common/IsIframe';
import portfolio from '../../assets/img/User/Portfolio/portfolio.png'
interface NavbarProps {
  page: string;
  bgColor?: string;
}
export const LogoutMethod: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    localStorage.clear();
    navigate("/")
}, []);
return (
  <>
  </>
);
};

const Navbar: React.FC<NavbarProps> = ({ page, bgColor }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [userProfileIcon, setUserProfileIcon] = useState(() => localStorage.getItem('profileIcon') || defaultAvatar);
  const [isUserLoggedIn, setUserStatus] = useState(false);
  const [userLevel, setUserLevel] = useState({ isPremiumUser: false, status: '' }); // Manage user status

  const location = useLocation();
  const name:any=localStorage.getItem('Name')
  const [showNameTooltip, setShowNameTooltip] = useState<boolean>(false);
  const handleNamePress = () => {
      if ((name?.length ?? 0) > 28) {
          setShowNameTooltip(true);
          setTimeout(() => setShowNameTooltip(false), 3000);
        }
      };

  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileIcon: any = localStorage.getItem("profileIcon");
        setUserProfileIcon(profileIcon);
        const userLevelInStorage = localStorage.getItem('userLevel') || 'standard';
        const email = localStorage.getItem('email');
  
        if (profileIcon && profileIcon !== '') {
          setUserProfileIcon(profileIcon);
          setUserStatus(true);
        }

        if (email) {
          setUserStatus(true);
        }
  
        if (userLevelInStorage === 'HR') {
          setUserLevel({ isPremiumUser: false, status: 'HR' });
          setUserStatus(true);
        } else {
          setUserLevel({
            isPremiumUser: ['Premium', 'Counselor'].includes(userLevelInStorage),
            status: userLevelInStorage,
          });
        }
  
        if (location.pathname === "/") {
          setUserStatus(false);
        }
      } catch (error) {
        console.error('Error fetching user profile icon:', error);
      }
    };
  
    fetchUserProfile();
  }, [location.pathname]);
  //this is the scrolling bug
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'auto'; // Prevent scrolling when menu is open
  };

  const enableScrolling = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = 'auto';
  };

  const premiumnavigatehandler = () => {
    const premiumJourneyStatus = localStorage.getItem("premiumJourneyStatus");
  
    if (premiumJourneyStatus === 'published' || premiumJourneyStatus === 'non-existence') {
      localStorage.setItem('premiumJourneyStatus', 'published')
      navigate("/premiumjourney");
    } else {
      navigate("/premiumjourneyloading");
    }
  };
  

  const toggleDesktopMenu = () => {
    setIsDesktopMenuOpen(!isDesktopMenuOpen);
  };

  const handleCloseDesktopMenu = () => {
    if (isDesktopMenuOpen) {
      setIsDesktopMenuOpen(false);
    }
  };

  const handleLogout = async () => {
    try {
      const isOnQuestionnairePage = location.pathname.startsWith('/journeybuilder');

      if (isOnQuestionnairePage) {
        const userConfirmed = window.confirm(
          "If you have any draft data, it will be lost."
        );
        if (userConfirmed) {
          localStorage.clear();
          navigate('/');
        }
      } else {
        localStorage.clear();
        navigate('/');
      }
    } catch (error) {
      console.error('Logout failed', error);
    }
  };
  const LMSTOKEN=localStorage.getItem("LMSToken")
  const commonLinks = [
    { to: "/", label: "Home" },
    { to: "/portfolio", label: "Portfolio"},
    { to: "https://resume.skilljourney.in/", label: "Resume Builder"},
    { to: LMSTOKEN ? `https://trainings.skilljourney.in/?ssoToken=${LMSTOKEN}` : "https://trainings.skilljourney.in/", label: "Trainings" },
    { to: "/counselling", label: "Counselling" },
    { to: LMSTOKEN ? `https://trainings.skilljourney.in/blog/?ssoToken=${LMSTOKEN}` : "https://trainings.skilljourney.in/blog/", label: "Blog" },
    { to: "/about", label: "About" }
  ];
  
  return (
    <nav className={`${page === 'premiumJourney' ? 'fixed w-full' : 'relative'} ${bgColor || 'bg-white'}`}>
      {isDesktopMenuOpen && (
        <div
          className="fixed inset-0 z-30"
          onClick={handleCloseDesktopMenu}
        ></div>
      )}
      <div className="max-w-full px-4 sm:px-6 lg:px-9">
        <div className="relative flex h-16 items-center justify-between">
          {/* Logo for Mobile View */}
          <div className="absolute inset-y-1 left-0 flex items-center max-custom-md:block custom-md:hidden z-10">
            <a href="/" className="inset-y-10">
              <img src={Logo} alt="Logo" className="h-20 w-[140px] md:w-[150px] md:h-10 mx-auto py-2" />
            </a>
          </div>
          {/* Mobile Menu Button */}
          <div className="absolute inset-y-0 right-2 flex items-center max-custom-md:block custom-md:hidden z-10">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 text-black"
              onClick={toggleMenu}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                  stroke="black"
                />
              </svg>
            </button>
          </div>
          {/* Desktop Menu and Extra Buttons */}
          <div className={`hidden custom-md:flex items-center justify-between w-full ${page !== 'questionnaire' ? 'mx-14' : ''}`}>
            {/* Logo */}
            {page !== 'questionnaire' ? (
              <div className="flex-shrink-0">
                <a href="/" className="inset-y-10">
                  <img src={Logo} alt="Logo" className="w-[160px] md:h-20" />
                </a>
              </div>
            ) : null}

            <div className="flex items-center space-x-4 ml-5">
              {commonLinks.map((link) => (
                <Link key={link.to} to={link.to} className="rounded-md px-3 py-2 text-base font-semibold font-sans text-black">
                  {link.label}
                </Link>
              ))}

              {!isUserLoggedIn ? (
                <Link to="/login" className="rounded-full bg-custom-orange px-3 py-2 text-sm font-medium font-sans text-white">
                  Login
                </Link>
              ) : (
                <div className="relative flex items-center">
                  <img
                    src={userProfileIcon}
                    alt="Avatar"
                    className="h-10 w-10 rounded-full object-cover cursor-pointer"
                    onClick={toggleDesktopMenu}
                  />
                </div>
              )}
            </div>
          </div>
          {/* Grey Overlay */}
          {isMenuOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-30"
              onClick={enableScrolling}
            ></div>
          )}
          {/* Mobile Menu */}
          <div
            className={`fixed top-0 right-0 z-40 h-full w-[85%] md:w-[50%] bg-menuebar shadow-lg transform transition-transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} custom-md:hidden`}
          >
            <div className="relative flex flex-col h-full">
              {/* Centered Logo when Menu is Open */}
              <div className={`py-3 text-xl font-bold font-sans mx-auto w-60 text-black ${isUserLoggedIn ? 'border-b-2 border-bloc-color' : ''} py-2`}>
                {!isUserLoggedIn ? (
                  <a href="/" className="block">
                    <img src={Logo} alt="Journey Icon" className="h-24 w-56 pt-3 py-2" />
                  </a>
                ) : (
                  <div className="flex items-center pt-1 py-1">
                    {isUserLoggedIn && (
                      <img src={userProfileIcon} alt="Avatar" className="h-18 w-12 pt-0 rounded-full object-cover" />
                    )}
                    <div className="flex items-center pt-1 py-1">
                      {isUserLoggedIn && (
                        <>
                          {(name?.length ?? 0) > 18 ? (
                            <div className="relative">
                            <div className="flex flex-col w-44 break-words overflow-hidden" onClick={handleNamePress}>
                              <span className="pl-1 text-black text-sx font-medium line-clamp-2 font-sans break-words">
                                {name || ''}
                              </span>
                                {showNameTooltip && (name?.length ?? 0) > 28 && (<div className="absolute z-50 p-1 px-2 bg-white text-black text-sm rounded-md whitespace-normal max-w-[300px] break-words -bottom-16 right-[-60px]">{name}</div>)}
                              </div>
                            </div>
                          ) : (
                            <span className="pl-1 text-black text-sx font-medium leading-7 font-sans break-words truncate w-44">
                              {name || ''}
                            </span>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                )}
              </div>
              {/* Premium User Text Positioned Slightly Down and Fully to the Left */}
              <div className="relative">
                {isUserLoggedIn && (
                  <div className="text-custom-blue flex justify-center text-xl font-bold font-sans ml-4">
                    {userLevel.status}
                  </div>
                )}
                {/* Adjusted Back Button Circle */}
                <div
                  className={`absolute -left-5 bottom-[-70px] h-16 w-16 rounded-full bg-menuebar flex items-center justify-center cursor-pointer z-50 ${isMenuOpen ? 'block' : 'hidden'}`}
                  onClick={() => {
                    toggleMenu();
                    setIsMenuOpen(false);
                  }}
                >
                  <img
                    src={back}
                    alt="Back Icon"
                    className="h-6 w-6 transform -translate-x-3"
                  />
                </div>
              </div>
              {/* Bottom Items */}
              <div className="flex flex-col flex-grow justify-between p-2 pt-6 h800:pt-20">
                <div className="flex flex-col space-y-px -ml-2">
                  {/* Premium Journey Link - Only show if not on the Premium Journey page */}
                  {isUserLoggedIn && userLevel.isPremiumUser  && userLevel.status !== 'HR' && !window.location.href.includes('/premiumjourney') && (
                    <div className="py-3 h800:py-4 text-base font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color">
                      <div className="flex items-center cursor-pointer pl-7" onClick={premiumnavigatehandler}>
                        <img src={premium} alt="Premium Icon" className="inline-block h-5 w-6 mr-6" />
                        Premium Journey
                      </div>
                    </div>
                  )}
                  {/* Standard Journey Link - Show on Premium Journey but hide on Standard Journey page */}
                  {isUserLoggedIn && userLevel.status !== 'HR' && !window.location.href.includes('/myjourney') &&
                    (window.location.href.includes('/home') || !window.location.href.includes('/premiumJourney')) && (
                      <div className="py-3 h800:py-4 text-base font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color">
                        <div className="flex items-center cursor-pointer pl-7" onClick={() => navigate('/myjourney')}>
                          <img src={Journey} alt="Journey Icon" className="inline-block h-5 w-6 mr-6" />
                          Standard Journey
                        </div>
                      </div>
                    )}
                  {/* Links */}
                  {commonLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.to}
                      className="flex items-center py-3 h800:py-4 text-base font-bold font-sans text-black border-b-2 border-bloc-color mx-auto w-60"
                      onClick={() => setIsMenuOpen(false)}
                    >
                      <div className="flex items-center pl-7">
                        {link.label === 'Home' && (
                          <img src={homeIcon} alt="Home Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Portfolio' && (
                          <img src={portfolio} alt="Portfolio Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Trainings' && (
                          <img src={Training} alt="Training Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Counselling' && (
                          <img src={counseelingLogo} alt="counseelingLogo" className="inline-block h-5 w-6 mr-6" />
                        )}
                       
                        {link.label === 'Blog' && (
                          <img src={Message} alt="Message Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'About' && (
                          <img src={About} alt="About Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Resume Builder' && (
                          <img src={resumeLogo} alt="Resume Builder" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label}
                      </div>
                    </Link>
                  ))}
                  <div className="py-3 h800:py-4 text-base font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color">
                    <a href="https://forms.office.com/pages/responsepage.aspx?id=7O6Egwz4PUysWxEbpZOPOw5hSyQXevpHgsHHAq7nfvVUNzNKSUVBTkNYVVRLQ1NRVTJTUzg3VktRTy4u&route=shorturl" target="_blank" rel="noopener noreferrer">
                      <div className="flex items-center pl-7">
                        <img src={feedback} alt="Journey Icon" className="inline-block h-5 w-6 mr-6" />
                        Feedback
                      </div>
                    </a>
                  </div>

                  {page !== 'header' && !isUserLoggedIn && (
                    <div className="flex flex-col flex-grow justify-between p-4 pt-10 ml-7">
                      <Link to="/login" className="rounded-full bg-custom-orange px-6 py-4 text-2xl font-medium font-sans text-white w-28 h-12 flex items-center justify-center">
                        Login
                      </Link>
                    </div>
                  )}
                </div>
                {page === 'header' && !isUserLoggedIn && (
                  <div className="flex flex-col flex-grow justify-between p-4 pt-10 ml-7">
                    <Link to="/login" className="rounded-full bg-custom-orange px-6 py-4 text-2xl font-medium font-sans text-white w-28 h-12 flex items-center justify-center">
                      Login
                    </Link>
                  </div>
                )}
                <div className="absolute h800:bottom-5 bottom-1">
                  {page !== 'header' && isUserLoggedIn && (
                    <div className="flex items-center cursor-pointer space-x-2 absolute bottom-0 ml-12" onClick={handleLogout}>
                      <img src={Logout} alt="Logout Icon" className="h-6 w-6" onClick={handleLogout} />
                      <span className="text-black text-lg font-bold font-sans">Logout</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Fingerprint Image at the Bottom Left */}
              <div className="absolute bottom-0 right-0">
                <img src={Fingerprint} alt="Fingerprint Icon" className="h-auto w-20 h800:w-28" />
              </div>
            </div>
          </div>
          {/* Desktop Menu */}
          <div
            className={`fixed top-0 right-0 z-40 h-auto w-64 bg-menuebar shadow-lg transform transition-transform ${isDesktopMenuOpen ? '-translate-x-28' : 'translate-x-full'} hidden sm:block`}
            style={{ marginTop: '3.5rem' }}
          >
            <div className="relative flex flex-col h-full">
              <div className={`py-3 text-xl font-bold font-sans mx-auto w-48 text-black ${isUserLoggedIn ? 'border-b-2 border-bloc-color' : ''} py-2`}>
                {page === 'header' ? isUserLoggedIn && (
                  <a href="/" className="block">
                    <img src={Logo} alt="Journey Icon" className="h-24 w-56 pt-3" />
                  </a>
                ) : (
                  <div className="flex items-center justify-center pt-1 py-1">
                    {isUserLoggedIn && (
                    <span className=" text-black text-base font-medium leading-5 line-clamp-2 overflow-hidden font-sans break-words whitespace-normal text-center" title= {name && name.length > 20 ? name : ""}>
                        {name || ""}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="relative">
                {page !== 'header' && !isUserLoggedIn && (
                  <div className=" justify-between p-4 pt-3 ml-7">
                    <Link to="/login" className="rounded-full bg-custom-orange px-6 py-4 text-auto font-medium font-sans text-white w-28 h-10 flex items-center justify-center">
                      Login
                    </Link>
                  </div>
                )}
              </div>
              <div className="relative">
                {page !== 'header' && isUserLoggedIn && (
                  <div className="text-custom-blue flex justify-center text-sm font-bold font-sans ml-4">
                    {userLevel.status}
                  </div>
                )}
              </div>
              <div className="flex flex-col flex-grow justify-between p-4 md:pt-4 lg:pt-2">
                <div className="flex flex-col space-y-px ">
                  {/* Premium Journey Link - Show only if user is Premium and not on Premium Journey page */}
                  {isUserLoggedIn && userLevel.isPremiumUser && userLevel.status !== 'HR' && !window.location.href.includes('/premiumjourney') && (
                    <div className="py-2 text-base font-semibold font-sans mx-auto w-48 text-black border-b-2 border-bloc-color">
                      <div className="flex items-center cursor-pointer pl-2" onClick={() => navigate('/premiumjourney')}>
                        <img src={premium} alt="Premium Icon" className="inline-block h-5 w-6 mr-2" />
                        Premium Journey
                      </div>
                    </div>
                  )}
                  {/* Standard Journey Link - Show on Premium Journey but hide on Standard Journey page */}
                  {isUserLoggedIn && userLevel.status !== 'HR' &&
                    !window.location.href.includes('/myjourney') &&
                    (window.location.href.includes('/home') || !window.location.href.includes('/premiumJourney')) && (
                      <div className="py-2 text-base font-semibold font-sans mx-auto w-48 text-black border-b-2 border-bloc-color">
                        <div className="flex items-center cursor-pointer pl-2 space-x-2" onClick={() => navigate('/myjourney')}>
                          <img src={Journey} alt="Journey Icon" className="h-5 w-6" />
                          <span className="whitespace-nowrap">Standard Journey</span>
                        </div>
                      </div>
                    )}
                  {page !== 'header' && isUserLoggedIn && (
                    <a href="https://forms.office.com/pages/responsepage.aspx?id=7O6Egwz4PUysWxEbpZOPOw5hSyQXevpHgsHHAq7nfvVUNzNKSUVBTkNYVVRLQ1NRVTJTUzg3VktRTy4u&route=shorturl" target="_blank" rel="noopener noreferrer">
                      <div className="py-2 text-base font-semibold font-sans mx-auto w-48 text-black border-b-2 border-bloc-color">
                        <div className="flex items-center pl-2">
                          <img src={feedback} alt="Journey Icon" className="inline-block h-5 w-6 mr-2" />
                          Feedback
                        </div>
                      </div>
                    </a>
                  )}

                  {page !== 'header' && isUserLoggedIn && (
                    <div className="py-2 text-base font-semibold font-sans mx-auto w-48 text-black">
                      <div className="flex items-center cursor-pointer pl-2" onClick={handleLogout}>
                        <img src={Logout} alt="Journey Icon" className="inline-block h-5 w-6 mr-2" />
                        Logout
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

interface CreateNavbarProps {
  page: string;
}

const CreateNavbar: React.FC<CreateNavbarProps> = ({ page }) => {
  const isIframe = useIsIframe();
  if( isIframe){
    return <></>;
  }
  const navbarBgColor = ['counselling', 'trainings', 'contact', 'about', 'privacypolicy', 'questionnaire', 'exploreopportunities'].includes(page) ? '' : 'bg-background';
  return <Navbar page={page} bgColor={navbarBgColor} />;
};

export default CreateNavbar;
