// import React, { useState } from "react";

// interface InputDropdownProps {
//   label: string;
//   name: string;
//   options: { value: string; label: string }[];
//   // selectedValue: string;
//   // onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
//   selectedValues: string[]; // Change from `selectedValue` to `selectedValues`
//   onChange: (selected: string[]) => void;
//   isRequired: boolean;
// }

// const InputDropdown: React.FC<InputDropdownProps> = ({ label, name, options, selectedValues, onChange, isRequired }) => {
//   const [searchText, setSearchText] = useState("");
//   const handleOptionToggle = (value: string) => {
//     if (!value) return;
//     // Add or remove the value from selectedValues array
//     console.log(value)
//     const updatedValues = selectedValues.includes(value)
//       ? selectedValues.filter((v) => v !== value) // Remove value if already selected
//       : [...selectedValues, value]; // Add value if not already selected
//     onChange(updatedValues); // Update parent component
//   };

//   const filteredOptions = options.filter((option) =>
//     option.label.toLowerCase().includes(searchText.toLowerCase())
//   );
//   return (
//     <div>
//       <label className="block mb-2 text-sm font-medium text-gray-900 dark:text-white" htmlFor={name}>{label}</label>
//       <select className="bg-white-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 mb-7"
//        id={name} name={name} value={selectedValues[selectedValues.length - 1] || ""} onChange={(e) => handleOptionToggle(e.target.value)} required={isRequired}  >
//         {options.map((option) => (
//           <option key={option.value} value={option.value}>
//             {option.label}
//           </option>
//         ))}
//       </select>
//       {selectedValues.map((value) => (
//       <label className='invisible-dropdown' >{options.find((option) => option.value === value)?.label}</label>
//     ))}
//     </div>
//   );
// };

// export default InputDropdown;

import React, { useEffect, useRef, useState } from "react";
import ReactMarkdown from 'react-markdown';

interface CustomSearchDropdownProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  selectedValues: string[];
  onChange: (selected: string[]) => void;
  isRequired?: boolean;
  description?: string;
}

const InputDropdown: React.FC<CustomSearchDropdownProps> = ({
  label,
  options,
  selectedValues,
  onChange,
  name,
  description,
  isRequired = false,
}) => {
  const [searchText, setSearchText] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(prev => !prev);
  // Filter options based on the search text
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleOptionToggle = (value: string) => {
    const updatedValues = selectedValues.includes(value)
      ? selectedValues.filter((v) => v !== value) // Remove if already selected
      : [...selectedValues, value]; // Add if not selected
    onChange(updatedValues);
  };

  const handleDropdownToggle = () => setIsOpen((prev) => !prev);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div className="relative w-full " ref={dropdownRef}>
      <label className="block mb-5 text-lg font-medium text-gray-900">
        {label}{isRequired && '*'}
      </label>
      {description && (
        <div className="bg-[#FEF5E6] text-black text-sm p-2 rounded-md my-1">
          <div className={`${!isExpanded ? 'line-clamp-2' : ''}`}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          <span
            onClick={toggleExpanded}
            className="text-black cursor-pointer block text-right mt-1"
          >
            {isExpanded ? 'See less' : 'See more'}
          </span>
        </div>
      )}
      <div
        className="relative bg-white "
        // className="relative bg-white border border-gray-300  shadow-md"
        onClick={handleDropdownToggle}
      >
        <input style={{"zIndex":"9999" ,fontSize:" 0.9rem"}}
          type="text"
          name={name}
          className="w-full p-2.5 text-gray-900 bg-white rounded-lg border border-gray-300 focus:outline-none focus:ring focus:ring-blue-500"
          placeholder="Search or select"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          // onClick={(e) => e.stopPropagation()} // Prevent dropdown from toggling on input click
        />
        <span
          className={`absolute right-3 top-3 transform transition-transform ${
            isOpen ? "rotate-180" : ""
          }`}
        >
          ▼
        </span>
      </div>
      {isOpen && (
        <div className="absolute z-50 w-full bg-white border border-gray-300 rounded-lg shadow-lg max-h-48 overflow-y-auto mt-1" style={{"zIndex":"999999999999999"}}>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option) => (
              <div
                key={option.value}
                className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                  selectedValues.includes(option.value)
                    ? "bg-blue-50 font-medium"
                    : ""
                }`}
                onClick={() => handleOptionToggle(option.value)}
              >
                {option.label}
              </div>
            ))
          ) : (
            <div className="px-4 py-2 text-gray-500">No options found</div>
          )}
        </div>
      )}
      {/* {isRequired && selectedValues.length === 0 && (
        <p className="text-sm text-red-500 mt-2">This field is required</p>
      )} */}
      <div className="mt-2" style={{"zIndex":"9999" ,"position":"relative"}}>
        {selectedValues.map((value) => (
          <span
            key={value} style={{backgroundColor:"#ECFAFD",padding: "10px",
              border: "1px solid #0778B6"}}
            className="inline-block  text-black-800 font-semibold px-3 py-1  mr-2 mb-2"
          >
            {options.find((option) => option.value === value)?.label}
            <button
              className="ml-2 text-blue-500 hover:text-blue-700"
              onClick={() => handleOptionToggle(value)}
            >
              ×
            </button>
          </span>
        ))}
      </div>
    </div>
  );
};

export default InputDropdown;

