import { isUserTokenValid } from "../components/common/ValidateToken";
import { BackendApi } from "./apiConfig/ApiFormatter";
import axios from 'axios';


export const get_counsellor = async ():
    Promise<any> => {
    const apiUrl = BackendApi() + 'get_all_counsellors';
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage
    try {
        const response: any = await fetch(apiUrl, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            }
        });
        const result: any = await response.json();

        if (!result?.data?.counsellors_list) {
            throw new Error('No Counselors Found');
        }

        return result.data.counsellors_list;
    } catch (error) {
        console.error('Error fetching Counsellor Data:', error);
        throw error;  // Optionally, rethrow the error
    }
};

export const fetchBookings = async (): Promise<any> => {
    const apiUrl = BackendApi() + 'fetchBookings';
    const email = localStorage.getItem('Email'); // Fetch the ID token from local storage
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`

            },
            body: JSON.stringify({ "user_email": email }) // Send the body with GET
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data) {
            throw new Error('No Payment Details Found');
        }

        return data.data;
    } catch (error) {
        console.error('Error fetching bookings:', error);
        throw error;  // Optionally, rethrow the error
    }
};

export const fetchCounsellorBookings = async (): Promise<any> => {
    const apiUrl = BackendApi() + 'fetchBookings';
    const email = localStorage.getItem('Email'); // Fetch the ID token from local storage
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`

            },
            body: JSON.stringify({ "counsellor_email": email }) // Send the body with GET
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data) {
            throw new Error('No Payment Details Found');
        }

        return data.data;
    } catch (error) {
        console.error('Error fetching bookings:', error);
        throw error;  // Optionally, rethrow the error
    }
};


export const cancelMeeting = async (
    counsellor_email: string,
    user_email: string,
    date: any,
    time: any,
    timestamp: any
): Promise<any> => {
    const apiUrl = BackendApi() + 'cancelMeeting';
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`

            },
            body: JSON.stringify({ "user_email": user_email, 'counsellor_email': counsellor_email, "date": date, "time": time, "timestamp": timestamp }) // Send the body with GET
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data) {
            throw new Error('No Payment Details Found');
        }

        return data;
    } catch (error) {
        console.error('Error fetching bookings:', error);
        throw error;  // Optionally, rethrow the error
    }
};

export const checkIfCounselor = async (navigate: (path: string, state: any) => void): Promise<any> => {
    const isValid = await isUserTokenValid();
    if (!isValid) navigate('/login', { state: { page: "counselling" } });

    const apiUrl = BackendApi() + 'isCounselor';
    const email = localStorage.getItem('Email');
    const idToken = localStorage.getItem('AccessToken');
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            },
            body: JSON.stringify({ "email": email })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data) {
            throw new Error('No counselor status returned');
        }

        return data.data;
    } catch (error) {
        console.error('Error checking counselor status:', error);
        throw error;
    }
};
export const isValidCounselor = async (counsellor_email: string): Promise<any> => {
    const apiUrl = BackendApi() + 'isCounselor';
    const idToken = localStorage.getItem('AccessToken');
    try {
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            },
            body: JSON.stringify({ "email": counsellor_email })
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (!data) {
            throw new Error('No counselor status returned');
        }

        return data.data;

    } catch (error) {
        console.error('Error checking counselor status:', error);
        throw error;
    }
};

export const createNotes = async (notesData: any): Promise<any> => {
    const apiUrl = BackendApi() + 'create_notes';
    const idToken = localStorage.getItem('AccessToken');
    const payload = {
        username: notesData.username,
        journey_type: notesData.journey_type,
        version: notesData.version,
        counsellor_email: notesData.counsellor_email,
        notes: notesData.notes
    };

    try {
        const response = await axios.post(apiUrl, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error creating notes:', error);
        throw error;
    }
};

export const updateNotes = async (notesData: any): Promise<any> => {
    const apiUrl = BackendApi() + 'update_notes';
    const idToken = localStorage.getItem('AccessToken');
    const payload = {
        username: notesData.username,
        journey_type: notesData.journey_type,
        version: notesData.version,
        counsellor_email: notesData.counsellor_email,
        notes: notesData.notes
    };

    try {
        const response = await axios.put(apiUrl, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error updating notes:', error);
        throw error;
    }
};

export const viewNotes = async (username: string, counsellorEmail: string, journey_type: string): Promise<any> => {
    const apiUrl = BackendApi() + 'view_notes';
    const idToken = localStorage.getItem('AccessToken');
    const payload = {
        username,
        counsellor_email: counsellorEmail,
        journey_type
    };

    try {
        const response = await axios.post(apiUrl, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error viewing notes:', error);
        throw error;
    }
};

export const fetchAndUpdateBookings = async (
    username = '',
    counsellorEmail = ''
): Promise<any> => {
    const apiUrl = BackendApi() + 'fetchAndUpdateBookings';
    const idToken = localStorage.getItem('AccessToken');

    // Prepare payload based on available parameters
    const payload: any = {};
    if (username) payload.username = username;
    if (counsellorEmail) payload.counsellor_email = counsellorEmail;

    try {
        const response = await axios.post(apiUrl, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error fetching and updating bookings:', error);
        throw error;
    }
};

export const validateUserSchedule = async (userEmail: string, date: string, time: string): Promise<any> => {
    const apiUrl = BackendApi() + 'validateSchedule';
    const idToken = localStorage.getItem('AccessToken');

    const payload = { user_email: userEmail, Date: date, Time: time };

    try {
        const response = await axios.post(apiUrl, payload, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            }
        });
        return response.data.message;
    } catch (error: any) {
        console.error('Error validating user schedule:', error);
        if (error.response) {
            throw new Error(error.response.data?.detail || 'Error validating schedule.');
        } else {
            throw new Error('Network error or server is unreachable.');
        }
    }
};
