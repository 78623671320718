import React, { useEffect, useState } from "react";
import CreateNavbar from "../../../Navbar/Navbar";
import './PortfolioMobile.css';
import northstar from '../../../../assets/img/User/Portfolio/northstar.svg';
import questionnaire from '../../../../assets/img/User/Portfolio/questionnaire.svg';
import stdjourney from '../../../../assets/img/User/Portfolio/stdjourney.svg';
import pcj from '../../../../assets/img/User/Portfolio/pcjourney.svg';
import counselling from '../../../../assets/img/User/Portfolio/counselling.svg';
import trainings from '../../../../assets/img/User/Portfolio/trainings.svg';
import certificates from '../../../../assets/img/User/Portfolio/certificate.svg';
import jobs from '../../../../assets/img/User/Portfolio/jobs.svg';
import { useNavigate } from "react-router-dom";
import PortfolioMbView from "./PortfolioMbView";
import resume from '../../../../assets/img/User/Portfolio/resume.svg';
import { FetchPremiumJourneybyVerion } from "../../../../api/FetchHtmlJourney";
import { isUserTokenValid } from "../../../common/ValidateToken";
import { fetchUserDetails } from "../../../../api/UserMetadata";

const PortfolioMobile: React.FC = () => {
  const [isNorthStart,setNorthStartStatus]=useState<boolean>(true);
  const [northStarTitle, setNorthStarTitle] = useState<string>("Loading...");
  const [id,setId]=useState<number>(0);
  const navigate = useNavigate();
  const profileIcon:any =localStorage.getItem('profileIcon')
  const name:any=localStorage.getItem('Name')
  const [showNameTooltip, setShowNameTooltip] = useState<boolean>(false);

  const handleNamePress = () => {
    if (name.length > 32) {
      setShowNameTooltip(true);
      setTimeout(() => setShowNameTooltip(false), 3000);
    }
  };

  const fetchPremiumJourney = async () => {
    try {
      const isValid = await isUserTokenValid();
      if (!isValid) {
        navigate('/login',{ state:{page:"portfolio"} });
        return;
      }
  
      const username = localStorage.getItem("Email") || ""; 
      const userDetails = await fetchUserDetails(username, navigate);
  
      const publishedJourney = userDetails?.PublishedJourney;
      const version = publishedJourney?.version;
    
      const response = await FetchPremiumJourneybyVerion(version);
      const northStarData = response?.premium_journey?.NorthStar;
  
      if (northStarData?.Title) {
        localStorage.setItem("northstarTitle", northStarData.Title);
        setNorthStarTitle(northStarData.Title);
      } else {
        setNorthStarTitle("Your Premium Journey is not yet created");
      }
    } catch (error) {
      console.error("Error fetching premium journey:", error);
      setNorthStarTitle("Error loading title");
    }
  };
  
      
    useEffect(() => {
      fetchPremiumJourney();
    }, []);

  return (
<div className="h-[120vh] overflow-y-auto flex flex-col">
<CreateNavbar page="home" />
      <div className="box flex flex-col min-h-fit mt-2">
      <div className="flex w-full max-h-[60px] items-center">
        <div className="flex items-center mt-2">
          <img src={profileIcon} alt="Profile" className="profile ml-[25px] rounded-full h-[50px] w-[50px] object-cover"/>
          <div className="name truncate-ellipsis" onClick={handleNamePress}><span>{name}</span></div>
              {showNameTooltip && name.length > 32 && ( <div className="tooltip">{name}</div>)}
        </div>
        <div className="flex justify-end ml-auto">
          {localStorage.getItem('userLevel') === 'Standard' ? (
                  <img src={stdjourney} className="w-[30px] h-[40px] object-contain" alt="PCJ" /> 
                ) : 
                  <img src={pcj} className="w-[30px] h-[40px] object-contain" alt="std" />
                }              
        </div>
      </div>
        <label className="flex line mt-[65px] ">
        </label>
          {
            isNorthStart ? (
              <div className="flex flex-col northstartbox mt-[25px] ml-[13px]">
                <div className="flex w-full flex-row items-center">
                  <img
                    src={northstar}
                    alt="NorthStar"
                    className=" w-[30px] h-[30px]"
                  />
                  <span className="northstartext ">NorthStar</span>
                </div>
                <div className="flex ml-[15px] northstardynamictext ">
                {northStarTitle}
                </div>
              </div>
            ):(
              <div className="flex flex-col northstartbox mt-[25px] ml-[13px]">
                <div className="flex w-full flex-row items-center">
                  <img
                    src={northstar}
                    alt="NorthStar"
                    className=" w-[30px] h-[30px]"
                  />
                  <span className="northstartext ">Lorem</span>
                </div>
                <div className="flex ml-[15px] northstardynamictext ">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididun
                </div>
              </div>
            )
          }
        
      </div>
      {
        id===0 ?(
          <div className="flex justify-center gap-4 pt-8 pl-[74px] pr-[74px]">
            <div className="flex flex-col text-align-center">
                <div className="flex gap-20">
                    <div className="optionbox text-wrap" onClick={()=>setId(1)}>
                      <img src={questionnaire}  className="boxicon mb-[4px]  mt-[14px]" alt="Questionnaire" />
                      <span className="optionboxtext ">Journey Builder</span>
                    </div>
                    <div className="optionbox text-wrap"  onClick={()=>setId(2)}>
                      <img src={stdjourney}  className="boxicon mb-[4px]  mt-[14px]" alt="Standard Journey" />
                      <span className="optionboxtext ">Standard Journey</span>
                    </div> 
                </div>
                <div className="mt-[15px] flex gap-20">
                    <div className="optionbox text-wrap" onClick={()=>setId(3)}>
                      <img src={pcj}  className="boxicon mb-[4px] mt-[14px]" alt="Premium Journey" />
                      <span className="optionboxtext ">Premium Journey</span>
                    </div>
                    <div className="optionbox text-wrap" onClick={()=>setId(4)}>
                      <img src={counselling} className="boxicon mb-[4px] mt-[14px]" alt="Counselling" />
                      <span className="optionboxtext ">Counselling</span>
                    </div> 
                </div>
                <div className="mt-[15px] flex gap-20">
                    <div className="optionbox text-wrap" onClick={()=>setId(5)}>
                      <img src={trainings} className="boxicon mb-[4px] mt-[14px]" alt="Trainings" />
                      <span className="optionboxtext ">Trainings</span>
                    </div>
                    <div className="optionbox text-wrap" onClick={()=>setId(6)}>
                      <img src={certificates}  className="boxicon mb-[4px] mt-[14px]" alt="Certificates" />
                      <span className="optionboxtext ">Certificates</span>
                    </div>            
                </div>
                <div className="mt-[15px] flex gap-20">
                    <div className="optionbox text-wrap" onClick={() => setId(7)}>
                      <img src={jobs}  className="boxicon mb-[4px] mt-[14px]" alt="Jobs" />
                      <span className="optionboxtext  ">Jobs</span>
                    </div> 
                    <div className="optionbox text-wrap" onClick={()=>setId(8)}>
                      <img src={resume}  className="boxicon mb-[4px] mt-[14px]" alt="Resume" />
                      <span className="optionboxtext ">Resume</span>
                    </div>  
                </div>
            </div>
          </div>
        ):(
          <PortfolioMbView selectid={id} setId={setId}/>
        )
      }
      
    </div>
  );
};

export default PortfolioMobile;
