import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CollegePortfolioDeskView from '../components/College/desktop/CollegePortfolioDeskView';
import CollegePortfolioDesktop from '../components/College/desktop/CollegePortfolioDeskptop';
import CollegePortfolioMobile from '../components/College/mobile/CollegePortfolioMobile';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { useNavigate } from 'react-router-dom';
import { useTokenValidator } from '../components/tokenvalidator';



const CollegePortfolio: React.FC = () => {
  const { loading } = useTokenValidator('/college-login');
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });
  
  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="flex flex-col">
      {/* <CreateNavbar page='home' /> */}
      {isMobile ? (
        <CollegePortfolioMobile/>
      ) : (
        <CollegePortfolioDesktop/>
      )}
    </div>
  );
};

export default CollegePortfolio;
