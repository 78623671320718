import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FetchJourney, FetchUserName } from "../api/FetchHtmlJourney";
import { isUserTokenValid } from "../components/common/ValidateToken";
import { JourneyStatusCheck } from "../api/JourneyStatusCheck";
import { storeUserData } from "../components/common/StorageUtil";
import Shutter from "../components/journeys/Shutter";
import BasicJourneyBody from "../components/journeys/BasicJourneyBody";
import { SnackbarUi } from "../components/Snackbar/SnackbarUi";
import LoadingSpinner from "../components/common/LoadingSpinner";
import useIsIframe from "../components/CounsellorUser/CounsellorsClientPortFolio/common/IsIframe";
import JourneyInsights from "../components/common/error/JourneyInsights";

export default function BasicJourney() {
    const [shutter, setShutter] = useState(true);
    const [userName, setUserName] = useState<string>('');
    const [journeyPart, setJourneyPart] = useState<number>(0);
    const [standardJourney, setStandardJourney] = useState<any>({});
    const [disabledShutter, setDisabledShutter] = useState(true);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
    const [loading, setLoading] = useState<boolean>(true);
    const [journeyStatus, setJourneyStatus] = useState<string>('');
    const navigate = useNavigate();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const location = useLocation();
    const { redirect } = location.state || {};
    const isIframe = useIsIframe();
    const userLevel = localStorage.getItem("userLevel");
    const [isJourneyExisits,setJourneyExisits]=useState<boolean>(true);
    const [showAdminInsights, setShowAdminInsights] = useState(false);
    const fetchUserName = async () => {
        try {
            if (await isUserTokenValid()) {
                const name = localStorage.getItem("Name") as string;
                setUserName(name);
            } else {
                navigate('/');
            }
        } catch (error) {
            console.error("Error fetching user name:", error);
        }
    };

    const fetchJourney = async () => {
        try {
            if (!(await isUserTokenValid())) {
                navigate('/');
                return;
            }

            const journeyCheck = await JourneyStatusCheck();
            setJourneyStatus(journeyCheck?.journey_status);

            if (journeyCheck.version === 0) {
                const userLevel = localStorage.getItem('userLevel'); 
                if (userLevel === 'College Admin') {
                    setLoading(false);
                } else {
                  setJourneyExisits(false);
                  setLoading(false);
                }
              }

            if (journeyCheck?.journey_status !== 'initiated') {
                const journeyData = await FetchJourney();
                setStandardJourney(journeyData);
                (userLevel?.includes('Counselor') || userLevel?.includes('Standard') || userLevel?.includes('Premium')) && setLoading(false);
            }
            if (['completed', 'failed'].includes(journeyCheck?.journey_status)) {
                storeUserData('journeyExist', true);                             // home page view journey button enables trigger
                if (intervalRef.current) clearInterval(intervalRef.current);

                if (journeyCheck?.journey_status === 'completed') {
                    setDisabledShutter(false);
                    // setShutter(false); Uncomment this to directly move user to Standard Journey View
                    !userLevel?.includes('Counselor') && setLoading(false);
                } else if (journeyCheck?.journey_status === 'failed') {
                    setIsSnackbarOpen(true);
                    !userLevel?.includes('Counselor') && setLoading(false);   
                }
            }else if(journeyCheck?.journey_status === 'initiated'){
                setLoading(false);
            }
        } catch (error) {
            console.error("Error fetching journey data:", error);
        }
    };

    useEffect(() => {
        fetchUserName();
        
        fetchJourney();

        // If the user level includes 'Counselor', fetch the journey only once
        if (!userLevel?.includes('Counselor')) {
            intervalRef.current = setInterval(fetchJourney, 5000);
        }

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, [userLevel]);

    useEffect(() => {
        if (redirect === "Questionnaire-to-MyJourney") {
            setLoading(false);
            window.history.replaceState({}, "");
        }
    }, [redirect]);

    useEffect(() => {
        if (userLevel === 'College Admin' && !loading) {
          const timer = setTimeout(() => {
            setShowAdminInsights(true);
          }, 1000);
          return () => clearTimeout(timer);
        }
      }, [userLevel, loading]);

    if (loading) {
        return <LoadingSpinner />;
    }

    const isJourneyAvailable = standardJourney && standardJourney.career_journey;
    if (!isJourneyAvailable) {
        if (userLevel?.includes('Counselor')) {
        return <JourneyInsights message="Standard" />;
        }
        if (userLevel === 'College Admin') {
        if (!showAdminInsights) {
            return <LoadingSpinner />;
        }
        return <JourneyInsights message="Standard" />;
        }
    }
      
    const renderShutter = () => (
        <Shutter
            shutter={shutter}
            setShutter={setShutter}
            userName={userName}
            setJourneyPart={setJourneyPart}
            disabledShutter={disabledShutter}
        />
    );

    const renderJourneyBody = () => {
    if (!standardJourney || !standardJourney.career_journey) {
        return <LoadingSpinner />;
    }
    return (
        <BasicJourneyBody
            userName={userName}
            journeyPart={journeyPart}
            setShutter={setShutter}
            setJourneyPart={setJourneyPart}
            standardJourney={standardJourney.career_journey}
            hideNavbar={false}
            isIframe={isIframe}
        />
    );
};


    const showShutter = shutter && (journeyStatus === 'initiated' || journeyStatus === 'generating');

    return (
        <>
            {!isJourneyExisits ? (<JourneyInsights message="Standard" />) : (
                isIframe ? (showShutter ? renderShutter() : renderJourneyBody()) : (
                    shutter ? renderShutter() : renderJourneyBody())
            )}
            
            <SnackbarUi
                isSnackbarOpen={isSnackbarOpen}
                setIsSnackbarOpen={setIsSnackbarOpen}
                backendMessage="We’re sorry, your journey creation has failed for some technical reason. We are working on it, please retry creating your journey or contact us."
            />
        </>
    );
}
