import { isValidToken } from "../../api/UserMetadata";

export const isUserTokenValid = async () => {
      const token:any=localStorage.getItem('AccessToken');
      const name = localStorage.getItem('Name');
      const profileIcon = localStorage.getItem('profileIcon');

      if (!name || !profileIcon) {
          localStorage.clear();
          return false;
      }
      if(token===undefined || token===null){
        localStorage.clear();
        return false;
      }else if(token.length===0){
        localStorage.clear();
        return false;
      }
      const response:any=isValidToken(token);
      if(!response) localStorage.clear();
      return response;
}


export const isTokenValid = async (token:string) => {
  
  if(token===undefined || token===null){
    localStorage.clear();
    return false;
  }else if(token.length===0){
    localStorage.clear();
    return false;
  }
  const response:any=isValidToken(`Bearer ${token}`);
  if(!response) localStorage.clear();
  return response;
}