import React, { useEffect, useRef, useState } from 'react';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';
import { RazorpayButton } from './RazorPay';
import { generatePremiumJourney } from '../../api/UserMetadata';
import { fetchCoupons } from '../../api/InitiatePayment';
import CryptoJS from 'crypto-js';

interface DataItem {
  id: number;
  title: string;
  type: any;
  availableSeats: number;
  price: number;
  date: string;
  venue: string;
}

const dummyData: DataItem[] = [
  { id: 1, title: 'Selenium Basics to Advanced Hands-on Workshop', type: 'Training', availableSeats: 5, price: 1199, date: '2024-09-14', venue: 'Online' },
  { id: 2, title: 'Advanced JavaScript', type: 'Webinar', availableSeats: 15, price: 2000, date: 'TBD', venue: 'Online' },
  { id: 3, title: 'Certified Scrum Master', type: 'Certification', availableSeats: 10, price: 10000, date: 'TBD', venue: 'In-Person' },
  { id: 4, title: 'Networking Event', type: 'Event', availableSeats: 50, price: 0, date: 'TBD', venue: 'Conference Hall' },
  { id: 5, title: 'Software Engineering Internship', type: 'Software Internship', availableSeats: 5, price: 0, date: 'TBD', venue: 'Company Office' },
];

const PremiumAdvantage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const courseId = Number(id);
  const course = dummyData.find(item => item.id === courseId) || dummyData[0];

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [couponCode, setCouponCode] = useState<string>('');
  const [discount, setDiscount] = useState<number>(0);
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [mobileError, setMobileError] = useState<string>('');
  const [shouldRenderRazorpay, setShouldRenderRazorpay] = useState(false);
  const [validCoupons, setValidCoupons] = useState<any>();
  const [dynamicPrice, setDynamicPrice] = useState<number>(course.price);
  const [premiumCoupon, setPremiumCoupon] = useState<{code: string, value: number} | null>(null);
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  const secretKey = "WjbO6aasKeNx1orwjtpNBuSfrD7lLcoFE3jPOnX2ApU=";

  const decryptCoupons = (encryptedData: { iv: string; ciphertext: string }) => {
    try {
      if (!secretKey) {
        throw new Error("Decryption key not found.");
      }
      const keyBytes = CryptoJS.enc.Utf8.parse(secretKey);
      let key: CryptoJS.lib.WordArray;
      if ([16, 24, 32].includes(keyBytes.sigBytes)) {
        key = keyBytes;
      } else {
        key = CryptoJS.SHA256(secretKey);
      }
      const iv = CryptoJS.enc.Base64.parse(encryptedData.iv);
      const ciphertext = CryptoJS.enc.Base64.parse(encryptedData.ciphertext);
      const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext });
      const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) {
        throw new Error("Decryption failed. Possibly due to an incorrect key or malformed data.");
      }
      const coupons = JSON.parse(decryptedText);
      return coupons;
    } catch (error) {
      console.error("Error decrypting coupons:", error);
      return null;
    }
  };

  const fetchAndHandleCoupons = async (): Promise<void> => {
    try {
      const encryptedCoupons = await fetchCoupons();
      const decryptedCoupons = decryptCoupons(encryptedCoupons);
      setValidCoupons(decryptedCoupons.coupon_code)
      if (decryptedCoupons) {
        if (decryptedCoupons.Premium_Coupon) {
          setPremiumCoupon({
            code: decryptedCoupons.Premium_Coupon.Coupon,
            value: decryptedCoupons.Premium_Coupon.Value
          });
          setCouponCode(decryptedCoupons.Premium_Coupon.Coupon);
          setDiscount(decryptedCoupons.Premium_Coupon.Value);
        }
        if (decryptedCoupons.Dynamic_Pricing && decryptedCoupons.Dynamic_Pricing.Premium) {
          setDynamicPrice(decryptedCoupons.Dynamic_Pricing.Premium);
        }
      }
    }  catch (error) {
      console.error('Error fetching coupons:', error); 
    }
  };

  useEffect(() => {
    fetchAndHandleCoupons();
  }, []);

  useEffect(() => {
    const storedEmail = localStorage.getItem('Email');
    const name=localStorage.getItem("Name")
    localStorage.setItem('user_type',"Premium");
    if(name){
        setName(name);
    }
    if (storedEmail) {
      setEmail(storedEmail);
    }
    return () => {
      localStorage.removeItem('user_type');
    };
  }, []);

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value.trim();
    setCouponCode(code);
    let foundDiscount = 0;
    
    if (premiumCoupon && premiumCoupon.code === code) {
      foundDiscount = premiumCoupon.value;
    } 
    else if (validCoupons) {
      Object.keys(validCoupons).forEach((key) => {
        const couponData = validCoupons[key];
        if (couponData && typeof couponData === "object" && "Coupon" in couponData && "Value" in couponData) {
          if (couponData.Coupon === code) {
            foundDiscount = couponData.Value;
          }
        } else {
          if (key === code) {
            foundDiscount = validCoupons[key];
          }
        }
      });
    }
    setDiscount(foundDiscount);
  };

  const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMobileNumber(value);

    const mobilePattern = /^[0-9]{10}$/;
    if (mobilePattern.test(value)) {
      setMobileError('');
    } else {
      setMobileError('Please enter a valid 10-digit mobile number');
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    setNameError('');
    setEmailError('');
    setMobileError('');

    if (!name) {
      setNameError('Full Name is required');
      isValid = false;
    }

    if (!email) {
      setEmailError('Email is required');
      isValid = false;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        setEmailError('Please enter a valid email address');
        isValid = false;
      }
    }

    if (!mobileNumber) {
      setMobileError('Mobile number is required');
      isValid = false;
    } else {
      const mobilePattern = /^[0-9]{10}$/;
      if (!mobilePattern.test(mobileNumber)) {
        setMobileError('Please enter a valid 10-digit mobile number');
        isValid = false;
      }
    }

    return isValid;
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {

    const conditionToPrevent = validateForm();; // Replace this with your actual condition

    if (conditionToPrevent) {
      setShouldRenderRazorpay(true);
    } else {
      setShouldRenderRazorpay(false);
    }
  };

  const handlePremiumClick = () => {
    const generateJourney: any = async () => {
        try {

          await generatePremiumJourney();
          navigate('/premiumjourneyloading');
        } catch (error) {
            console.error('Error handling premium click:', error);
        }
    };

    generateJourney();
};

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  const discountAmount = ((dynamicPrice * discount) / 100).toFixed(0);
  const discountedPrice = dynamicPrice - parseFloat(discountAmount);

  const gstAmount = (discountedPrice * 0.18).toFixed(2);
  const total = (discountedPrice + parseFloat(gstAmount)).toFixed(2);

  return (
    <div className="h-full">
      <CreateNavbar page='myJourney' />
      <div className="bg-gray-100 min-h-screen p-6">
        {/* Technologies Carousel Section */}
        <section className="bg-white p-8 rounded-lg shadow-md mb-12 mx-auto lg:w-2/3 md:w-4/5 border-t-4 border-sky-600">
            <div className="text-center mb-6">
                <h2 className="text-2xl font-semibold text-gray-800 mb-4">Go Premium and Unlock Your Potential!</h2>
                <p className="text-gray-600 mb-4">Experience a transformative journey with personalized insights and strategies tailored for your success.</p>
            </div>
            <div className="text-gray-600 ml-2 md:ml-12 max-w-lg flex flex-wrap justify-left">
              <div className="mb-2 mx-2">✨ Your NorthStar, Vision, and Long-Term Goals</div>
              <div className="mb-2 mx-2">🔍 In-Depth Profile Analysis</div>
              <div className="mb-2 mx-2">📅 Detailed Quarterly Action Plans for Year 1</div>
              <div className="mb-2 mx-2">🎯 Your Smart Goals for the Next 5 Years</div>
              <div className="mb-2 mx-2">🚀 Alternative Journeys Towards Your NorthStar</div>
              <div className="mb-2 mx-2">📈 Industry Trends for Your Career Portfolio</div>
              <div className="mb-2 mx-2">🌱 Personal Development Opportunities</div>
          </div>

        </section>

        {/* Main Content Section */}
        <main className="container mx-auto flex flex-col lg:flex-row gap-6">
          {/* Your Details Section */}
          <section className="bg-white p-8 rounded-lg shadow-md flex-1 min-h-[300px] lg:w-1/2 border-t-4 border-sky-600">
            <div className="border-b-2 border-gray-200 pb-4 mb-6">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">Your Details</h2>
            </div>
            <form id="your-details-form" ref={formRef}>
              <label className="block mb-4">
                <span className="font-medium text-gray-700">Full Name:</span>
                <input
                  type="text"
                  value={name}
                  placeholder="Enter your full name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  className={`mt-1 block w-full px-3 py-2 border ${nameError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-sky-600`}
                />
                {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
              </label>

              <label className="block mb-4">
                <span className="font-medium text-gray-700">Email:</span>
                <input
                  type="email"
                  value={email}
                  placeholder="Enter your email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className={`mt-1 block w-full px-3 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-sky-600`}
                />
                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
              </label>

              <label className="block mb-4">
                <span className="font-medium text-gray-700">Mobile Number:</span>
                <input
                  type="tel"
                  value={mobileNumber}
                  placeholder="Enter your mobile number"
                  required
                  onChange={handleMobileNumberChange}
                  className={`mt-1 block w-full px-3 py-2 border ${mobileError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-sky-600`}
                />
                {mobileError && <p className="text-red-500 text-sm mt-1">{mobileError}</p>}
              </label>

              <label className="block mb-4">
                <span className="font-medium text-gray-700">Coupon Code (optional):</span> 
                <input
                  type="text"
                  value={couponCode}
                  placeholder="Enter coupon code (if any)"
                  onChange={handleCouponChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-sky-600"
                />
              </label>
            </form>
          </section>

          {/* Payment Summary Section */}
          <section className="bg-white p-8 rounded-lg shadow-md flex-1 min-h-[300px] lg:w-1/2 border-t-4 border-sky-600">
            <div className="border-b-2 border-gray-200 pb-4 mb-6">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">Payment Summary</h2>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="font-medium text-gray-700">Premium Subscription Fee:</span>
                <span className="font-medium text-gray-800">₹{dynamicPrice.toFixed(2)}</span>
              </div>
              {discount > 0 && (
                <>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Discount ({discount}%):</span>
                    <span className="font-medium text-red-500">- ₹{discountAmount}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Discounted Price:</span>
                    <span className="font-medium text-gray-800">₹{discountedPrice.toFixed(2)}</span>
                  </div>
                </>
              )}
              <div className="flex justify-between">
                <span className="font-medium text-gray-700">GST (18%):</span>
                <span className="font-medium text-gray-800">₹{gstAmount}</span>
              </div>
              <div className="flex justify-between border-t-2 border-gray-200 pt-4">
                <span className="font-semibold text-xl text-gray-800">Total Payable:</span>
                <span className="font-semibold text-xl text-gray-800">₹{total}</span>
              </div>
              <div className="flex py-3 justify-center">
                <button
                  type="submit"
                  onClick={handleButtonClick}
                  className=""
                >
                  {discount<100 && shouldRenderRazorpay &&  (
                    <RazorpayButton
                      buttonText="Proceed to Pay"
                      amount={total}
                    />
                  ) }
                  {!shouldRenderRazorpay && (
                    <button
                      type="submit"
                      className="w-full py-3 px-4 bg-custom-orange text-white rounded-md shadow-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-custom-orange mt-6"
                    >
                      Validate Information
                    </button>
                  )}
                </button>
                {shouldRenderRazorpay && discount === 100 && (<button
                      type="submit"
                      className="w-full py-3 px-4 bg-custom-blue text-white rounded-md shadow-md hover:bg-sky-700 focus:outline-none focus:ring-2 focus:ring-custom-blue mt-6"
                      onClick={handlePremiumClick}
                    >
                      Generate your Premium Journey
                    </button>)}
              </div>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
};

export default PremiumAdvantage;
