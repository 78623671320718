import React from 'react';
import { useLocation } from 'react-router-dom';
import Questionnaire from './Questionnaire';
import DynamicQuestionnaireMainPage from './user/Questionnaire/DyanamicQuestionnaireMainPage';


const QuestionnaireMainPage = () => {
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);
  const isDynamic = queryParams.get('isDynamic');

  return (
    <div>
      {isDynamic === 'false' ? <Questionnaire /> : <DynamicQuestionnaireMainPage />}
    </div>
  );
};

export default QuestionnaireMainPage;