import { BackendApi } from "./apiConfig/ApiFormatter";


export const checkIfTPO = async (email: string) => {
  const apiUrl = BackendApi() +'iscollegeAdmin';

  try {
    

    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); 
  } catch (error) {
    console.error('Failed to check TPO status:', error);
    throw error;
  }
};

export const searchStudents = async (searchParams: {
  name?: string;
  college_name?: string;
  department?: string;
  degree?: string;
  year?: string;
  page?: number;
  page_size?: number;
}) => {
  const apiUrl = `${BackendApi()}student-register/search`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` ${localStorage.getItem('AccessToken')}`, 
      },
      body: JSON.stringify(searchParams),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); 
  } catch (error) {
    console.error('Failed to search students:', error);
    throw error;
  }
};

export const insertStudentRegister = async (studentData: {
  name: string;
  email: string;
  college_name: string;
  highlights: string;
  department: string;
  degree: string;
  year: string;
}) => {
  const apiUrl = `${BackendApi()}student-register/insert`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` ${localStorage.getItem('AccessToken')}`,
      },
      body: JSON.stringify(studentData),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error('Failed to insert student register:', error);
    throw error;
  }
};


export const fetchStudentNotes = async (studentEmail: string) => {
  const apiUrl = `${BackendApi()}collegeAdmin-viewNotes`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('AccessToken')}`, 
      },
      body: JSON.stringify({ email: studentEmail }), 
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); 
  } catch (error) {
    console.error('Failed to fetch student notes:', error);
    throw error;
  }
};

export const addStudentNote = async (noteDetails: {
  student_email: string;
  notes: { notes: string }[]; 
}) => {
  const apiUrl = BackendApi() +'collegeAdmin-addNotes';

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: ` ${localStorage.getItem('AccessToken')}`,
      },
      body: JSON.stringify(noteDetails), 
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to save student note:', error);
    throw error;
  }
};

export const updateStudentNote = async (noteDetails: {
  student_email: string;
  notes: { notes: string  }[]; 
}) => {
  const apiUrl = BackendApi() + 'collegeAdmin-updateNotes'; 

  try {
    const response = await fetch(apiUrl, {
      method: 'PUT', 
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${localStorage.getItem('AccessToken')}`, 
      },
      body: JSON.stringify(noteDetails), 
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json(); 
  } catch (error) {
    console.error('Failed to update student note:', error);
    throw error;
  }
};





