import { useLayoutEffect, useState } from 'react'
import Footer from '../components/footer/Footer'
import Transform from '../components/careerPath/Transform';
import Passion from '../components/careerSolutions/Passion';
import Header from '../components/Header/header';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { isUserTokenValid, isTokenValid } from '../components/common/ValidateToken';
import LoadingSpinner from '../components/common/LoadingSpinner';
import CounselorCarousel from '../components/common/counsellor/counsellorCards';
import { getUserJWTtoken, getUserMetadata } from '../api/UserMetadata';
import { FetechUserProfile } from '../api/FetchUserProfile';
import { isValidCounselor } from '../api/Counsellor';
import useIsIframe from '../components/CounsellorUser/CounsellorsClientPortFolio/common/IsIframe';


export default function Landing() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState<boolean>(true);
  const isIframe = useIsIframe();

  useLayoutEffect(() => {
    const initialize = async () => {
      setLoading(true);

      const accessToken = searchParams.get('access_token');
      const email = searchParams.get('email');
      const redirectPath = searchParams.get('redirect');
      const counsellor_email: string = searchParams.get('counsellor_email') || "";
      if (accessToken && email && redirectPath && counsellor_email ) {
        const data: any = await isValidCounselor(counsellor_email);

        console.log(data)
        if (data.is_counselor) {
          const isValid = await isTokenValid(accessToken);
          if (isValid) {
            const res = await getUserJWTtoken(accessToken, email)
            console.log(res)
            const data = await getUserMetadata(res.token)
            console.log(data)

            if (data?.name) {
              localStorage.setItem("Name", data.name);
            }
            if (data) {
              localStorage.setItem("AccessToken", `Bearer ${accessToken}`);
              const profileData: any = await FetechUserProfile();
              if (!localStorage.getItem("Name")) {
                localStorage.setItem("Name", profileData.response.name);
              }
              localStorage.setItem("profileIcon", profileData.profileURL);
              localStorage.setItem("AccessToken", `Bearer ${res.token}`);


            }
            console.log("Valid Token");
            localStorage.setItem('userEmail', email);
            navigate(redirectPath);
            return;
          }
        }
      }
      const isValid = await isUserTokenValid();
      if (isValid) {
        navigate('/home');
      }
      setLoading(false);
    };
    initialize();
  }, [navigate, searchParams]);


  if (loading) {
    return <LoadingSpinner />; // Show spinner while loading
  }
  return (
    <>
      <div className="flex overflow-y-auto flex-col">
        <Header />
        <Passion />
        <div className='md:container md:mx-auto'>
          <Transform />
        </div>
        <CounselorCarousel />
        {/* <Meet /> */}
        <Footer />
      </div>
    </>
  )
}
