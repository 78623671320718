// api.ts
import { BackendApi } from './apiConfig/ApiFormatter';

export const fetchUserSearchLogs = async (version?: number): Promise<any> => {
  let apiUrl = BackendApi() + 'questionSet';
  if (version) {
    apiUrl += `?version=${version}`;
  }
  const idToken = localStorage.getItem('AccessToken');
  try {
  
    const response = await fetch(apiUrl, {
      method: 'GET', // HTTP method
      headers: {
          'Content-Type': 'application/json',
          Authorization: `${idToken}`, // Bearer token for authorization
      }
    });
     // Check if the response is ok (status code in the range 200-299)
     if (!response.ok) {
      // Handle errors here, e.g., throw an error or return a specific response
      throw new Error(`HTTP error! status: ${response.status}`);
  }
  
  // Parse the JSON response
  const data: any = await response.json();
  return data.data;
  } catch (error) {
    throw new Error(`Failed to fetch user search logs: ${error}`);
  }
};
