import React from 'react';
import { useMediaQuery } from 'react-responsive';
import CollegeDashboardDesktop from '../components/College/CollegeDashboardDesktop';
import CollegeDashboardMobile from '../components/College/CollegeDashboardMobile';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { useTokenValidator } from '../components/tokenvalidator';

const CollegeDashboardMainPage: React.FC = () => {
  const { loading } = useTokenValidator('/college-login');

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });

  if (loading) {
    return <LoadingSpinner />;
  }
  return (
    <div className="flex flex-col">
      {isMobile ? (
        <CollegeDashboardMobile/>
      ) : (
        <CollegeDashboardDesktop/>
      )}
    </div>
  );
};

export default CollegeDashboardMainPage;
