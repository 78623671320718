import React, { useRef, useState } from 'react'
import CreateNavbar from '../../components/Navbar/Navbar'
import JobFilter from '../../components/jobs/JobFilter'
import JobList from '../../components/jobs/JobList'
import SearchBar, { MobileSearchBar } from '../../components/jobs/SearchBar'
import { useMediaQuery } from 'react-responsive'
import useIsIframe from '../CounsellorUser/CounsellorsClientPortFolio/common/IsIframe'
import LoadingSpinner from '../common/LoadingSpinner'
import { useTokenValidator } from '../tokenvalidator'

const Jobs: React.FC = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })
  const [searchTerms, setSearchTerms] = useState({
    jobTitle: '',
    location: '',
    role: '',
  })
  const [companyFilters, setCompanyFilters] = useState<string[]>([])
  const stateRef = useRef({ page: "jobs" });
  const { loading } = useTokenValidator('/login', stateRef.current);
    const isIframe = useIsIframe();

  const handleFilterChange = (companies: string[]) => {
    setCompanyFilters(companies)
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen bg-white">
      <div className="fixed top-0 left-0 right-0 z-20">
        <CreateNavbar page="home" />
      </div>
      <div className={`${isMobile &&  !isIframe ? 'pt-[200px]' : 'pt-16'}`}>
        <div className="px-4 pt-2">
          {isMobile ? (
            <MobileSearchBar onSearch={setSearchTerms} />
          ) : (
            <SearchBar onSearch={setSearchTerms} />
          )}
        </div>
        {!isMobile && (
          <div className="flex px-6 gap-6 mt-14">
            <div className="w-64">
              <JobFilter onFilterChange={handleFilterChange} />
            </div>
            <div className="flex-1">
              <JobList
                searchTerms={searchTerms}
                companyFilters={companyFilters}
              />
            </div>
          </div>
        )}
        {isMobile  && (
          <div className="p-4">
            {!isIframe &&
              <div className="mb-4">
                <JobFilter onFilterChange={handleFilterChange} />
              </div>
            }

            <div className={`${isIframe && 'mt-4'}`}>
              <JobList
                searchTerms={searchTerms}
                companyFilters={companyFilters}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Jobs
