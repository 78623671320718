import axios from 'axios';
import { BackendApi } from "./apiConfig/ApiFormatter";
import { isUserTokenValid } from '../components/common/ValidateToken';

export const getUserMetadata = async (idToken: string): Promise<any> => {
    const apiUrl = `${BackendApi()}getUserMetadata`;

    try {
        const { data } = await axios.post(apiUrl, {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              }
        });

        // Handle cases where data fields might be undefined
        const emailIdPresent = data.emailIdPresent !== undefined ? data.emailIdPresent.toString() : 'false';
        const editAllowed = data.editAllowed !== undefined ? data.editAllowed.toString() : 'false';
        const journeyExist = data.journeyExist !== undefined ? data.journeyExist.toString() : 'false';

        // Now `premiumJourneyStatus` is a string instead of an object
        const premiumJourneyStatus = data.premiumJourneyStatus || 'unknown';
        const userLevel = data.userLevel || '';

        // Store values as strings in localStorage
        localStorage.setItem('Email', data.email || '');
        localStorage.setItem('emailIdPresent', emailIdPresent);
        localStorage.setItem('editAllowed', editAllowed);
        localStorage.setItem('journeyExist', journeyExist);
        localStorage.setItem('premiumJourneyStatus', premiumJourneyStatus); // Simplified key
        localStorage.setItem('userLevel', userLevel);

        return data;
    } catch (error) {
        console.error("Error checking email:", error);
        return null;
    }
};

export const getJWTtoken = async (): Promise<any> => {
    const apiUrl = `${BackendApi()}getToken`;
    // const apiUrl = `http://localhost:7071/api/getToken`;
    const idToken = localStorage.getItem('AccessToken');
    const email = localStorage.getItem('Email');
    const name = localStorage.getItem('Name');
    const payload={"email":email, "name": name } // Fetch the ID token from session storage
    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // HTTP method
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${idToken}`, // Bearer token for authorization
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data= await response.json();
        return data;        
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
        throw error;  // rethrow the error
    }
};

export const generatePremiumJourney = async (): Promise<any> => {
    const apiUrl = `${BackendApi()}generatePremiumJourney`;
    const idToken = localStorage.getItem('AccessToken');
    const email = localStorage.getItem('Email');
    const payload={"email":email} // Fetch the ID token from session storage
    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // HTTP method
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${idToken}`, // Bearer token for authorization
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data= await response.json();
        return data;        
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
        throw error;  // rethrow the error
    }
};


interface TokenValidationResponse {
    message: string;
    isValid: any;
    Email: string;
  }
  export const isValidToken = async (idToken: string): Promise<TokenValidationResponse | null> => {
      const apiUrl = `${BackendApi()}isTokenValid`;
  
      try {
          const response = await fetch(apiUrl, {
              method: 'GET',
              headers: {
                  'Authorization': `${idToken}`, // Use 'Bearer' prefix for token-based authorization
                  'Content-Type': 'application/json'
              }
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data: TokenValidationResponse = await response.json();
          return data.isValid;
      } catch (error) {
          console.error("In Valid Token:", error);
        //   localStorage.clear();      fix for relogin bug on refresh
          return null;
      }
  };


  export const fetchUserDetails = async (username: string, navigate: (path: string) => void ): Promise<any> => {

    const isValid = await isUserTokenValid();
    if (!isValid) navigate('/login');

    const apiUrl = BackendApi() + 'fetchUserDetails';
    const idToken = localStorage.getItem('AccessToken');

    try {
        const response = await axios.post(apiUrl, { email: username }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Error viewing notes:', error);
        throw error;
    }
};


export const send_Otp = async (
    email:string
): Promise<any> => {
    const apiUrl = `${BackendApi()}sendOTP`;
    const payload={"email":email} // Fetch the ID token from session storage
    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // HTTP method
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data= await response.json();
        return data;        
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
        throw error;  // rethrow the error
    }
};

export const validate_otp = async (
    email:string,
    otp:string
): Promise<any> => {
    const apiUrl = `${BackendApi()}validateOTP`;
    const payload={"email":email,"otp":otp} 
    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // HTTP method
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data= await response.json();
        return data;        
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
        throw error;  // rethrow the error
    }
};

export const storeUserName = async (
    username: string,
    name: string
): Promise<any> => {
    const apiUrl = `${BackendApi()}storeUserName`;
    const payload = { username, name };
    const response = await fetch(apiUrl, {
      method: 'POST', // HTTP method
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),// Convert payload to JSON
    });
  
    const data = await response.json();
    
    if (!response.ok) {
      throw {
        status: response.status,
        error: data.error || 'Failed to store username'
      };
    }
    return data;
  };


  export const getUserJWTtoken = async (idToken:string,email:string): Promise<any> => {
    const apiUrl = `${BackendApi()}getToken`;
    const name = localStorage.getItem('Name');
    const payload={"email":email, "name": name } // Fetch the ID token from session storage
    try {
        const response = await fetch(apiUrl, {
            method: 'POST', // HTTP method
            headers: {
                'Content-Type': 'application/json',
                Authorization: `${idToken}`, // Bearer token for authorization
            },
            body: JSON.stringify(payload) // Convert payload to JSON
        });
        
        // Check if the response is ok (status code in the range 200-299)
        if (!response.ok) {
            // Handle errors here, e.g., throw an error or return a specific response
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const data= await response.json();
        return data;        
    } catch (error) {
        console.error('Error verifying and saving payment credential:', error);
        throw error;  // rethrow the error
    }
};