import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getJWTtoken, getUserMetadata } from "../api/UserMetadata";
import { FetechUserProfile } from "../api/FetchUserProfile";
import google from "../assets/img/google.png";
import login from "../assets/img/login.png";
import { isUserTokenValid } from "../components/common/ValidateToken";
import Loginnav from "../components/Navbar/loginNav";
import LoadingSpinner from "../components/common/LoadingSpinner";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EmailLoginMainPage from "./EmailLogin";

interface LoginProps {
  clientId: string;
}

const Login: React.FC<LoginProps> = ({ clientId }) => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showEmailPage, setShowEmailPage] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  
  
  let { page } = location.state || { page: "home" };

  useEffect(() => {
    const checkTokenAndSetupLogin = async () => {
      setLoading(true);
      const isValid = await isUserTokenValid();
      if (isValid) {
        const name = localStorage.getItem("Name");
        const profile = localStorage.getItem("profileIcon");
        const email = localStorage.getItem("Email");

        if (!name && !profile) {
          setShowNameInput(true);
          setShowEmailPage(true);
          if (email) {
            setUserEmail(email);
          }
        } else {
          navigate("/home");
        }
      }
      setLoading(false);
    };

    checkTokenAndSetupLogin();
  }, []);




  const log = async () => {
    const handleLogin = (): void => {
      const GoogleclientId="696561118234-878rvcigr8loohv8r9bhua7foe49ljgi.apps.googleusercontent.com"
      console.log(page);
      const encodedUrl = new URLSearchParams(location.search).get("returnurl");
      console.log(encodedUrl);
      if (encodedUrl) {
        page="trainings"
      }
      
      const redirectUri: string = window.location.origin + `/callback`;
      console.log(window.location.origin);
      const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${GoogleclientId}&redirect_uri=${encodeURIComponent(redirectUri)}&response_type=id_token%20token&scope=email%20openid%20profile&nonce=asdsadasdsad&service=lso&o2v=1&ddm=${page}&flowName=GeneralOAuthFlow&state=${page}`;
      window.location.href = googleAuthUrl;
    };
    handleLogin();
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  const handleEmailPageClose = () => {
    setShowEmailPage(false);
  };

  return (
    <div className="relative flex flex-col md:flex-row md:h-screen overflow-y-auto">
      <div className="flex-1 flex flex-col md:justify-between md:w-1/2">
        <Loginnav />
        <div className="flex-1 md:absolute md:right-0 md:top-0 md:bottom-0 md:w-1/2 bg-[#FEF5E6] rounded-custom-70px-tl-tr md:rounded-custom-90px-tl-br md:p-16 flex flex-col justify-start items-center shadow-mobiledrop md:shadow-custom relative mt-72 md:mt-auto">
          <img
            src={login}
            alt="Login"
            className="w-80 h-64 left-1/2 -top-40 md:w-[500px] md:h-[400px] absolute md:top-1/2 md:-left-32 transform -translate-x-1/2 -translate-y-1/2 "
          />
          <span className="text-3xl md:text-5xl py-5 md:py-8 font-bold md:mt-14 lg:mt-20 md:ml-20 bg-custom-gradient text-transparent bg-clip-text">
            LOGIN
          </span>
          <div className="flex items-center justify-center font-sans md:ml-20 pb-4">
            <span className="md:text-xl text-lg font-medium text-center leading-tight">
              Welcome to SkillJourney! <br />
              <span className="font-medium ">
                Your Path to a Bright Future starts here.
              </span>
            </span>
          </div>

          <div className="flex items-center justify-center py-5 md:py-3 md:ml-20">
            <button
              onClick={log}
              className="relative w-full md:w-[20rem] md:h-14 px-6 py-2.5 md:text-xl text-md font-semibold  text-black transition-all duration-300 transform focus:outline-none rounded-lg border border-black hover:bg-orange-100"
            >
              <span className="absolute left-4 top-1/2 transform -translate-y-1/2 ">
                <img className="h-5 w-5" src={google} alt="Google Logo" />
              </span>
              <span className="pl-10 ">Continue With Google</span>
            </button>
          </div>
          <div className="flex items-center justify-center pb-5 md:py-3 md:ml-20">
            {!showEmailPage ? (
              <button
                onClick={() => setShowEmailPage(true)}
                className="relative w-full md:w-[20rem] md:h-14 px-6 py-2.5 md:text-xl text-md font-semibold text-black transition-all duration-300 transform focus:outline-none rounded-lg border border-black hover:bg-orange-100 "
              >
                <span className="absolute left-4 top-1/2 transform -translate-y-1/2">
                  <EmailOutlinedIcon fontSize="medium" />
                </span>
                <span className="pl-10 mr-4">Continue With Email</span>
              </button>
            ) : (
              <div className="fixed top-0 left-0 w-full h-full bg-white z-50">
                <EmailLoginMainPage
                  onBack={handleEmailPageClose}
                  initialShowNameInput={showNameInput}
                  initialEmail={userEmail}
                />
              </div>
            )}
          </div>
          <p className="md:text-base py-1 text-sm font-medium font-sans text-center md:ml-20 md:mt-4">
            By signing you are agreeing to our{" "}
            <Link to="/privacypolicy" className="text-custom-blue underline">
              Privacy Policy
            </Link>
          </p>
          {successMessage && (
            <div className="text-green-500 text-2xl">{successMessage}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Login;
