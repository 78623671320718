import { useState, useEffect, useRef } from "react";
import InputText from "./DynamicInput1/InputText";
import InputTextArea from "./DynamicInput1/InputTextArea";
import InputRadio from "./DynamicInput1/InputRadio";
import InputCheckbox from "./DynamicInput1/InputCheckbox";
import InputDropdown from "./DynamicInput1/InputDropdown";
import InputRange from "./DynamicInput1/InputRange";
import InputFile from "./DynamicInput1/InputFile";

interface SectionRendererProps {
  section: any;
  formData: Record<string, any>;
  onInputChange: (
    name: string,
    value: any,
    bypassResumeCheck?: boolean
  ) => void;
  resumeValidationError?: boolean;
  onResumeValidationErrorClear?: () => void;
  resumeErrorMessage?: string;
}

const SectionRenderer: React.FC<SectionRendererProps> = ({
  section,
  formData,
  onInputChange,
  resumeValidationError,
  onResumeValidationErrorClear,
  resumeErrorMessage,
}) => {
  const renderQuestion = (question: any) => {
    switch (question.type) {
      case "text": {
        const isPhoneNumber = question.name === "contactNumber";
        const value = formData[question.name] || "";

        const handlePhoneNumberChange = (e: { target: { value: string } }) => {
          const inputValue = e.target.value.replace("+91", "").trim();
          const numericValue = inputValue.replace(/\D/g, "");

          const isValidInput =
            numericValue === "" ||
            (/^\d+$/.test(numericValue) && numericValue.length <= 10);

          if (isValidInput) {
            onInputChange(question.name, numericValue);
          }
        };
        return (
          <InputText
            key={question.questionId}
            label={question.label}
            name={question.name}
            description={question.description}
            value={isPhoneNumber ? `+91 ${value}` : value}
            onChange={
              isPhoneNumber
                ? handlePhoneNumberChange
                : (e) => onInputChange(question.name, e.target.value)
            }
            isRequired={question.isRequired}
            tooltip={question.tooltip}
          />
        );
      }
      case "textarea":
        return (
          <InputTextArea
            key={question.questionId}
            label={question.label}
            name={question.name}
            description={question.description}
            value={formData[question.name] || ""}
            onChange={(e) => onInputChange(question.name, e.target.value)}
            isRequired={question.isRequired}
            tooltip={question.tooltip}
          />
        );
      case "radio":
        return (
          <InputRadio
            key={question.questionId}
            label={question.label}
            name={question.name}
            description={question.description}
            options={question.options}
            selectedValue={formData[question.name] || ""}
            onChange={(e) => onInputChange(question.name, e.target.value)}
            isRequired={question.isRequired}
          />
        );
      case "checkbox":
        return (
          <InputCheckbox
            key={question.questionId}
            label={question.label}
            description={question.description}
            name={question.name}
            options={question.options}
            selectedValues={
              Array.isArray(formData[question.name])
                ? formData[question.name]
                : []
            } // Ensure it's an array
            onChange={(e) => {
              const value = e.target.value;
              const updatedValues = Array.isArray(formData[question.name])
                ? formData[question.name]
                : [];
              const newValues = updatedValues.includes(value)
                ? updatedValues.filter((val: string) => val !== value)
                : [...updatedValues, value];
              onInputChange(question.name, newValues);
            }}
            isRequired={question.isRequired}
          />
        );
      case "dropdown":
        return (
          <InputDropdown
            key={question.questionId}
            label={question.label}
            name={question.name}
            description={question.description}
            options={question.options}
            // selectedValues={formData[question.name] || ""}
            // onChange={(e:any) => onInputChange(question.name, e.target.value)}
            selectedValues={formData[question.name] || []}
            onChange={(selected) => onInputChange(question.name, selected)}
            isRequired={question.isRequired}
          />
        );
      case "range":
        return (
          <InputRange
            key={question.questionId}
            label={question.label}
            name={question.name}
            description={question.description}
            min={question.range[0]}
            max={question.range[1]}
            value={formData[question.name] || question.range[0]}
            onChange={(e) => onInputChange(question.name, e.target.value)}
            isRequired={question.isRequired}
          />
        );

      case "file":
        return (
          <InputFile
            key={question.questionId}
            label={question.label}
            name={question.name}
            description={question.description}
            isRequired={question.isRequired}
            tooltip={question.tooltip}
            validations={question.validations} // Pass validations here
            onChange={(file, bypassCheck) => {
              onInputChange(question.name, file, bypassCheck);
            }}
            resumeValidationError={resumeValidationError}
            onResumeValidationErrorClear={onResumeValidationErrorClear}
            resumeErrorMessage={resumeErrorMessage}
          />
        );
      default:
        return null;
    }
  };

  const [formDatas, setFormData] = useState<Record<string, any>>(() => {});

  const renderSubquestions = (question: any) => {
    const selectedOption = Array.isArray(formData[question.name])
      ? formData[question.name]
      : [formData[question.name]];

    const selectedOptionDetails = question.options.filter(
      (option: any) => selectedOption.includes(option.value) // Check if any value in selectedOption matches
    );

    if (selectedOptionDetails.length > 0) {
      // Collect and render all subquestions
      const subquestionsToRender = selectedOptionDetails.flatMap(
        (option: any) => option.selectsubquestions || []
      );

      return subquestionsToRender.map((subquestionId: string) => {
        const subquestion = question.subquestions.find(
          (sq: any) => sq.subquestionId === subquestionId
        );
        return subquestion ? (
          <div style={{ paddingTop: "35px" }} key={subquestion.subquestionId}>
            {renderQuestion(subquestion)}
          </div>
        ) : null;
      });
    }

    return null;
  };

  const shouldSkipQuestion = (question: any) => {
    if (!question?.skipquestions) return false;

    return question.skipquestions.some(
      (skipQuestionId: string) => formData[skipQuestionId]
    );
  };

  return (
    <div>
      {section.questions
        .filter((question: any) => !shouldSkipQuestion(question))
        .map((question: any) => (
          <div key={question.questionId}>
            {/* {question.prequestionMessage && <p>{question.prequestionMessage}</p>} */}
            {renderQuestion(question)}
            <div>{renderSubquestions(question)}</div>
          </div>
        ))}
    </div>
  );
};

export default SectionRenderer;
