import React, { useEffect, useState } from 'react';
import { Button, Snackbar } from '@mui/material';
import { Add, Close } from '@mui/icons-material';
import CreateNavbar from '../Navbar/Navbar';
import HrDetails from './HrDetails';
import HRJobCard from './HRJobCard';
import { createHRJob, deleteHRJob, getHRDetails, searchHRJobs, updateHRJob } from '../../api/HrJobs';
import { useLocation } from 'react-router-dom';
import AddJobForm from './AddJobForm';
import Pagination from '../jobs/Pagination'; // Import Pagination component
import { useMediaQuery } from 'react-responsive';
import { useNavigate } from 'react-router-dom';

export interface Job {
  job_id: string;
  title: string;
  company: string;
  department?: string;
  experience_level?: string;
  salary?: string;
  location: string;
  description?: string;
  applyLink?: string;
  postedDate: string;
  valid_days?: string;
  posted_by?: string;
}

interface Hr {
  name: string;
  position: string;
  email: string;
  phone: string;
  companyName: string;
  profileImageUrl: string;
}

const HrPortfolio: React.FC = () => {
  const [jobs, setJobs] = useState<Job[]>([]);
  const [currentPage, setCurrentPage] = useState(1); 
  const [recordsPerPage, setRecordsPerPage] = useState(25); 
  const [totalRecords, setTotalRecords] = useState(0); 
  const [formVisible, setFormVisible] = useState(false);
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [openSnackbar, setOpenSnackbar] = useState(false); // Snackbar visibility state
  const [snackbarMessage, setSnackbarMessage] = useState(''); // Snackbar message content
  const location = useLocation();
  const navigate = useNavigate();
  const email = location.state?.email || '';
  const [isHrDetailsExpanded, setIsHrDetailsExpanded] = useState(false);
  const [hrProfile, setHrProfile] = useState<any | null>(null); // Dynamic typing
  const [newJob, setNewJob] = useState<Partial<Job>>({
    job_id: Math.floor(100000 + Math.random() * 900000).toString(),
    title: '',
    company: '',
    department: '',
    location: '',
    description: '',
    experience_level: '',
    salary: '',
    postedDate: '',
    posted_by: '',
    valid_days:'',
  });

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' });


  useEffect(() => {
    const checkAuth = () => {
      const email = localStorage.getItem('email');
      const userLevel = localStorage.getItem('userLevel');
      
      if (!email || userLevel !== 'HR') {
        navigate('/hrlogin');
      }
    };
  
    checkAuth();
  }, [navigate]);

  useEffect(() => {
    const fetchHRDetails = async () => {
      const email = localStorage.getItem('email'); // Retrieve email from localStorage
      if (!email) {
        setError('Email not found. Please log in again.');
        setLoading(false);
        return;
      }
  
      try {
        setLoading(true);
        const profile = await getHRDetails(email); // Fetch HR details using email
        setHrProfile(profile);

        const photoUrl = profile?.photo_url || '';
        localStorage.setItem('profileIcon', photoUrl);
  
        if (profile?.name) {
          localStorage.setItem('Name', profile.name);
        }
      } catch (err) {
        setError('Failed to fetch HR details.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };
  
    fetchHRDetails();
  }, []);

  const fetchJobs = async () => {
    if (!hrProfile?.company_name) {
      setError('Company name not found. Please try again.');
      return;
    }

    setLoading(true);
    setError(null); // Reset error before making the request

    try {
      const response = await searchHRJobs({
        company: hrProfile.company_name,
        page: currentPage, // Include current page
        page_size: recordsPerPage, // Include records per page
      });

      if (response?.data?.jobs && Array.isArray(response.data.jobs)) {
        setJobs(response.data.jobs); // Set jobs
        setTotalRecords(response.data.total_records || 0); // Set total records
      } else {
        setJobs([]); // Ensure jobs is an empty array if response is malformed
        setTotalRecords(0); // Reset total records
        setError('Unexpected response format.');
      }
    } catch (err) {
      console.error('Error fetching jobs:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (hrProfile) {
      fetchJobs(); // Fetch jobs once HR profile is loaded
    }
  }, [hrProfile, currentPage, recordsPerPage]);

  const handleDeleteJob = async (job_id: string) => {
    try {
      await deleteHRJob(job_id);
      fetchJobs(); // Fetch jobs after deletion
    } catch (error) {
      setError('Failed to delete the job. Please try again.');
    }
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page); // Update current page
  };

  const handleRecordsPerPageChange = (records: number) => {
    setRecordsPerPage(records); // Update records per page
    setCurrentPage(1); // Reset to the first page
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setNewJob(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleAddOrUpdateJob = async () => {
    try {
      const jobId = editingIndex !== null ? jobs[editingIndex].job_id : newJob.job_id || `fallback-id-${Date.now()}`;

      const basePayload = {
        job_id: jobId,
        title: newJob.title || '',
        company: hrProfile.company_name,
        department: newJob.department || '',
        location: newJob.location || '',
        experience_level: newJob.experience_level || '',
        description: newJob.description || '',
        hr_email: hrProfile.email,
        salary: newJob.salary,
        valid_days:newJob.valid_days,
      };

      if (editingIndex !== null) {
        // Update the job with the job_id
        await updateHRJob(basePayload);
        setSnackbarMessage('Job updated successfully');
      } else {
        // Add new job with job_id
        await createHRJob(basePayload);
        setSnackbarMessage('Job posted successfully');
      }

      fetchJobs(); // Fetch jobs after add/update
      resetForm();
      setFormVisible(false);
      setOpenSnackbar(true); // Show the snackbar on success
    } catch (error) {
      console.error('Error adding or updating job:', error);
      alert('Failed to save the job. Please try again.');
    }
  };

  const resetForm = () => {
    setNewJob({
      job_id: Math.floor(100000 + Math.random() * 900000).toString(),
      title: '',
      company: '',
      department: '',
      location: '',
      description: '',
      experience_level: '',
      salary: '',
    });
    setEditingIndex(null);
  };

  const toggleFormVisibility = () => {
    if (formVisible) resetForm();
    setFormVisible(prev => !prev);
  };

  const handleEditJob = (job: Job, index: number) => {
    setNewJob(job);
    setEditingIndex(index);
    setFormVisible(true);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  const handleHrDetailsExpand = (expanded: boolean) => {
    setIsHrDetailsExpanded(expanded);
  };

  return (
    <div className={`h-screen bg-gray-100 ${isMobile ? 'pb-10' : ''}`}>
      {/* Navbar */}
      <div className="fixed top-0 left-0 right-0 z-20">
        <CreateNavbar page="home" />
      </div>
  
      {/* Main Content */}
      <div className="bg-gray-100 flex flex-col md:flex-row pt-16">
        {/* HR Profile Section */}
        <div className="px-4 pt-4 md:w-1/4 h-fit">
          <HrDetails {...hrProfile} 
          onExpandedChange={handleHrDetailsExpand} />
        </div>
  
        {/* Job Listings Section */}
        <div className="p-4 md:w-3/4 flex flex-col">
          {/* Always show the "List a Job" button */}
          <div className="flex justify-start mb-2">
            <Button
              startIcon={!formVisible ? <Add /> : null}
              endIcon={formVisible ? <Close /> : null}
              variant="contained"
              onClick={toggleFormVisibility}
              style={{ backgroundColor: '#0778B6', color: '#FFFFFF' }}
            >
              {formVisible ? 'Cancel' : 'List a Job'}
            </Button>
          </div>
  
          {/* Loading State */}
          {loading && !error ? (
            <p>Loading jobs...</p>
          ) : error ? (
            // Error State
            <p className="text-red-500">{error}</p>
          ) : (
            <>
              {/* Add Job Form */}
              {formVisible && (
                <div className={`${isMobile ? (isHrDetailsExpanded ? 'max-h-[60vh] overflow-y-auto pb-20' : 'max-h-[80vh] overflow-y-auto pb-20') : ''}`}>
                <AddJobForm
                    newJob={newJob}
                    onInputChange={handleInputChange}
                    onAddJob={handleAddOrUpdateJob}
                    onCancel={toggleFormVisibility}
                  />
                </div>
              )}
              <div className={`flex-grow overflow-y-auto max-h-[80vh] ${isMobile ? (isHrDetailsExpanded ? 'pb-32' : '') : ''}`}>
                {jobs.length > 0 ? (
                  <>
                    <div>
                      {jobs.map((job, index) => (
                        <HRJobCard
                          key={job.job_id}
                          job={job}
                          hrName={hrProfile.name}
                          onEdit={() => handleEditJob(job, index)}
                          onDelete={() => handleDeleteJob(job.job_id)}
                        />
                      ))}
                    </div>

                    <div className=" z-10 w-full bg-white mb-20">
                      <Pagination
                        currentPage={currentPage}
                        totalRecords={totalRecords}
                        recordsPerPage={recordsPerPage}
                        onPageChange={handlePageChange}
                        onRecordsPerPageChange={handleRecordsPerPageChange}
                        className='px-6 md:w-3/4'
                      />
                    </div>
                  </>
                ) : (
                  <div className="text-center mt-8">
                    <p className="text-gray-500 mb-4">No jobs available at the moment.</p>
                    <Button
                      variant="contained"
                      onClick={toggleFormVisibility}
                      style={{ backgroundColor: '#0778B6', color: '#FFFFFF' }}
                    >
                      Post a Job
                    </Button>
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      />
    </div>
  );
};

export default HrPortfolio;
