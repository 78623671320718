import React , {useEffect}  from "react";
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import CreateNavbar from "../components/Navbar/Navbar";
import AboutSvg from '../assets/img/About.svg';
import { updateSectionData } from "../components/common/StorageUtil";

const contentList = [
  {span: "Our Vision",p: "Empower individuals to unleash their potential by helping them navigate their professional and personal journeys with confidence, purpose, and continuous growth."},
  {span: "Our Mission",p: "At SkillJourney, our mission is to empower individuals by providing personalized career guidance that aligns with their unique identities, passions, and aspirations. We believe that every career journey is unique, and we are dedicated to helping our users navigate their paths with confidence and clarity, enabling them to achieve their professional goals. We are committed to transforming aspirations into achievements and guiding individuals toward fulfilling and impactful careers."},
  {span: "Who We Are",p: "SkillJourney is a cutting-edge career guidance platform designed to support students, professionals, and career changers in planning and achieving their dream careers. Our team comprises experts in career counseling, data science, psychology, and education, all working together to create an innovative and user-centric experience."},
  {span: "What We Do",p: "We understand that the path to a successful career can be complex and challenging. That’s why we are developing a powerful platform that combines advanced technology, personalized insights, and expert advice to guide you every step of the way."},
  {span: "Personalized Career Journey",p: "At the core of SkillJourney is our unique career journey algorithm, powered by leading AI models. We start by evaluating your educational background, psychometric profile, and career aspirations through a detailed questionnaire and your resume. Based on this information, we create a personalized roadmap tailored to your goals, helping you make informed decisions about your future."},
  {span: "Explore Opportunities",p: "Our platform offers a comprehensive exploration of diverse career streams, providing you with detailed insights, resources, and guidance to help you understand the opportunities available in each field. Whether you’re interested in technology, healthcare, the arts, or any other industry, SkillJourney offers the tools you need to explore your options and make confident choices."},
  {span: "Stay Aligned with Your Goals",p: "We know that staying motivated and on track can be challenging, especially when pursuing long-term goals. SkillJourney is here to keep you aligned with your passions and dreams, ensuring that you never lose sight of what matters most. With our ongoing support and resources, you’ll stay motivated throughout your career journey."},
  {span: "Why Choose Us?",
    p: [
      "Tailored Guidance: Our platform is designed to provide personalized recommendations based on your unique profile, ensuring that your career journey is aligned with your individual strengths and aspirations.",
      "Expert Insights: With the support of our experienced team and cutting-edge AI, you’ll receive the most relevant and up-to-date guidance for your chosen field.",
      "Comprehensive Support: From exploring opportunities to staying on track with your goals, SkillJourney offers end-to-end support for your career development."
    ]},
  {span: "Join Us On Your Journey",p: "At SkillJourney, we’re more than just a platform – we’re your partner in building a successful career. Whether you’re just starting out or looking to make a change, we’re here to help you navigate the complexities of your career path with confidence. Ready to embark on your journey? Let SkillJourney guide you to your dream career."}
];

const About: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
}, []);

  const handleCreateJourney = () => {
    const useremail =localStorage.getItem('Email');
    if(useremail!==undefined && useremail!==null){
      navigate('/journeybuilder');
      updateSectionData(useremail, "activeSectionIndex", 0);
    } else {
      navigate('/login')
    }
    
  };
  return (
    <div className="flex overflow-y-auto flex-col">
       <CreateNavbar page="about" />
      <Container fluid className="px-4 sm:px-6 lg:px-8 pb-8">
       
        <h1 className="text-3xl md:text-4xl font-bold text-center text-[#0778B6] my-6">
          About Us
        </h1>

        <div className="flex justify-center mb-6">
          <img src={AboutSvg} alt="About" className="w-[90%] " />
        </div>

        <div className="space-y-4 sm:space-y-6">
          {contentList.map((item, index) => (
            <div key={index} className="space-y-4 px-4 mx-4 md:mx-20">
              <span className="text-2xl md:5xl font-bold   bg-custom-gradient text-transparent bg-clip-text  ">
                {item.span}
              </span>
              {Array.isArray(item.p) ? (
                <div className="grid grid-cols-1 gap-4 relative">
                {item.p.map((paragraph, pIndex) => (
                  <div
                    key={pIndex}
                    className="relative pl-6 border border-[#0778B6]  rounded-lg p-4 min-h-[100px] flex items-center"
                  >
                    {/* Blue dot */}
                    <div className="absolute left-[-6px] top-1/2 w-3 h-3 bg-[#0778B6] rounded-full transform -translate-y-1/2"></div>

                    <div className="absolute left-[-20px] top-1/2 w-[15px] h-[1px] bg-[#0778B6] transform -translate-y-1/2"></div>

                    {pIndex !== 0 && pIndex !== item.p.length - 1 && (
                      <div className="absolute left-[-20px] top-1 h-[100%] w-[1px] bg-[#0778B6]"></div>
                    )}

                    {pIndex === 0 && (
                      <div className="absolute left-[-20px] top-1/2 h-[72%] w-[1px] bg-[#0778B6]"></div>
                    )}

                    {pIndex === item.p.length - 1 && (
                      <div className="absolute left-[-20px] bottom-1/2 h-[72%] w-[1px] bg-[#0778B6]"></div>
                    )}

                      <p className="text-base sm:text-lg"
                        style={{
                          textAlign: 'left',
                          wordSpacing: '0.05em',
                          maxWidth: '100%',
                        }}>
                        {paragraph}
                      </p>
                  </div>
                ))}
              </div>
              ) : (
                <p className="text-base sm:text-lg   "
                style={{ 
                  textAlign: 'left' ,
                  wordSpacing: '0.05em',
                  maxWidth: '100%',
                }}>
              {item.p}</p>
              )}
            </div>
          ))}
        </div>

        <div className="flex flex-col items-center justify-center py-8 space-y-4">
          <button 
            onClick={handleCreateJourney}
            className="bg-[#F3772F] text-white rounded-full px-6 py-3 text-base sm:text-lg font-medium hover:bg-[#E66A1F] transition-colors duration-300"
          >
            CREATE YOUR JOURNEY NOW
          </button>
          <p className="text-xs sm:text-sm font-bold text-center">
            If you need further information, please{' '}
            
            <a  href="/contactUs"
              className="text-[#007BFF] hover:underline"
            >
              CONTACT US
            </a>
            .
          </p>
        </div>
      </Container>
    </div>
  );
};

export default About;

