import React, { useEffect, useState, useRef } from "react";
import CreateNavbar from "../../../Navbar/Navbar";
import './PortfolioMobile.css';
import northstar from '../../../../assets/img/User/Portfolio/northstar.svg';
import questionnaire from '../../../../assets/img/User/Portfolio/questionnaire.svg';
import stdjourney from '../../../../assets/img/User/Portfolio/stdjourney.svg';
import pcj from '../../../../assets/img/User/Portfolio/pcjourney.svg';
import counselling from '../../../../assets/img/User/Portfolio/counselling.svg';
import trainings from '../../../../assets/img/User/Portfolio/trainings.svg';
import certificates from '../../../../assets/img/User/Portfolio/certificate.svg';
import jobs from '../../../../assets/img/User/Portfolio/jobs.svg';
import bckarw from '../../../../assets/img/backarrowround.svg';
import frntarw from '../../../../assets/img/frontarrowround.svg';
import resume from '../../../../assets/img/User/Portfolio/resume.svg';
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import { Box, IconButton, Tooltip } from "@mui/material";
import IframeComp from "../UserIframeComp";
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { set } from "lodash";
import { GoChevronDown } from "react-icons/go";

interface JourneyRoute {
    id: number;
    route: string;
    externalUrl?: string
}

  // Define the journey routes with type annotations
const journeyRoutes: JourneyRoute[] = [
    { id: 1, route: "/journeybuilderview" },
    { id: 2, route: "/myjourney" },
    { id: 3, route: "/premiumjourney" },
    { id: 4, route: "/booked-sessions" },
    { id: 5, route: "/enrolledcourses", externalUrl: "https://trainings.skilljourney.in/t/u/activeCourses" },
    { id: 6, route: "/certificate" },
    { id: 7, route: "/jobs" },
    { id: 8, route: "/comingsoon" }
];


// Define the interface for the options
interface Option {
    id: number;
    name: string;
    logo: string;
}
interface ViewOption{
    selectid:number;
    setId:any;
}
const PortfolioMbView: React.FC<ViewOption> = ({selectid,setId}) => {
    const [isNorthStar, setNorthStarStatus] = useState<boolean>(true);
    const [endpoint,setEndpoint]=useState<string>();
    const navigate = useNavigate();
    const [activeId,setActiveID]=useState<number>(selectid || 1);
    const [activeOption,setActiveOption]=useState<string>();
    const [hideOptions,setHideOptions]=useState<boolean>(true);

    const carouselRef = useRef<any>(null);
    const [currentSlide, setCurrentSlide] = useState(0);
    const [iframeKey, setIframeKey] = useState<number>(0);

    const options: Option[] = [
        { id: 1, name: "Journey Builder", logo: questionnaire },
        { id: 2, name: "Standard Journey", logo: stdjourney },
        { id: 3, name: "Premium Journey", logo: pcj },
        { id: 4, name: "Counselling", logo: counselling },
        { id: 5, name: "Trainings", logo: trainings },
        { id: 6, name: "Certificates", logo: certificates },
        { id: 7, name: "Jobs", logo: jobs },
        { id: 8, name: "Resume Builder", logo: resume },
    ];
    const getNameById = (id: number) => {
        const option = options.find(option => option.id === id);
        return option ? option.name : "";  // Returns null if no option is found with the given id
      };
      

    const setData = (id:number,activeoption:string) => {
        setActiveID(id);
        scrollToActive(id);
        setHideOptions(true);
        setActiveOption(activeoption);
        setIframeKey(prev => prev + 1);
    };

    const handleNavigate = (id: number) => {
        const selectedRoute = journeyRoutes.find((journey) => journey.id === id)
        if (selectedRoute) {
      if (selectedRoute.externalUrl) {
        const LMSTOKEN = localStorage.getItem('LMSToken')
        window.open(LMSTOKEN ? `${selectedRoute.externalUrl}?ssoToken=${LMSTOKEN}` : selectedRoute.externalUrl)
      } else {
        navigate(selectedRoute.route)
      }
        }
    };

    const responsive = {
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 5,
            slidesToSlide: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 3,
            slidesToSlide: 3
        }
    };

    const CustomDot = ({ onClick, active, index }: any) => {
        return (
            <button
                className={`custom-dot ${active ? 'active' : ''}`}
                onClick={() => { onClick(); setCurrentSlide(index); }}
            />
        )};
    
    const handleDropdown = () => {
        setHideOptions(false);  
    }

    const scrollToActive = (id: number) => {
        const index = options.findIndex((option) => option.id === id);
        if (carouselRef.current) {
            const currentBreakpoint = window.innerWidth <= 464 ? 'mobile' : 'tablet';
            const visibleItems = responsive[currentBreakpoint].items;
            const totalOptions = options.length;
            const adjustedIndex =
                index >= totalOptions - visibleItems ? totalOptions - visibleItems : Math.max(0, index - Math.floor(visibleItems / 2));
            carouselRef.current.goToSlide(adjustedIndex);
            setCurrentSlide(Math.floor(adjustedIndex / visibleItems));
        }
    };

    useEffect(() => {
        setActiveOption(getNameById(activeId));
        scrollToActive(activeId);
    },[]);
    useEffect(() => {
        setActiveOption(getNameById(activeId));
        scrollToActive(activeId);
    },[hideOptions]);


    return (
        <div className="overflow-y-auto flex flex-col w-full h-screen">
            <div className="flex sticky top-4 justify-center px-4 mb-5 bg-white z-10">
                <div className="w-full pt-5 max-h-[200px]">
                    <Carousel
                        ref={carouselRef}
                        responsive={responsive}
                        infinite={false}
                        autoPlay={false}
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        itemClass="px-2"
                        containerClass="hide-scrollbar"
                        ssr={true}
                        showDots={true}
                        customDot={<CustomDot />}
                        dotListClass="custom-dot-list-style"
                        afterChange={(previousSlide, { currentSlide }) => {
                            setCurrentSlide(Math.floor(currentSlide / (window.innerWidth <= 464 ? 3 : 5)));
                        }}
                    >
                        {options.map((option) => (
                        !hideOptions && (
                        <div
                            key={option.id}
                            className={`optionbox justify-start flex flex-col ${activeId === option.id ? 'bg-[#ECFAFD]' : ''}`}
                            onClick={()=>setData(option.id,option.name)} 
                        >
                            <img
                                src={option.logo}
                                alt={option.name}
                                className="w-8 h-8 mt-4"
                            />
                            <span className=" optionboxtext text-wrap mt-2">{option.name}</span>
                        </div>
                    )
                    ))}
                    </Carousel>
                    {hideOptions && (
                        <div className="flex custom-rectangle flex-row" onClick={() => handleDropdown()}>
                            <div className="flex justify-left flex-row w-full">
                            <span className="flex pl-8 items-center justify-center custom-span">
                                {activeOption}
                            </span>
                            </div>
                            <span className="justify-end items-end  m-[10px] self-end">
                                <GoChevronDown />
                            </span>
                        </div>
                    )}
                </div>
            </div>

            <div className="flex-1 overflow-y-auto">
                <div className="flex justify-end pr-5">
                    <span className="cursor-pointer text-[#0778B6] hover:text-gray-700 my-1" onClick={() => handleNavigate(activeId)}>
                        <LaunchIcon />
                    </span>
                </div>

                <IframeComp
                    key={iframeKey}
                    className={"w-full h-[calc(100vh-200px)]"}
                    endpoint={journeyRoutes.find(journey => journey.id === activeId)?.route}
                />
            </div>
        </div>
    );
};

export default PortfolioMbView;
