import React, { useEffect } from "react";
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Cancel';
import { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MIT_logo from '../../assets/img/CollegeDashboard/MIT_logo.png';
import College_Filter from '../../assets/img/CollegeDashboard/College_Filter.png';
import { checkIfTPO, searchStudents } from "../../api/College";
import LoadingSpinner from "../common/LoadingSpinner";
import { isUserTokenValid } from "../common/ValidateToken";
import Pagination from "../jobs/Pagination";
import CollegeDashboardNavbar from "../Navbar/CollegeDashboardNavbar";

export interface Student {
  id?: string; 
  name: string;
  ProfileUrl: string;
  education: string;
  cgpa: string;
  highlights: string;
  email?: string;
  college_name?: string;
  contact_number?: number;
  degree?: string;
  department?: string;
  year?: number | string;
  photo_url?: string;

}

interface Filter {
  category: string;
  value: string | number;
}

interface FilterOptions {
  degrees: string[];
  departments: string[];
  years: number[];
}

const CollegeDashboardMobile: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showFilters, setShowFilters] = useState<boolean>(false);
  const [selectedFilters, setSelectedFilters] = useState<Filter[]>([]);
  const navigate = useNavigate();
  const [students, setStudents] = useState<Student[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    degrees: [],
    departments: [],
    years: []
  });
  const [refreshTrigger, setRefreshTrigger] = useState<number>(0);
  const [initialStudents, setInitialStudents] = useState<Student[]>([]);
  const [lastSearchResults, setLastSearchResults] = useState<Student[]>([]);
  const location = useLocation();
  const collegeName = location.state?.collegeName || localStorage.getItem("collegeName");
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [recordsPerPage, setRecordsPerPage] = useState<number>(25);
  const [collegeImage, setCollegeImage] = useState<string>("");
  const [isAdmin, setIsAdmin] = useState<boolean | null>(null); 

  useEffect(() => {
      const initialize = async () => {
      try {
        setLoading(true);
        
        if (localStorage.getItem('isCollegeAdmin') === 'true') {
          setIsAdmin(true);
          return;
        }
          const isValid = await isUserTokenValid();
          if (!isValid) {
            navigate('/college-login', { state: { page: "college-dashboard" } });
          return;
          }
  
        const email = localStorage.getItem("Email");
        if (!email) {
          navigate('/');
          return;
        }
  
        const adminResponse = await checkIfTPO(email);
        if (!adminResponse?.is_collegeAdmin?.isCollegeAdmin) {
          navigate('/');
          return;
        }
        setIsAdmin(true);
        localStorage.setItem('isCollegeAdmin', 'true'); 
      } catch (error) {
        navigate('/');
      } finally {
          setLoading(false);
      }};
      initialize();
  }, [navigate]);

  const transformStudentData = (studentsData: Student[]) => {
    return studentsData.map((student) => ({
      id: student.email,
      name: student.name,
      ProfileUrl: student.ProfileUrl || student.photo_url || "https://i.imgur.com/FDiUA0i.png",
      education: `${student.degree} in ${student.department}`,
      cgpa: student.cgpa.toString(),
      highlights: student.highlights,
      year: student.year,
      degree: student.degree,
      department: student.department
    }));
  };

  const fetchStudentsData = async (params: Record<string, any>, isSearch: boolean = false) => {
    try {
      setLoading(true);
      const response = await searchStudents({
        college_name: collegeName, page: currentPage, page_size: recordsPerPage ,
        ...params
      });
      const studentsData = response.data.students as Student[];
      const transformedData = transformStudentData(studentsData);
      setTotalRecords(response.data.total_records);
      
      if (isSearch) {
        setLastSearchResults(transformedData);
      }
      setStudents(transformedData);
    } catch (error) {
      console.error("Failed to fetch students:", error);
      setStudents([]);
      if (isSearch) {
        setLastSearchResults([]);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const initializeDashboard = async () => {
      try {
        setLoading(true);
        
        const response = await searchStudents({ college_name: collegeName, page: currentPage, page_size: recordsPerPage});
        const studentsData = response.data.students as Student[];
        setTotalRecords(response.data.total_records);

        const uniqueOptions: FilterOptions = {
          degrees: Array.from(new Set(studentsData
            .map(student => student.degree)
            .filter((degree): degree is string => degree !== undefined))),
          departments: Array.from(new Set(studentsData
            .map(student => student.department)
            .filter((department): department is string => department !== undefined))),
          years: Array.from(new Set(studentsData
            .map(student => student.year)
            .filter((year): year is number => year !== undefined)))
            
        };
        setFilterOptions(uniqueOptions);
        const transformedStudents = transformStudentData(studentsData);
        setStudents(transformedStudents);
        setInitialStudents(transformedStudents);
      } catch (error) {
        console.error("Failed to initialize dashboard:", error);
        setStudents([]);
        setInitialStudents([]);
      } finally {
        setLoading(false);
      }
    };

    initializeDashboard();
  }, [collegeName, refreshTrigger, currentPage, recordsPerPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const handleRecordsPerPageChange = (records: number) => {
    setRecordsPerPage(records);
    setCurrentPage(1);
  };
  
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    setSearchQuery(query);

    if (!query.trim()) {
      setStudents(initialStudents);
      setLastSearchResults([]);
      return;
    }
  };

  const handleSearchClick = async () => {
    const searchParams: Record<string, any> = {};
    
    if (searchQuery.trim()) {
      searchParams.name = searchQuery.trim();
    } else {
      if (selectedFilters.length > 0) {
        handleApplyFilters();
      } else {
        setStudents(initialStudents);
        setLastSearchResults([]);
      }
      return;
    }
  
    if (selectedFilters.length > 0) {
      const filterParams = selectedFilters.reduce((acc, filter) => {
        const categoryKey = filter.category.toLowerCase();
        if (categoryKey === 'year') {
          acc[categoryKey] = Number(filter.value);
        } else {
          if (!acc[categoryKey]) {
            acc[categoryKey] = [];
          }
          acc[categoryKey].push(String(filter.value));
        }
        return acc;
      }, {} as Record<string, any>);
  
      Object.keys(filterParams).forEach((key) => {
        if (Array.isArray(filterParams[key])) {
          filterParams[key] = filterParams[key].join(',');
        }
      });
  
      Object.assign(searchParams, filterParams);
    }
  
    await fetchStudentsData(searchParams, true);
  };
  const handleCardClick = (id: string | undefined) => {
    if (!id) return;
    const selectedStudent = students.find(student => student.id === id);
    if (selectedStudent) {
      navigate(`/college-portfolio`, { state: selectedStudent });
    }
  };

  const handleFilterSelect = (category: string, value: string | number, checked: boolean) => {
    setSelectedFilters(prev => 
      checked 
        ? [...prev, { category, value }]
        : prev.filter(filter => !(filter.category === category && filter.value === value))
    );
  };

  const isFilterSelected = (category: string, value: string | number) => {
    return selectedFilters.some(
      filter => filter.category === category && filter.value === value
    );
  };

  const handleRemoveFilter = async (filterToRemove: Filter) => {
    const updatedFilters = selectedFilters.filter(
      filter => !(filter.category === filterToRemove.category && filter.value === filterToRemove.value)
    );
    setSelectedFilters(updatedFilters);
  
    if (updatedFilters.length === 0) {
      if (searchQuery.trim()) {
        await fetchStudentsData({ name: searchQuery.trim() }, true);
      } else {
        setStudents(initialStudents);
      }
      return;
    }

    const filterParams = selectedFilters.reduce((params, filter) => {
      const categoryKey = filter.category.toLowerCase();
      if (categoryKey === 'year') {
        params[categoryKey] = Number(filter.value);
      } else {
        if (!params[categoryKey]) {
          params[categoryKey] = [];
        }
        params[categoryKey].push(String(filter.value));
      }
      return params;
    }, {} as Record<string, any>);
  
    Object.keys(filterParams).forEach((key) => {
      if (Array.isArray(filterParams[key])) {
        filterParams[key] = filterParams[key].join(',');
      }
    });
  
    if (searchQuery.trim()) {
      filterParams.name = searchQuery.trim();
    }
  
    await fetchStudentsData(filterParams);
  };

  const handleClearAll = async () => {
    setSelectedFilters([]);
    if (lastSearchResults.length > 0) {
      setStudents(lastSearchResults);
      setTotalRecords(lastSearchResults.length);
    } else {
      try {
        setLoading(true);
        const response = await searchStudents({ college_name: collegeName,page: 1,page_size: recordsPerPage});
        const studentsData = response.data.students as Student[];
        const transformedData = transformStudentData(studentsData);
        setStudents(transformedData);
        setTotalRecords(response.data.total_records);
        setInitialStudents(transformedData);
      } catch (error) {
        setStudents([]);
        setTotalRecords(0);
      } finally {
        setLoading(false);
      }
    }
  };

  const handleApplyFilters = async () => {
    if (selectedFilters.length === 0) {
      handleClearAll();
      return;
    }
  
    const filterParams = selectedFilters.reduce((params, filter) => {
      const categoryKey = filter.category.toLowerCase();
      if (categoryKey === 'year') {
        if (!params[categoryKey]) {
          params[categoryKey] = [];
        }
        params[categoryKey].push(String(filter.value)); 
      } else {
        if (!params[categoryKey]) {
          params[categoryKey] = [];
        }
        params[categoryKey].push(String(filter.value));
      }
      return params;
    }, {} as Record<string, any>);
  
    Object.keys(filterParams).forEach((key) => {
      if (Array.isArray(filterParams[key]) && key !== 'year') {
        filterParams[key] = filterParams[key].join(',');
      }
    });
    if (searchQuery.trim()) {
      filterParams.name = searchQuery.trim();
    }
  
    await fetchStudentsData(filterParams);
  };

  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };
  const handleReadMoreClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    toggleExpand();
  };

  useEffect(() => {
    const college_image = localStorage.getItem("college_image");
    if (college_image) {
      setCollegeImage(college_image);
    }
  }, []);

  useEffect(() => {
    return () => {
      localStorage.removeItem("createJourneyData");
    };
  }, []); 

  return (
    <div className="h-dvh overflow-x-hidden bg-[#F5F5F5]">
      <div className="fixed top-0 left-0 right-0 z-50">
      <CollegeDashboardNavbar page="college-dashboard" bgColor="bg-[#FEF5E6]" />
      </div>
      <div className="pt-[64px]"> 
        <div className="sticky top-14 z-40 bg-[#F5F5F5]">
          <div className="flex items-center justify-between p-4 gap-4">
            <div className="flex-1">
              <div className="flex items-center bg-white border border-gray-300 rounded-md overflow-hidden shadow-md">
                <SearchIcon className="text-gray-300 ml-4 h-6 w-6 shrink-0" />
                <input
                  type="text"
                  placeholder="Search Student"
                  className="flex-grow min-w-0 px-2 py-3 text-sm focus:outline-none bg-white"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <button
                  onClick={handleSearchClick}
                  className="bg-[#0778B6] text-white text-sm px-3 py-2 mr-1 rounded-md shrink-0"
                >
                  Search
                </button>
              </div>
            </div>
            <div className="shrink-0">
              <img src={collegeImage} alt="College Logo" className="w-14 h-14" />
            </div>
          </div>
  
          <div className="px-4 pb-2">
            <div className="flex items-center gap-4">
              <div className="flex-1 overflow-x-auto py-2 [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                <div className="flex items-center gap-2">
                  {selectedFilters.map((filter, index) => (
                    <div
                      key={index}
                      className="flex items-center gap-1 bg-[#ECFAFD] text-[#0778B6] border border-[#0778B6] px-2 py-1 rounded-full text-sm font-medium whitespace-nowrap"
                    >
                      <span>{filter.value}</span>
                      <button
                        onClick={() => handleRemoveFilter(filter)}
                        className="ml-1 hover:text-blue-600"
                      >
                        <CloseIcon fontSize="small" />
                      </button>
                    </div>
                  ))}
                </div>
              </div>
              <div className="shrink-0">
                <button
                  className="bg-white p-2 rounded-md shadow-sm hover:shadow-md transition-shadow"
                  onClick={() => setShowFilters(true)}
                >
                  <img src={College_Filter} alt="Filter" className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
  
        {showFilters && (
          <div
            className="fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center"
            onClick={() => setShowFilters(false)}
          >
            <div
              className="absolute top-48 right-6 bg-white rounded-md shadow-lg w-[60%] max-w-md p-4"
              onClick={(e) => e.stopPropagation()}
            >
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-lg font-semibold">Filters</h3>
              </div>
              <div className="max-h-[50vh] overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
                <div className="mb-4">
                  <h4 className="text-base font-semibold">Degree</h4>
                  {filterOptions.degrees.map((degree) => (
                    <label key={degree} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={isFilterSelected('Degree', degree)}
                        onChange={(e) => handleFilterSelect('Degree', degree, e.target.checked)}
                      />
                      <span>{degree}</span>
                    </label>
                  ))}
                </div>
  
                <div className="mb-4">
                  <h4 className="text-base font-semibold">Department</h4>
                  {filterOptions.departments.map((department) => (
                    <label key={department} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={isFilterSelected('department', department)}
                        onChange={(e) => handleFilterSelect('department', department, e.target.checked)}
                      />
                      <span>{department}</span>
                    </label>
                  ))}
                </div>
  
                <div>
                  <h4 className="text-base font-semibold">Year</h4>
                  {filterOptions.years.map((year) => (
                    <label key={year} className="flex items-center gap-2">
                      <input
                        type="checkbox"
                        className="form-checkbox h-4 w-4 text-blue-600"
                        checked={isFilterSelected('Year', year)}
                        onChange={(e) => handleFilterSelect('Year', year, e.target.checked)}
                      />
                      <span>{year} Year</span>
                    </label>
                  ))}
                </div>
              </div>
              <div className="flex justify-between items-center border-t border-gray-300 pt-2 mt-2">
                <button
                  className="text-gray-600 text-sm font-semibold"
                  onClick={handleClearAll}
                >
                  Clear All
                </button>
                <button
                  className="text-custom-blue text-sm font-semibold py-2"
                  onClick={handleApplyFilters}
                >
                  Apply All ({selectedFilters.length})
                </button>
              </div>
            </div>
          </div>
        )}
  
        {loading ? (
            <div>
            <LoadingSpinner />
          </div>
        ) : students.length === 0 ? (
          <div className="flex justify-center items-center h-[70vh]">
            <p className="text-gray-600 text-lg font-semibold text-center">No student record found</p>
          </div>
        ) : (
        <>
        <div className="grid grid-cols-1 gap-6 mt-4 mb-8 px-5">
          {students.map((student) => (
            <div
              key={student.id || student.email}
              className="bg-white py-4 px-2 rounded-lg shadow-md border-t-4 border-custom-orange transition-transform transform"
              onClick={() => handleCardClick(student.id || student.email)}
            >
              <div className="flex items-center">
                <div className="w-[80px] h-[80px] flex-shrink-0">
                  <img
                    src={student.ProfileUrl}
                    alt={student.name}
                    className="w-full h-full rounded-full border-4 border-customBlue object-cover" 
                  />
                </div>
                <div className="ml-2">
                  <h3 className="text-lg font-semibold text-gray-800 mb-1">
                    {student.name}
                  </h3>
                  <p className="text-gray-600 text-sm mb-1">
                    <strong>Education:</strong> {student.education}
                  </p>
                  <div className="flex flex-row mb-1">
                    <p className="text-gray-600 text-sm  mr-[13px]">
                      <strong>Year: </strong> <span className="font-normal">{student.year}</span>
                    </p>
                    <p className="text-gray-600 text-sm  mr-[13px]">
                      <strong>CGPA: </strong> <span className="font-normal">{student.cgpa}</span>
                    </p>
                  </div>
                  {student.highlights && (
                  <div className="text-gray-600 text-sm mb-1">
                    <strong>Highlights:</strong>
                    {student.highlights.length > 70 ? (
                      <span>
                        {isExpanded
                          ? student.highlights
                          : `${student.highlights.slice(0, 70)}...`}
                        <button
                          onClick={handleReadMoreClick}
                          className="ml-2 text-blue-500 hover:text-blue-700 underline focus:outline-none"
                        >
                          {isExpanded ? "Read Less" : "Read More"}
                        </button>
                      </span>
                    ) : (
                      <span>{student.highlights}</span>
                    )}
                  </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <Pagination
          currentPage={currentPage}
          totalRecords={totalRecords}
          recordsPerPage={recordsPerPage}
          onPageChange={handlePageChange}
          onRecordsPerPageChange={handleRecordsPerPageChange}
          className="w-full"
        />
        </>
        )}
      </div>
    </div>
  );  
};

export default CollegeDashboardMobile;