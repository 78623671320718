import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { getJWTtoken, getUserMetadata } from "../api/UserMetadata";
import { FetechUserProfile } from "../api/FetchUserProfile";
import google from "../assets/img/google.png";
import login from "../assets/img/login.png";
import { isUserTokenValid } from "../components/common/ValidateToken";
import Loginnav from "../components/Navbar/loginNav";
import LoadingSpinner from "../components/common/LoadingSpinner";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import EmailLoginMainPage from "./EmailLogin";



const LoginCallBack: React.FC = () => {
  const location: any = useLocation();
  const navigate = useNavigate();
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [showEmailPage, setShowEmailPage] = useState(false);
  const [showNameInput, setShowNameInput] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [idToken, setIdToken] = useState<string | null>(null);
  
  let { page } = location.state || { page: "" };

  useEffect(() => {
    const checkTokenAndSetupLogin = async () => {
      setLoading(true);
      const isValid = await isUserTokenValid();
      if (isValid) {
        const name = localStorage.getItem("Name");
        const profile = localStorage.getItem("profileIcon");
        const email = localStorage.getItem("Email");

        if (!name && !profile) {
          setShowNameInput(true);
          setShowEmailPage(true);
          if (email) {
            setUserEmail(email);
          }
        } else {
          navigate("/home");
        }
      }
      setLoading(false);
    };

    checkTokenAndSetupLogin();
  }, [navigate]);

  const appendTokenToUrl = (decodedUrl: string, token: string): string => {
    const url = new URL(decodedUrl);
    url.searchParams.set("ssoToken", token);
    return url.toString();
  };

  const handleOAuthRedirect = async () => {

    if (accessToken) {
      setLoading(true);
      try {
        const data = await getUserMetadata(accessToken);
        if (data?.name) {
          localStorage.setItem("Name", data.name);
        }
        if (data) {
          localStorage.setItem("AccessToken", `Bearer ${accessToken}`);
          const profileData: any = await FetechUserProfile();
          if (!localStorage.getItem("Name")) {
            localStorage.setItem("Name", profileData.response.name);
          }
          localStorage.setItem("profileIcon", profileData.profileURL);
          setSuccessMessage("Login Success");
          const token: any = await getJWTtoken();
          localStorage.setItem("LMSToken", token.token);
          localStorage.setItem("AccessToken", `Bearer ${token.token}`);
          
          if (page === "counselling") {
            navigate("/counselling");
          } else if (page === "pcj") {
            navigate("/premiumjourney");
          } else if (page === "journeybuilder") {
            navigate("/journeybuilder");
          }else if (page === "jobs") {
            navigate("/jobs");
          }
          else if (page === "journeybuilderview") {
            navigate("/journeybuilderview");
          } else if (page === "portfolio") {
            navigate("/portfolio");
          } else if (page === "trainings") {
            const encodedUrl = new URLSearchParams(location.search).get("returnurl");
            const decodedUrl = "https://trainings.skilljourney.in/";
            if (decodedUrl) {
              window.location.href = appendTokenToUrl(decodedUrl, token.token);
            }
          } else {
            navigate("/home");
          }
        }
      } catch (error) {
        console.error("Error during login:", error);
      } finally {
        setLoading(false);
      }
    }
};

  useEffect(() => {
    handleOAuthRedirect();
  }, );

  useEffect(() => {
    const urlHash = window.location.hash;
    if (urlHash) {
      const params = new URLSearchParams(urlHash.slice(1)); 
      const accessToken = params.get("access_token");
      const idToken = params.get("id_token");
      page=params.get('state')
      if (accessToken) {
        setAccessToken(accessToken);
        handleOAuthRedirect();
      }
      if (idToken) {
        setIdToken(idToken);
      }
    }
  });


  if (loading) {
    return <LoadingSpinner />;
  }



  return (
  <>
  </>
  );
};

export default LoginCallBack;
