import React, { useEffect, useState } from 'react';
import { Session } from '../../SessionsDetails/DetailedSessionPage';
import IframeComp from '../common/IframeComponent';
import { Box, Tab, Tabs, Tooltip } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { useNavigate } from 'react-router-dom';
import './PortfolioDesktopView.css';
import UserDetails from '../common/UserDetails';
import { fetchUserDetails } from '../../../../api/UserMetadata';

export interface PortfolioProps {
    currentSession: Session | null;
}

const PortfolioDesktopView: React.FC<PortfolioProps> = ({ currentSession }) => {
    const navigate = useNavigate();
    const [value, setValue] = useState(0);
    const [isNavigationEnabled, setIsNavigationEnabled] = useState(false);
    const [journeys, setJourneys] = useState({ standard: false, premium: false });

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const handleClick = () => {
        // Only allow navigation if the relevant journey is enabled
        if (isNavigationEnabled) {
            if (value === 0 && journeys.premium) {
                navigate(`/counsellor/userpremiumjourney?user_name=${JSON.stringify(currentSession?.clientName)}`, { state: { currentSession } });
            } else if (value === 1 && journeys.standard) {
                navigate(`/counsellor/userstandardjourney?user_name=${JSON.stringify(currentSession?.clientName)}`, { state: { currentSession } });
            }
        }
    };

    const journeyStatus = async () => {
        if (currentSession?.clientName) {
            const res = await fetchUserDetails(currentSession.clientName, navigate);

            // Set journeys based on conditions
            const premiumJourneyStatus = res.PremiumJourney.version > 0 && ['completed', 'published'].includes(res.PremiumJourney.journey_status);  // in future complited needs to be removed when premium journey not visible for complited status
            const standardJourneyStatus = res.StandardJourney.version > 0 && res.StandardJourney.journey_status === 'completed';
            
            setJourneys({ premium: premiumJourneyStatus, standard: standardJourneyStatus });
            setIsNavigationEnabled(premiumJourneyStatus || standardJourneyStatus);
        }
    };

    useEffect(() => { if (currentSession?.clientName) { journeyStatus() } }, [currentSession]);

    return (
        <div className='grid grid-cols-3 gap-2 px-5 py-2 h-[40rem] sm:h-[35rem] md:h-[38rem] lg:h-[43rem] xl:h-[45rem] 2xl:h-[45rem]'>
            <UserDetails currentSession={currentSession} />

            <IframeComp
                user_info={currentSession?.user_info}
                className={"w-full h-full shadow-lg rounded-lg border-2 border-gray-200 pb-10"}
                endpoint={"/journeybuilder"}
            />

            <div className='w-full shadow-lg border-2 border-gray-200 rounded-lg'>
                <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                        <Tab label="Premium Journey" />
                        <Tab label="Standard Journey" />
                    </Tabs>
                    <Tooltip 
                    title={`Navigate to ${value === 0 ? 'Premium ' : 'Standard '} Journey`} arrow >
                        <LaunchIcon
                            onClick={handleClick}
                            className={`cursor-pointer  ${isNavigationEnabled && (value === 0 ? journeys.premium : journeys.standard) ? 'mt-3 text-[#0778B6] hover:text-gray-700' : 'text-gray-400 cursor-not-allowed'}`}
                        />
                    </Tooltip>
                </Box>
                {value === 0
                    ? <IframeComp
                        user_info={currentSession?.user_info}
                        className={"w-full h-[40rem]"}
                        endpoint={"/premiumjourney"}
                    />
                    : <IframeComp
                        user_info={currentSession?.user_info}
                        className={"w-full h-[40rem]"}
                        endpoint={"/myjourney"}
                    />
                }
            </div>
        </div>
    );
};

export default PortfolioDesktopView;
