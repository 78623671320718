import React, { useState, useEffect, useRef } from "react";
import { AlertTriangle } from 'lucide-react';
import CloseIcon from '@mui/icons-material/Close';
import ReactMarkdown from 'react-markdown';

interface InputFileProps {
  label: string;
  name: string;
  isRequired: boolean;
  tooltip?: string;
  validations: {
    maxSizeInMB: number;
    allowedTypes: string[];
  };
  onChange: (file: File | null, bypassCheck: boolean) => void;
  resumeValidationError?: boolean;
  onResumeValidationErrorClear?: () => void;
  resumeErrorMessage?: string;
  description?: string;
}

const InputFile: React.FC<InputFileProps> = ({
  label,
  name,
  description,
  isRequired,
  tooltip,
  validations,
  onChange,
  resumeValidationError,
  onResumeValidationErrorClear,
  resumeErrorMessage,
}) => {
  const [error, setError] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null>(null);
  const [isDragging, setIsDragging] = useState<boolean>(false);
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [inputKey, setInputKey] = useState(Date.now());
  const toggleExpanded = () => setIsExpanded(prev => !prev);
  const validateFile = (file: File): boolean => {
    if (file.size > validations.maxSizeInMB * 1024 * 1024) {
      setError(`File size exceeds ${validations.maxSizeInMB} MB.`);
      return false;
    }
    if (!validations.allowedTypes.includes(file.type)) {
      setError("Invalid file type.");
      return false;
    }
    setError(null);
    return true;
  };

  const handleFileSelection = (file: File | null) => {
    if (file && validateFile(file)) {
      setFileName(file.name);
      onChange(file, false);
    } else {
      setFileName(null);
      onChange(null, false);
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0] || null;
    handleFileSelection(file);
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(true);
  };

  const handleDragLeave = () => {
    setIsDragging(false);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setIsDragging(false);
    const file = event.dataTransfer.files?.[0] || null;
    handleFileSelection(file);
  };

  const handleRemoveFile = () => {
    setFileName(null);
    onChange(null, false);
    setInputKey(Date.now()); 
  };

  const handleUseResume = () => {
    if (fileInputRef.current?.files?.[0]) {
      onChange(fileInputRef.current.files[0], true);
    }
    onResumeValidationErrorClear?.();
  };

  const handleCancel = () => {
    onResumeValidationErrorClear?.();
    handleRemoveFile();
  };

  useEffect(() => {
    const journeyData = localStorage.getItem("createJourneyData");
    if (journeyData) {
      const parsedData = JSON.parse(journeyData);
      if (parsedData[name]) {
        setFileName(parsedData[name]);
      }
    }
  }, [name]);

  return (
    <div className="w-full">
      <label htmlFor={name} className="block pb-5 text-lg font-medium text-gray-700">
        {label}
        {isRequired && <span className="text-red-500"> *</span>}
      </label>
      {tooltip && <p className="text-lg text-gray-500 mb-2">{tooltip}</p>}

      {description && (
        <div className="bg-[#FEF5E6] text-black text-sm p-2 rounded-md my-1">
          <div className={`${!isExpanded ? 'line-clamp-2' : ''}`}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          <span
            onClick={toggleExpanded}
            className="text-black cursor-pointer block text-right mt-1"
          >
            {isExpanded ? 'See less' : 'See more'}
          </span>
        </div>
      )}

      <div
        className={`flex items-center justify-center w-full h-40 border-2 transition-colors ${
          isDragging
            ? "border-blue-500 bg-blue-100"
            : "border-gray-300 bg-gray-50"
        } border-dashed rounded-lg`}
        onDragOver={handleDragOver}
        onDragLeave={handleDragLeave}
        onDrop={handleDrop}
      >
        <label
          htmlFor={name}
          className="flex flex-col items-center justify-center w-full h-full cursor-pointer"
        >
          <div className="flex flex-col items-center justify-center pt-5 pb-6">
            <svg
              className="w-8 h-8 mb-4 text-gray-500"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 16"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
              />
            </svg>
            <p className="mb-2 text-sm text-gray-500">
              <span className="font-semibold">Click to upload</span> or drag and drop
            </p>
            <p className="text-xs text-gray-500">
              {tooltip} (MAX. {validations.maxSizeInMB}MB)
            </p>
          </div>
          <input
          key={inputKey}
            ref={fileInputRef}
            id={name}
            name={name}
            type="file"
            className="hidden"
            onChange={handleInputChange}
          />
        </label>
      </div>

      {fileName && (
        <p className="text-sm mt-2 text-gray-700">
          <span className="font-medium">Uploaded file:</span> 
          <div className="flex items-center gap-2 text-sm text-gray-700">
            <span>{fileName}</span>
            <button
              onClick={handleRemoveFile}
              className="p-1 hover:bg-gray-100 rounded-full transition-colors"
              aria-label="Remove file"
            >
              <CloseIcon className="w-4 h-4 text-gray-500 hover:text-gray-700" />
            </button>
          </div>
        </p>
      )}
      {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
      {resumeValidationError && (
        <>
          <div 
            className="fixed inset-0 bg-black/20 backdrop-blur-[2px] z-50"
            onClick={handleCancel}
          />
          <div className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[90%] max-w-lg bg-white rounded-lg shadow-lg z-50 overflow-hidden p-4">
            <div className="relative w-full h-20 bg-amber-100 rounded-lg overflow-hidden mb-4">
              <div className="absolute left-0 top-0 h-full w-4 bg-amber-500 rounded-l-lg" />
              <div className="flex items-center gap-3 h-full pl-8">
                <AlertTriangle className="w-8 h-8 text-amber-500" />
                <div>
                  <h2 className="font-bold text-lg">Name Mismatch Detected</h2>
                  <p className="text-sm text-gray-700">
                    We've detected a mismatch in the name provided by you and the resume.
                  </p>
                </div>
              </div>
            </div>

            <div className="px-4 mb-6">
              <p className="text-gray-700">
                <strong> {resumeErrorMessage}</strong>
              </p>
            </div>

            <div className="flex gap-4 px-4">
              <button
                onClick={handleUseResume}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
              >
                Use this Resume
              </button>
              <button
                onClick={handleCancel}
                className="flex-1 px-4 py-2 border border-gray-300 rounded-md text-gray-700 hover:bg-gray-50 transition-colors"
              >
                Cancel and Update Resume
              </button>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default InputFile;
