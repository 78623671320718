import { BackendApi } from '../api/apiConfig/ApiFormatter'

export interface Course {
  title: string
  registrationDate: string
}

export interface ApiCourse {
  id: string
  Title: string
  'Assigned Date': string
}

export interface ApiLearner {
  email: string
  id: string
  name: string
  mobile: string
  active: boolean
  courses: ApiCourse[]
  'created date': string
  'last login': string
  state: string
}

export interface ApiResponse {
  message: string
  data: {
    data: ApiLearner[]
    total: number
    limit: number
    skip: number
  }
}

export class ApiError extends Error {
  constructor(message: string) {
    super(message)
    this.name = 'ApiError'
  }
}

const formatDate = (dateString: string): string => {
  const date = new Date(dateString)
  const day = date.getDate().toString().padStart(2, '0')
  const month = (date.getMonth() + 1).toString().padStart(2, '0')
  const year = date.getFullYear()
  return `${day}-${month}-${year}`
}

export const coursesApi = {
  fetchEnrolledCourses: async (): Promise<Course[]> => {
    const email = localStorage.getItem('Email')
    const lmsToken = localStorage.getItem('LMSToken')
    try {
      const response = await fetch(`${BackendApi()}fetchCourseDetails`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json', 
          Authorization: `Bearer ${lmsToken}`,
        },
        body: JSON.stringify({
          email: email,
        }),
      })

      if (!response.ok) {
        throw new ApiError(`Failed to fetch courses: ${response.statusText}`)
      }

      const responseData = await response.json()
      const learnerCourses = responseData.data?.data[0]?.courses
      if (!learnerCourses) {
        return [];
      }
      return learnerCourses.map((course: ApiCourse) => {
        return {
          title: course.Title,
          registrationDate: formatDate(course['Assigned Date']),
        }
      })
    } catch (error) {
      console.error('Error fetching courses:', error)
      if (error instanceof ApiError) {
        throw error
      }
      return [];
    }
  },
}
