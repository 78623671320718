import React, { useState } from "react";
import ReactMarkdown from 'react-markdown';

interface InputTextProps {
  label: string;
  name: string;
  tooltip?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired: boolean;
  description?: string;
}

const InputText: React.FC<InputTextProps> = ({
  label,
  name,
  tooltip,
  value,
  onChange,
  isRequired,
  description
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(prev => !prev);
  return (
    <div style={{ zIndex: "999999999999999" }}>
      <label
        className="block mb-5 text-lg font-medium text-gray-900"
        htmlFor={name}
      >
        {label}
        {isRequired && "*"}
      </label>
      {description && (
        <div className="bg-[#FEF5E6] text-black text-sm p-2 rounded-md my-1">
          <div className={`${!isExpanded ? 'line-clamp-2' : ''}`}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          <span
            onClick={toggleExpanded}
            className="text-black cursor-pointer block text-right mt-1"
          >
            {isExpanded ? 'See less' : 'See more'}
          </span>
        </div>
      )}
      <input
        className="bg-white border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-gray-500 focus:outline-none block w-full p-2.5"
        type="text"
        id={name}
        name={name}
        value={value}
        onChange={onChange}
        required={isRequired}
        placeholder={tooltip || ""}
      />
    </div>
  );
};

export default InputText;
