import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

interface TypingEffectProps {
  text: string;
}

const TypingEffect: React.FC<TypingEffectProps> = ({ text }) => {
  const [displayedText, setDisplayedText] = useState<string>('');

  const speed = 400 / text.length;

  useEffect(() => {
    let index = 0; 
    const typing = () => {
      if (index < text.length-1 && displayedText.length!=text.length) {
        setDisplayedText((prev) => prev + text[index]);
        index += 1; 

      }
      setTimeout(typing, speed);

    };
    
    typing(); 
    return () => {
      index = text.length; 
    };
  }, [text, speed]);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <h2>{displayedText}</h2>
    </motion.div>
  );
};

export default TypingEffect;
