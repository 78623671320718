import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

interface InputRangeProps {
  label: string;
  name: string;
  min: number;
  max: number;
  value: number;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired: boolean;
  description?: string;
}

const InputRange: React.FC<InputRangeProps> = ({ label, name, description, min, max, value, onChange, isRequired }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(prev => !prev);
  return (
    <div className="relative mb-5" style={{"zIndex":"999999999999999"}}>
      <label className="text-lg" htmlFor={name}>{label}{isRequired && '*'}</label>
      {description && (
        <div className="bg-[#FEF5E6] text-black text-sm p-2 rounded-md my-1">
          <div className={`${!isExpanded ? 'line-clamp-2' : ''}`}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          <span
            onClick={toggleExpanded}
            className="text-black cursor-pointer block text-right mt-1"
          >
            {isExpanded ? 'See less' : 'See more'}
          </span>
        </div>
      )}
      <input
       className="w-full h-4 bg-gray-200 rounded-lg range-lg cursor-pointer range accent-range accent-range-bg"
        type="range"
        id={name}
        name={name}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        required={isRequired}
      />
      <span className="text-sm text-gray-500 absolute start-0 -bottom-6 min-val">₹ {value}</span>
      <span className="text-sm text-gray-500 absolute end-0 -bottom-6 max-val">₹ {max}</span>
    </div>
  );
};

export default InputRange;
