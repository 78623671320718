"use client";
import React, { useState, useEffect, useRef } from "react";
import SectionRenderer from "../../../components/user/Questionnaire/DynamicJourneyBuilder/SectionRenderer";
import CreateNavbar from "../../../components/Navbar/Navbar";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserSearchLogs } from "../../../api/Questionnaire"; // Import the API function
import LoadingSpinner from "../../../components/common/LoadingSpinner";
import { fetchUserAnswer } from "../../../api/DynamicQuestionnaire";
import { useTokenValidator } from "../../../components/tokenvalidator";

export default function SectionWiseQuestionnaire() {
  const [selectedSectionId, setSelectedSectionId] = useState<number>(0);
  const [progressdata, setProgress] = useState<number>(0);
  const [formData, setFormData] = useState<Record<string, any>>(() => {
    const savedData = localStorage.getItem("createJourneyData");
    return savedData ? JSON.parse(savedData) : {};
  });
  const navigate = useNavigate(); // Initialize navigate
  const location = useLocation();
  const [isCollegePortfolio, setIsCollegePortfolio] = useState(false);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0); // Track current question
  const [errorMessage, setErrorMessage] = useState("");
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const sectionRefs = useRef<(HTMLDivElement | null)[]>([]);
  const sectionContentRef = useRef<HTMLDivElement | null>(null);
  const [jsonData, setJsonData] = useState<{ sections: any[] }>({ sections: [] }); // State to store fetched data
  const stateRef = useRef({ page: "journeybuilderview" });
  const { loading } = useTokenValidator('/login', stateRef.current);
  const [questionLoading, setQuestionLoading] = useState<boolean>(true);

  const setErrorMessageWithTimeout = (message: any) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(""), 3000); // Clear message after 3 seconds
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    try {
      const isInIframe = window !== window.parent;
      if (isInIframe) {
        const parentUrl = window.parent.location.pathname;
        setIsCollegePortfolio(parentUrl === '/college-portfolio');
      } else {
        setIsCollegePortfolio(location.pathname === '/college-portfolio');
      }
    } catch (error) {
      const referrer = document.referrer;
      setIsCollegePortfolio(referrer.includes('/college-portfolio'));
    }
  }, [location]);

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("AccessToken");
      const email = localStorage.getItem("Email");  
  
      if (!token || !email) {
        console.error("No token or email found.");
        return;
      }
  
      try {
        const response = await fetchUserAnswer(token, email); 
        if (response && response.data) {
          setFormData(response.data);
          localStorage.setItem("createJourneyData", JSON.stringify(response.data));
        } 
      } catch (error) {
        console.error("Failed to fetch user answers:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
      const fetchQuestionSet = async () => {
        try {
          setQuestionLoading(true);
          const data = await fetchUserSearchLogs(2); 
          setJsonData({ sections: data.questions }); 
        } catch (error) {
          console.error("Failed to fetch question set:", error);
        }finally {
          setQuestionLoading(false);
        }
      };
      fetchQuestionSet();
    }, []);

  useEffect(() => {
  const calculateProgress = () => {
    let totalQuestions = 0;
    let answeredQuestions = 0;
    jsonData.sections.forEach((section) => {
      section.questions.forEach((question: { isRequired: any; name: string | number; }) => {
        if (question.isRequired) {
          totalQuestions++;
          if (formData[question.name]?.length > 0) {
            answeredQuestions++;
          }
        }
      });
    });
    if (totalQuestions === 0) {
      setProgress(0); 
      return;
    }
    const progress = (answeredQuestions / totalQuestions) * 100;
    setProgress(progress);
  };
  calculateProgress();
  }, [formData, jsonData]);

  const handleSectionChange = (sectionId: number, index: number) => {
    setSelectedSectionId(sectionId);
    setCurrentQuestionIndex(0); // Reset question index
    setIsScrollable(true); 
    
    if (sectionRefs.current[index]) {
      sectionRefs.current[index]?.scrollIntoView({behavior: "smooth",block: "center",inline: "center"});
    }
    if (sectionContentRef.current) {
      sectionContentRef.current.scrollTo({ top: 0});
    }
  };

  const handleInputChange = (name: string, value: any) => {
    setFormData((prev) => {
      const updatedFormData = { ...prev, [name]: value };
      localStorage.setItem("createJourneyData", JSON.stringify(updatedFormData));
      return updatedFormData;
    });
  };

  const selectedSection = jsonData.sections.find(
    (section) => section.sectionId === selectedSectionId
  );

  if (loading || questionLoading) {
    return <LoadingSpinner />;
  }


  return (
    <div className="h-screen flex flex-col overflow-hidden">
      {progressdata === 100 ? (
        <div className="flex flex-col h-full overflow-hidden overflow-y-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none]">
          <CreateNavbar page="home" />
              <div className="flex-shrink-0">
              <div className={`createContainer ${
                  isScrollable
                    ? "overflow-x-auto [&::-webkit-scrollbar]:hidden [-ms-overflow-style:none] [scrollbar-width:none] whitespace-nowrap"
                    : "grid grid-cols-2 md:grid-cols-3 gap-6"
                }`}
                style={{
                  gap: "20px",
                  justifyItems: "center",
                  textAlign: "center",
                }}
                >
                  {jsonData.sections.map((section, index) => {
                    let isSectionComplete = true;

                    for (const question of section.questions) {
                      if (question.isRequired && (!formData[question.name] || formData[question.name].toString().trim() === "")) {
                        isSectionComplete = false;
                        break;
                      }
                    }
                    return (
                      <div
                        key={section.sectionId}
                        ref={(el) => (sectionRefs.current[index] = el)}
                        className={`transition-all duration-300 ${
                          isScrollable ? "inline-block" : "flex flex-col items-center"
                        }`}
                        style={{
                          ...(isScrollable ? {
                            width: "64px",
                            height: "64px",
                            padding: "0",
                            marginRight: "12px",
                            marginLeft: "6px",
                            borderRadius: "50%",
                            display: "inline-flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: isSectionComplete
                              ? "2px solid #0778B6"
                              : "2px solid #828282",
                            backgroundColor: isSectionComplete
                              ? "#ECFAFD"
                              : "#fff",
                            transform: selectedSectionId === section.sectionId ? "scale(1.1)" : "scale(1)",
                          } : {
                          cursor: "pointer",
                        }),
                      }}
                      onClick={() => handleSectionChange(section.sectionId, index)}
                    >
                      {!isScrollable ? (
                        <>
                        <div
                          style={{
                            width: "64px",
                            height: "64px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            border: isSectionComplete
                              ? "1.5px solid #0778B6" // Light green for completed sections
                              : "1.5px solid #828282",
                            backgroundColor: isSectionComplete
                              ? "#ECFAFD"
                              : selectedSectionId === section.sectionId
                              ? "#f0f8ff"
                              : "#fff",
                            transform: selectedSectionId === section.sectionId ? "scale(1.1)" : "scale(1)",
                        }}
                      >
                        <div className="w-8 h-8 flex items-center justify-center">
                          <img
                            className="w-full h-full object-contain"
                            src={isSectionComplete ? section.selected_image : section.image}
                            alt={`${section.title} icon`}
                          />
                        </div>
                        </div>
                        <h3
                          style={{
                            fontWeight: "500",
                            color: isSectionComplete
                              ? "#0778B6"
                              : selectedSectionId === section.sectionId
                              ? "#828282"
                              : "#828282",
                            wordBreak: "break-word",
                            whiteSpace: "normal",
                            overflow: "hidden",
                            textAlign: "center",
                            maxWidth: "120px"
                          }}
                        >
                          {section.title}
                        </h3>
                      </>
                    ) : (
                      <div className={`w-8 h-8 flex items-center justify-center`}>
                        <img
                          className="w-full h-full object-contain"
                          src={isSectionComplete ? section.selected_image : section.image}
                          alt={`${section.title} icon`}
                        />
                      </div>
                    )}
                      </div>
                    );
                  })}
                </div>
              </div>

        <div className="flex-1 overflow-y-auto" ref={sectionContentRef}>
          {selectedSectionId !== 0 && selectedSection && (
            <div className="createContainer overflow-y-auto createJourneyContainer pointer-events-none">
              {selectedSection.questions.map((question: { name: any; }, index: React.Key | null | undefined) => {
                // Safely retrieve createJourneyData from localStorage
                const createJourneyData = JSON.parse(
                  localStorage.getItem("createJourneyData") || "{}"
                );

                // Check if the question name exists in createJourneyData
                if (createJourneyData.hasOwnProperty(question.name)) {
                  return (
                    <div key={index} className="pointer-events-none">
                      <SectionRenderer
                        section={{
                          ...selectedSection,
                          questions: [question],
                        }}
                        formData={formData}
                        onInputChange={handleInputChange}
                      />
                      <br />
                    </div>
                  );
                }
                return null;
              })}
            </div>
          )}
        </div>
        </div>
      ) : (
        <div className="bg-gray-50 min-h-screen flex flex-col items-center justify-center">
          <div className="text-center mb-4">
            <h2 className="text-2xl font-bold text-gray-800 mb-2">
              Journey Builder
            </h2>
            <p className="text-base text-gray-600 w-auto p-2">
              {isCollegePortfolio 
                ? "Journey Builder not yet filled. Once completed, the information will be displayed here."
                : "Kindly ensure all sections are filled out before proceeding. Once completed, the information will be displayed here. now!"
              }
            </p>
          </div>

          {!isCollegePortfolio && (
          <button
            onClick={() => window.open("/journeybuilder", "_blank")}
            className="px-5 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300 transform hover:scale-105"
          >
            Please click here to fill in the required details.
          </button>
        )}
        </div>
      )}
    </div>
  );
}
