import { getQueryParam } from "../../src/components/CounsellorUser/CounsellorsClientPortFolio/common/util";
import { BackendApi } from "./apiConfig/ApiFormatter";
import axios from 'axios';


export const FetechUserResponses = async (): Promise<any[]> => {
    const apiUrl = BackendApi() + "readUserQuestionnaire";
    const user_info = JSON.parse(getQueryParam('user_info') as string) || null;

    try {
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from localStorage
        const email = user_info ? user_info.email : localStorage.getItem("Email");

        const response = await axios.post(apiUrl, 
            { email }, 
            {
                headers: {
                    'Authorization': `${idToken}`, // Add Bearer prefix if needed
                    'Content-Type': 'application/json'
                }
            }
        );
        console.log('User questionnaire:', response);

        // Check for an error based on HTTP status code
        if (response.status !== 200) {
            console.log('Error fetching user questionnaire');
            return [];
        }

        const questionWithAnswers = response.data;
        if (questionWithAnswers === null) return [];
        console.log('User questionnaire:', questionWithAnswers.data);
        return questionWithAnswers.data ?? [];
    } catch (error) {
        console.error('Error fetching user questionnaire:', error);
        throw error; // Rethrow error to handle it in the component
    }
};
