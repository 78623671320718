import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';

interface InputRadioProps {
  label: string;
  name: string;
  options: { value: string; label: string }[];
  selectedValue: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isRequired: boolean;
  description?: string;
}

const InputRadio: React.FC<InputRadioProps> = ({ label, name, description, options, selectedValue, onChange, isRequired }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpanded = () => setIsExpanded(prev => !prev);
  return (
    <div>
      <label className="block mb-5 text-lg font-medium text-gray-900">{label}{isRequired && '*'}</label>
      {description && (
        <div className="bg-[#FEF5E6] text-black text-sm p-2 rounded-md my-1">
          <div className={`${!isExpanded ? 'line-clamp-2' : ''}`}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          <span
            onClick={toggleExpanded}
            className="text-black cursor-pointer block text-right mt-1"
          >
            {isExpanded ? 'See less' : 'See more'}
          </span>
        </div>
      )}
      {options.map((option) => (
        <div className="invisible-radio" key={option.value} style={{"zIndex":"999999999999999"}}>
          <input
            type="radio"
            id={option.value}
            name={name}
            value={option.value}
            checked={selectedValue === option.value}
            onChange={onChange}
            required={isRequired}
          />
          <label className="checkbox-alias-radio" htmlFor={option.value}>{option.label}</label>
        </div>
      ))}
    </div>
  );
};

export default InputRadio;
