import React, { useEffect } from 'react';
import shipping from "../assets/img/shipping.png";
import CreateNavbar from '../components/Navbar/Navbar';

const Shipping: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="flex overflow-y-auto flex-col">
            <CreateNavbar page='about' />
            <div className="px-10 md:px-14 flex md:justify-center justify-center mt-8 md:mt-4 md:flex text-center">
                <h2 className="font-sans font-bold text-2xl md:text-3xl mb-4 text-black">
                    SHIPPING AND DELIVERY
                </h2>
            </div>

            <div className="px-10 md:px-24 flex md:justify-end justify-center mt-8 md:mt-9  md:flex">
                <img src={shipping} alt="shipping" className="w-96 h-auto" />
            </div>
            {/* Content Section */}
            <div className="flex flex-col lg:flex-row  mt-8 md:mt-[-420px]">

                {/* Text content */}
                <div className=" text-left px-10 md:px-24">

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color">
                        1. INTRODUCTION
                    </h2>

                    <p className="font-normal text-sm mb-4 font-sans">
                        This Privacy Policy outlines how SkillJourney LLP ("we," "us," <span className="hidden md:inline "><br /></span>"our") collects, uses, and protects your
                        personal information when <span className="hidden md:inline "><br /></span> you use SkillJourney NorthStar, our
                        career guidance platform. By <span className="hidden md:inline "><br /></span> accessing and using our web application,
                        you agree to the terms <span className="hidden md:inline "><br /></span> of this Privacy Policy.
                    </p>

                    {/* Additional content sections */}

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color ">
                        2. COMPANY INFORMATION
                        <ul className=" font-normal font-sans text-sm  list-disc text-black pt-4 pl-8">
                            <li>Company Name: SkillJourney LLP</li>
                            <li>Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar,<span className="hidden md:inline"><br /></span> Paud Road, Pune 411038</li>
                            <li>Current Address: 6th Floor, AWFIS Quantum Works, Karve Road, Nal Stop, Pune 411004</li>
                            <li>Contact Email: <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in</a></li>

                        </ul>
                    </h2>


                    {/* Section 3 */}
                    <h2 className="font-sans font-bold text-2xl mb-4 text-privacy-color mt-6">
                        3. SERVICE DELIVERY
                        <h3 className="font-semibold font-sans text-lg mb-4 text-black pt-4">Account Creation and Activaton
                            <ul className=" font-normal font-sans text-sm  list-disc text-black pt-4 pl-8">
                                <li>Account Creation: Users must create an account to access the services of SkillJourney NorthStar. Upon successful registration, users will receive a confirmation email with account details.</li>
                                <li>Account Activation: Once the account is created, users can immediately access the Basic Tier services for free. For Premium Tier services, users must complete the payment process.</li>

                            </ul>
                        </h3>

                        <h3 className="font-semibold font-sans text-lg mb-4 text-black">PREMIUM TIER SUBSCRIPTION
                            <ul className=" font-normal text-sm  list-disc font-sans pt-4 pl-8">

                                <li>Subscription Fee: The Premium Tier is available for Rs. 999/- per annum.</li>
                                <li>Access: Upon successful payment, users will receive immediate access to Premium Tier features. A confirmation email with subscription details will be sent to the registered email address.</li>

                            </ul>
                        </h3>

                        <h3 className="font-semibold font-sans text-lg mb-4 text-black">Delivery and Digital Services
                            <ul className=" font-normal text-sm  list-disc font-sans pt-4 pl-8">

                                <li>Career Journey Plan: Users can generate a personalized career journey plan using the platform. The plan is delivered digitally and can be accessed through the user's account.</li>
                                <li>Guidance and Tracking: The platform provides ongoing guidance and allows users to track their progress. All services are delivered digitally through the web application.</li>

                            </ul>
                        </h3>

                    </h2>


                    {/* Section 4 */}
                    <h2 className="font-sans font-bold text-2xl mb-4 text-privacy-color mt-6">
                        4. USER RESPONSIBILITIES
                        <h3 className="font-semibold font-sans text-lg mb-4 text-black pt-4">Accurate Information
                            <ul className=" font-normal font-sans text-sm  list-disc text-black pt-4 pl-8">
                                <li>Users must provide accurate and complete information during account creation and throughout their use of the platform to ensure the best possible service delivery.</li>
                            </ul>
                        </h3>

                        <h3 className="font-semibold font-sans text-lg mb-4 text-black">Prohibites Actions
                            <ul className=" font-normal text-sm  list-disc font-sans pt-4 pl-8">

                                <li>Users are prohibited from posting offensive content, hacking, spamming, spoofing, or phishing. Any such actions may result in termination of the user account.
                                </li>

                            </ul>
                        </h3>

                        <h3 className="font-semibold font-sans text-lg mb-4 text-black">Payment Information
                            <p className='font-normal font-sans text-sm pt-4'>We use your payment information to process transactions for the Premium Tier subscription.
                            </p>
                        </h3>

                    </h2>
                    {/* Section 5 */}
                    <h2 className="font-sans font-bold text-2xl mb-4 text-privacy-color mt-6">
                        5. REFUND AND CANCELLATION


                        <h3 className="font-semibold font-sans text-lg mb-4 text-black pt-4">Third-Party Service Providers
                            <p className='font-normal font-sans text-sm pt-4'>Please refer to our Cancellation and Refund Policy for details on refunds and cancellation of the Premium Tier subscription.
                            </p>
                        </h3>

                    </h2>
                    <h2 className="font-sans font-bold text-2xl mb-4 text-privacy-color mt-6">
                        6.  CONTACT INFORMATION
                        <h3 className="font-normal font-sans text-lg mb-4 text-black pt-4">If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:
                            <ul className=" font-normal text-sm  list-disc font-sans pl-8">

                                <li>Email: <a href="mailto:support@skilljourney.in" className="text-highlight-color">legal@skilljourney.in</a>
                                </li>

                            </ul>
                        </h3>
                    </h2>
                    <p className='font-normal font-sans text-sm my-4'>By using SkillJourney NorthStar, you acknowledge that you have read, understood, and agree to this Privacy Policy.
                    </p>
                </div>
            </div>
        </div>


    );
}

export default Shipping;
