import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import defaultAvatar from '../../assets/img/Avatar.png';
import Logo from '../../assets/img/skillJourneyMainLogo.svg';
import homeIcon from '../../assets/img/Contact/home.svg';
import Logout from '../../assets/img/Contact/Logout.svg';
import back from '../../assets/img/Contact/back.svg';
import Fingerprint from '../../assets/img/Contact/Fingerprint.svg';
import feedback from '../../assets/img/Contact/Feedback.svg';
import useIsIframe from '../CounsellorUser/CounsellorsClientPortFolio/common/IsIframe';
import { getUserMetadata } from '../../api/UserMetadata';
import { isUserTokenValid } from '../common/ValidateToken';
import LoadingSpinner from '../common/LoadingSpinner';

interface NavbarProps {
  page: string;
  bgColor?: string;
}

const CollegeDashboardNavbar: React.FC<NavbarProps> = ({ page, bgColor }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [userProfileIcon, setUserProfileIcon] = useState(() => localStorage.getItem('profileIcon') || defaultAvatar);
  const [isUserLoggedIn, setUserStatus] = useState(false);
  const [showNameTooltip, setShowNameTooltip] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();
  const navigate = useNavigate();
  const name: any = localStorage.getItem('Name');

  const handleNamePress = () => {
    if ((name?.length ?? 0) > 28) {
      setShowNameTooltip(true);
      setTimeout(() => setShowNameTooltip(false), 3000);
    }
  };

  useEffect(() => {
    const initialize = async () => {
      const isValid = await isUserTokenValid();
      if (!isValid) {
        navigate('/college-login');
        return; 
      }
      fetchUserProfile();
      setLoading(false);
    };
    initialize();
  }, [navigate]);

    const fetchUserProfile = async () => {
      try {
        const profileIcon: any = localStorage.getItem("profileIcon");
        const email = localStorage.getItem('email');

        if (profileIcon && profileIcon !== '') {
          setUserProfileIcon(profileIcon);
          setUserStatus(true);
        }

        if (email) {
          setUserStatus(true);
        }

        if (location.pathname === "/") {
          setUserStatus(false);
        }
      } catch (error) {
        console.error('Error fetching user profile icon:', error);
      }
    };

  useEffect(() => {
    if (!loading) {
      fetchUserProfile();
    }
  }, [location.pathname, loading]);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'auto';
  };

  const enableScrolling = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = 'auto';
  };

  const toggleDesktopMenu = () => {
    setIsDesktopMenuOpen(!isDesktopMenuOpen);
  };

  const handleCloseDesktopMenu = () => {
    if (isDesktopMenuOpen) {
      setIsDesktopMenuOpen(false);
    }
  };

  const handleLogout = async () => {
    try {
      localStorage.clear();
      navigate('/college-login');
    } catch (error) {
      console.error('Logout failed', error);
    }
  };

  const handleHomeClick = async () => {
    try {
      const accessToken = localStorage.getItem('accessToken');
      if (accessToken) {
        await getUserMetadata(accessToken);
      }

      navigate('/');
    } catch (error) {
      console.error('Error handling home click:', error);
    }
  };

  const homeLink = [
    { to: "/", label: "SkillJourney Home", onClick: handleHomeClick }
  ];

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <nav className={`relative ${bgColor || 'bg-white'}`}>
      {isDesktopMenuOpen && (
        <div className="fixed inset-0 z-30" onClick={handleCloseDesktopMenu}></div>
      )}
      <div className="max-w-full px-4 sm:px-6 lg:px-9">
        <div className="relative flex h-16 items-center justify-between">
          {/* Logo for Mobile View */}
          <div className="absolute inset-y-1 left-0 flex items-center max-custom-md:block custom-md:hidden z-10">
            <a href="/" className="inset-y-10">
              <img src={Logo} alt="Logo" className="h-20 w-[140px] md:w-[150px] md:h-10 mx-auto py-2" />
            </a>
          </div>

          {/* Mobile Menu Button */}
          <div className="absolute inset-y-0 right-2 flex items-center max-custom-md:block custom-md:hidden z-10">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 text-black"
              onClick={toggleMenu}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                  stroke="black"
                />
              </svg>
            </button>
          </div>

          {/* Desktop Menu */}
          <div className="hidden custom-md:flex items-center justify-between w-full mx-14">
            <div className="flex-shrink-0">
              <a href="/" className="inset-y-10">
                <img src={Logo} alt="Logo" className="w-[160px] md:h-20" />
              </a>
            </div>

            <div className="flex items-center space-x-4 ml-5">
              {homeLink.map((link) => (
                <Link 
                  key={link.to} 
                  to={link.to} 
                  className="rounded-md px-3 py-2 text-base font-semibold font-sans text-black"
                  onClick={link.onClick}
                >
                  {link.label}
                </Link>
              ))}

              {isUserLoggedIn && (
                <div className="relative flex items-center">
                  <img
                    src={userProfileIcon}
                    alt="Avatar"
                    className="h-10 w-10 rounded-full object-cover cursor-pointer"
                    onClick={toggleDesktopMenu}
                  />
                </div>
              )}
            </div>
          </div>

          {/* Mobile Menu Overlay */}
          {isMenuOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-30"
              onClick={enableScrolling}
            ></div>
          )}

          {/* Mobile Menu Content */}
          <div
            className={`fixed top-0 right-0 z-40 h-full w-[80%] md:w-[50%] bg-menuebar shadow-lg transform transition-transform ${
              isMenuOpen ? 'translate-x-0' : 'translate-x-full'
            } custom-md:hidden`}
          >
            <div className="relative flex flex-col h-full">
              {/* Profile Section */}
              <div className="py-3 text-xl font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color py-2">
                <div className="flex items-center pt-1 py-1">
                  <div className="h-12 w-12 rounded-full overflow-hidden flex-shrink-0">
                    <img src={userProfileIcon} alt="Avatar" className="h-full w-full object-cover" />
                  </div>
                  <div className="flex items-center pt-1 py-1">
                    {(name?.length ?? 0) > 18 ? (
                      <div className="relative">
                        <div className="flex flex-col w-44 break-words overflow-hidden" onClick={handleNamePress}>
                          <span className="pl-1 text-black text-sx font-medium line-clamp-2 font-sans break-words">
                            {name || ''}
                          </span>
                          {showNameTooltip && (name?.length ?? 0) > 28 && (
                            <div className="absolute z-50 p-1 px-2 bg-white text-black text-sm rounded-md whitespace-normal max-w-[300px] break-words -bottom-16 right-[-60px]">
                              {name}
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <span className="pl-1 text-black text-sx font-medium leading-7 font-sans break-words truncate w-44">
                        {name || ''}
                      </span>
                    )}
                  </div>
                </div>
              </div>

              {/* User Role */}
              <div className="relative">
                <div className="text-custom-blue flex justify-center text-xl font-bold font-sans ml-4">
                  College Admin
                </div>
                {/* Back Button */}
                <div
                  className={`absolute -left-5 bottom-[-70px] h-16 w-16 rounded-full bg-menuebar flex items-center justify-center cursor-pointer z-50 ${isMenuOpen ? 'block' : 'hidden'}`}
                  onClick={() => {
                    toggleMenu();
                    setIsMenuOpen(false);
                  }}
                >
                  <img
                    src={back}
                    alt="Back Icon"
                    className="h-6 w-6 transform -translate-x-3"
                  />
                </div>
              </div>

              {/* Navigation Links */}
              <div className="flex flex-col flex-grow justify-between p-2 pt-6 h800:pt-20">
                <div className="flex flex-col space-y-px -ml-2">
                  {homeLink.map((link) => (
                    <Link
                      key={link.to}
                      to={link.to}
                      className="flex items-center py-3 h800:py-4 text-base font-bold font-sans text-black border-b-2 border-bloc-color mx-auto w-60"
                      onClick={() => {
                        setIsMenuOpen(false);
                        link.onClick();
                      }}
                    >
                      <div className="flex items-center pl-7">
                        <img src={homeIcon} alt="Home Icon" className="inline-block h-5 w-6 mr-6" />
                        {link.label}
                      </div>
                    </Link>
                  ))}

                  {/* Feedback Link */}
                  <div className="py-3 h800:py-4 text-base font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color">
                    <a href="https://forms.office.com/pages/responsepage.aspx?id=7O6Egwz4PUysWxEbpZOPOw5hSyQXevpHgsHHAq7nfvVUNzNKSUVBTkNYVVRLQ1NRVTJTUzg3VktRTy4u&route=shorturl" 
                       target="_blank" 
                       rel="noopener noreferrer">
                      <div className="flex items-center pl-7">
                        <img src={feedback} alt="Feedback Icon" className="inline-block h-5 w-6 mr-6" />
                        Feedback
                      </div>
                    </a>
                  </div>
                </div>

                {/* Logout Button */}
                <div className="absolute h800:bottom-5 bottom-1">
                  <div className="flex items-center cursor-pointer space-x-2 absolute bottom-0 ml-12" onClick={handleLogout}>
                    <img src={Logout} alt="Logout Icon" className="h-6 w-6" />
                    <span className="text-black text-lg font-bold font-sans">Logout</span>
                  </div>
                </div>
              </div>

              {/* Fingerprint Image */}
              <div className="absolute bottom-0 right-0">
                <img src={Fingerprint} alt="Fingerprint Icon" className="h-auto w-20 h800:w-28" />
              </div>
            </div>
          </div>

          {/* Desktop Menu Dropdown */}
          <div
            className={`fixed top-0 right-0 z-40 h-auto w-64 bg-menuebar shadow-lg transform transition-transform ${
              isDesktopMenuOpen ? '-translate-x-28' : 'translate-x-full'
            } hidden sm:block`}
            style={{ marginTop: '3.5rem' }}
          >
            <div className="relative flex flex-col h-full">
              <div className="py-3 text-xl font-bold font-sans mx-auto w-48 text-black border-b-2 border-bloc-color py-2">
                <div className="flex items-center justify-center pt-1 py-1">
                  <span className="text-black text-base font-medium leading-5 line-clamp-2 overflow-hidden font-sans break-words whitespace-normal text-center" 
                        title={name.length > 20 ? name : ""}>
                    {name}
                  </span>
                </div>
              </div>

              <div className="relative">
                <div className="text-custom-blue flex justify-center text-sm font-bold font-sans ml-4">
                  College Admin
                </div>
              </div>

              <div className="flex flex-col flex-grow justify-between p-4 md:pt-4 lg:pt-2">
                <div className="flex flex-col space-y-px">
                  {/* Feedback Link */}
                  <a href="https://forms.office.com/pages/responsepage.aspx?id=7O6Egwz4PUysWxEbpZOPOw5hSyQXevpHgsHHAq7nfvVUNzNKSUVBTkNYVVRLQ1NRVTJTUzg3VktRTy4u&route=shorturl" 
                     target="_blank" 
                     rel="noopener noreferrer">
                    <div className="py-2 text-base font-semibold font-sans mx-auto w-48 text-black border-b-2 border-bloc-color">
                      <div className="flex items-center pl-2">
                        <img src={feedback} alt="Feedback Icon" className="inline-block h-5 w-6 mr-2" />
                        Feedback
                      </div>
                    </div>
                  </a>

                  {/* Logout Link */}
                  <div className="py-2 text-base font-semibold font-sans mx-auto w-48 text-black">
                    <div className="flex items-center cursor-pointer pl-2" onClick={handleLogout}>
                      <img src={Logout} alt="Logout Icon" className="inline-block h-5 w-6 mr-2" />
                      Logout
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

interface CreateNavbarProps {
  page: string;
}

const CreateCollegeDashboardNavbar: React.FC<CreateNavbarProps> = ({ page }) => {
  const isIframe = useIsIframe();
  if (isIframe) {
    return null;
  }
  return <CollegeDashboardNavbar page={page}  />;
};

export default CollegeDashboardNavbar;