import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import CreateNavbar from '../components/Navbar/Navbar';
import PremiumJourneyBody from '../components/PremiumJourneys/PremiumJourneyBody';
import '../components/PremiumJourneys/PremiumJourneyBody.css'
import { FetchPremiumJourney, FetchPremiumJourneybyVerion } from '../api/FetchHtmlJourney';
import { isUserTokenValid } from '../components/common/ValidateToken';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { isMobile } from 'react-device-detect';
import LeftPremiumSidebar from '../components/sidebar/LeftPremiumSidebar';
import RightPremiumSidebar from '../components/sidebar/RightPremiumSidebar';
import useIsIframe from '../components/CounsellorUser/CounsellorsClientPortFolio/common/IsIframe';
import JourneyInsights from '../components/common/error/JourneyInsights';
import { PremiumJourneyStatusCheck } from '../api/JourneyStatusCheck';
import MovingTextBanner from '../components/common/MovingTextBanner';

const PremiumJourney: React.FC = () => {
  const [premiumJourney, setPremiumJourney] = useState<any>({});
  const [isResponsive, setIsResponsive] = useState<boolean>(false);
  const navigate = useNavigate();
  const isIframe = useIsIframe();
  const [loading, setLoading] = useState<boolean>(true);
  const userLevel = localStorage.getItem("userLevel");
  const [journeyStatus, setJourneyStatus] = useState<any>(null);
  const [isPreviousJourney, setPreviousJourney] = useState<boolean>(false);
  const location: any = useLocation(); // Get the current location
  const { pcj } = location.state || { pcj: 0 };
  const handleResize = useCallback(() => {
    setIsResponsive(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);


  const fetchPremiumJourney = async () => {
    if (!(await isUserTokenValid())) {
      navigate('/login', { state: { page: "pcj" } });
      return;
    }
    let journeyCheck;
    let journeyData;
    if(pcj>0){   
      journeyData = await FetchPremiumJourneybyVerion(pcj);
      setPreviousJourney(true);
    }else{
      journeyCheck = await PremiumJourneyStatusCheck();
      setJourneyStatus(journeyCheck)
      console.log(journeyCheck.journey_status)
      if (journeyCheck?.journey_status === 'non-existence') {
        const userLevel = localStorage.getItem('userLevel'); 
        if (userLevel === 'College Admin') {
        } else {
          setLoading(false);
          return <JourneyInsights message="Premium" />;
        }
      }
    
       if (!userLevel?.includes('Counselor') && journeyCheck && [ 'failed','completed','initiated','generating'].includes(journeyCheck.journey_status)){
        navigate('/premiumjourneyloading');
      }
      journeyData = await FetchPremiumJourney();
    } 
    setPremiumJourney(journeyData);
    setLoading(false)
  }

  useEffect(() => { 
    fetchPremiumJourney();
  },[]);

  if (loading) {
    return <LoadingSpinner />;
  }


  const isJourneyAvailable = premiumJourney && premiumJourney.premium_journey;
  if (!isJourneyAvailable ) {
    return <JourneyInsights message='Premium' />;
  }
  
  return (
    <div className="h-screen font-sans">
      {!isIframe && <div className='relative z-20'><CreateNavbar page='premiumJourney' /></div>}
      <div className='fixed top-0 left-0 right-0'>
      {isPreviousJourney && 
          <MovingTextBanner text="Your latest premium journey is under creation and should be available soon. You are currently viewing the older version of your journey." />}</div>
      <div className={` flex flex-row w-full h-full ${!isIframe && 'pt-4'} md:pb-5 ${!isPreviousJourney &&' md:pt-20'} ${isPreviousJourney && `md:h-[calc(100vh-7rem)] fixed top-10 ${isIframe ? 'mt-4' : 'mt-20'} bottom-0`} justify-evenly`}>
        {!isIframe && <LeftPremiumSidebar visible={!isResponsive} />}
        <PremiumJourneyBody premiumJourney={premiumJourney.premium_journey} isIframe={isIframe} isPreviousJourney={isPreviousJourney}  setJourneyStatus={journeyStatus} // Pass the state setter function
 />
        {!isMobile && !isIframe && <RightPremiumSidebar premiumJourney={premiumJourney.premium_journey} />}
      </div>
    </div>
  );
};

export default PremiumJourney;
