import React, { useEffect, useState } from "react";
import CreateNavbar from "../../../components/Navbar/Navbar";
import { useMediaQuery } from "react-responsive";
import CreateJourneyMB from "../../../components/user/Questionnaire/DynamicJourneyBuilder/Mobile/DynamicQuestionnaireMobile";
import CreateJourneyDesktop from "../../../components/user/Questionnaire/DynamicJourneyBuilder/Desktop/DynamicQuestionnaireDesktop";
import { isUserTokenValid } from "../../../components/common/ValidateToken";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/common/LoadingSpinner";

const DynamicQuestionnaireMainPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const [loading, setLoading] = useState<boolean>(true);
  
  useEffect(() => {
    const initialize = async () => {
        const isValid = await isUserTokenValid();
        if (!isValid) {
          navigate('/login',{ state:{page:"journeybuilder"} });
          return;
        }
        setLoading(false);
    };
    initialize();
  });

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="flex flex-col">
      <div className="fixed top-0 left-0 w-full z-20">
        <CreateNavbar page="home" />
      </div>
      <div className="mt-16 h-full">
        {isMobile ? <CreateJourneyMB /> :<CreateJourneyDesktop/>}
      </div>
    </div>
  );
};

export default DynamicQuestionnaireMainPage;
