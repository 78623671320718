import React, { useState, useEffect, useCallback, useRef } from "react";
import OutsideClickHandler from "../common/outsideClick/OutsideClickHandler";
import { isMobile } from 'react-device-detect';
import PremiumSidebar from "../sidebar/LeftPremiumSidebar";
import userOne from '../../assets/img/premiumJourney/user1.png'
import northStar from '../../assets/img/premiumJourney/NorthStar.svg'
import triangle from '../../assets/img/premiumJourney/triangle.svg'
import triangleInverse from '../../assets/img/premiumJourney/triangleInverse.svg'
import YearWithQuoters from "./years/yearWithQuoters";
import YearWithoutQuoters from "./years/yearWIthoutQuoters";
import SummaryFrame from '../../assets/img/premiumJourney/image 7.svg';
import Filter from '../../assets/img/premiumJourney/filter.svg'
import { addRating } from "../../api/Rating";
import ReactMarkdown from "react-markdown";

interface PremiumJourneyComp {
    premiumJourney: any;
    isIframe: boolean;
    isPreviousJourney?:boolean;
    setJourneyStatus?: any;
}

const PremiumJourneyBody: React.FC<PremiumJourneyComp> = ({ premiumJourney, isIframe,isPreviousJourney, setJourneyStatus }) => {
    const [selectOptionOpen, setSidebarPopupOpen] = useState(false);
    const [isResponsive, setIsResponsive] = useState<boolean>(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [isHovered, setIsHovered] = useState(!isIframe);
    const [rating, setRating] = useState<number>(0);
    const name:any=localStorage.getItem('Name')
    const [showNameTooltip, setShowNameTooltip] = useState<boolean>(false);
    const handleNamePress = () => {
        if (name.length > 40) {
            setShowNameTooltip(true);
            setTimeout(() => setShowNameTooltip(false), 3000);
            }
        };
    const handleResize = useCallback(() => {
        setIsResponsive(window.innerWidth < 768);
    }, []);

    const handleOutsideClick = () => {
        if (isResponsive) {
            setSidebarPopupOpen(false);
        }
    };

    const isValid = (value: any) => {
        if (value === null || value === undefined || value === "") return false;
        return true;
    };

    const onStarClick = () => {
        const targetElement = document.getElementById("northstar");
        if (targetElement) {
            setTimeout(() => targetElement.scrollIntoView({ behavior: "smooth" }), 200);
            ;
        } else {
            console.log("Element not found"); // Log if the element is not found
        }
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    const handleStarClick = async (value: number) => {
        setRating(value);
        const username = localStorage.getItem('Email') || 'User';
        const ratingData: any = {
            username: username,
            selected_version: setJourneyStatus.version,
            rating: value,
        };

        try {
            await addRating(ratingData);

        } catch (error) {
            console.error('Error submitting rating:', error);
        }
    };

    return (
        <div className="flex w-full md:w-3/5 ">
            <div className={`${!isIframe && !isPreviousJourney && 'pt-12'} md:pt-0 flex flex-col`}>
            {/* Header Section */}
                <div className="pl-2 pr-3">
                    {isMobile && !isIframe && (
                        <div className="flex justify-between sm:hidden mb-3 pt-3 bg-white gap-2">
                            <div className="flex items-center justify-center rounded-md bg-[#141736] text-white font-sans order-1"
                                style={{ width: '40px' }}
                                onClick={() => window.scrollTo(0, 0)}
                            >
                                <img src={northStar} alt="northstar" className="w-10 h-10" onClick={onStarClick} />
                            </div>
                            <div className="flex-1 flex shadow-md bg-white mob-w pl-2 rounded-lg  py-2 order-2"
                                style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}
                            >
                                <img src={localStorage.getItem('profileIcon') || userOne} alt="user" className="w-12 h-12 mt-2 rounded-full mr-2" />
                                <div className="flex-1 min-w-0"> 
                                    <p className="font-semibold text-base mt-3 leading-4 line-clamp-2 break-words max-w-[200px]" onClick={handleNamePress}>{name}</p>
                                    {showNameTooltip && name.length > 40 && ( <div className="absolute z-50 bg-white text-black p-1 px-2 rounded text-sm whitespace-normal max-w-[300px] break-words shadow border border-gray-300 -mt-[60px] mr-2">{name}</div>)}
                                    <p className="font-light text-sm leading-4">Premium Career Journey</p>
                                </div>
                            </div>
                            <div className="order-3 flex items-center justify-center"
                                style={{ width: '40px' }}
                            >
                                <div className="bg-white h-20 w-full rounded-lg flex items-center justify-center"
                                    style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}
                                >
                                    <div className="flex items-center justify-center h-10 cursor-pointer"
                                        onClick={() => { setSidebarPopupOpen(true); }}
                                    >
                                        <img src={Filter} alt="filter" className="w-6 h-6" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* leftSidebar as a popup for Mobile */}
                {selectOptionOpen && isResponsive && (
                    <OutsideClickHandler onOutsideClick={handleOutsideClick} targetSelector=".specific-target-class">
                        <div className="md:hidden fixed inset-0 z-50 bg-opacity-50 bg-black">
                            <div className="relative z-50 top-44 left-16 right-8 mob-left" ref={sidebarRef}>
                                <PremiumSidebar visible={true} handleOutsideClick={handleOutsideClick} />
                            </div>
                        </div>
                    </OutsideClickHandler>
                )}
                {/* Main Content and Sidebar for Desktop */}
                <div className="flex flex-grow overflow-y-auto h-full shadow-xl rounded-lg pb-10"
                    style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}
                >
                    <div className="flex flex-col ">
                        <div id="northstar">
                            {(isMobile || isIframe) && <div
                                className="rounded-md px-2 py-4 bg-[#141736] text-white font-sans mx-2 md:mx-0 mt-1"
                            >
                                <div className="flex flex-row justify-between items-center font-bold cursor-grab text-xl"
                                    onClick={() => setIsHovered(!isHovered)}
                                >
                                    <div className="flex items-center">
                                        <img src={northStar} alt="northstar" className="size-10" />
                                        NorthStar
                                    </div>
                                    <img
                                        src={isHovered ? triangleInverse : triangle}
                                        alt="triangle"
                                        className="size-3 mr-2 mt-1"
                                    />
                                </div>

                                <div className="px-2 md:px-10 text-lg font-semibold">{premiumJourney.NorthStar.Title} </div>
                                {isHovered && (
                                    <div className="flex flex-col mt-3 gap-3 px-2">
                                        {isValid(premiumJourney.NorthStar?.VisionStatement) && (
                                            <p>
                                                <span className="font-semibold text-base">Vision:</span><br />
                                                <span className="text-sm">{premiumJourney.NorthStar.VisionStatement.replace(/\n/g, "")}</span>
                                            </p>
                                        )}
                                        {isValid(premiumJourney.NorthStar?.LongTermGoals) && (
                                            <p>
                                                <span className="font-semibold text-base">Long Term Goals:</span><br />
                                                {Array.isArray(premiumJourney.NorthStar.LongTermGoals) ? (
                                                    <ul className="list-disc text-sm px-4 py-2">
                                                        {premiumJourney.NorthStar.LongTermGoals.map((goal: string, index: number) => (
                                                            <li key={index} className="">{goal}</li>
                                                        ))}
                                                    </ul>
                                                ) : (
                                                    <p className="text-sm">{premiumJourney.NorthStar.LongTermGoals.replace(/\n/g, "")}</p>
                                                )}
                                            </p>
                                        )}
                                        {isValid(premiumJourney.NorthStar?.MotivationalStatement) && (
                                            <p className="pr-1 font-medium italic text-sm">
                                                {`"${premiumJourney.NorthStar.MotivationalStatement.replace(/\n/g, "")}"`}
                                            </p>
                                        )}
                                    </div>
                                )}
                            </div>}
                        </div>
                        <div className="mx-2" id={'Profile Analysis'}>
                            {/* SWOT Analysis Section */}
                            <div data-section="SWOT" >
                                <h2 className="bg-[#CAF0F8] rounded-lg px-4 py-4 text-xl text-[#141736] font-semibold  mb-4  mt-5 md:mt-1">Profile Analysis</h2>
                                <div className="flex flex-col px-4 gap-2">
                                    {premiumJourney.CurrentAssessment.SWOTAnalysis.Strengths.length > 0 &&
                                        Array.isArray(premiumJourney?.CurrentAssessment?.SWOTAnalysis?.Strengths) &&
                                        <div>
                                            <h3 className="text-[#0778B6] mb-1 text-base font-semibold font-sans  ">Strengths:</h3>
                                            <ul className="list-disc pl-5 space-y-1">
                                                {premiumJourney.CurrentAssessment.SWOTAnalysis.Strengths.map((item: string, index: number) => (
                                                    <li key={index} className="text-base font-normal font-sans leading-5 text-gray-700">{item}</li>
                                                ))}
                                            </ul>
                                        </div>}
                                    {premiumJourney.CurrentAssessment.SWOTAnalysis.Weaknesses.length > 0 &&
                                        Array.isArray(premiumJourney?.CurrentAssessment?.SWOTAnalysis?.Weaknesses) &&
                                        <div>
                                            <h3 className="text-[#0778B6] mb-1 text-base font-semibold font-sans mt-2">Weaknesses:</h3>
                                            <ul className="list-disc pl-5 space-y-1">
                                                {premiumJourney.CurrentAssessment.SWOTAnalysis.Weaknesses.map((item: string, index: number) => (
                                                    <li key={index} className="text-base font-normal font-sans leading-5 text-gray-700">{item}</li>
                                                ))}
                                            </ul>
                                        </div>}
                                    {premiumJourney.CurrentAssessment.SWOTAnalysis.Opportunities.length > 0 &&
                                        Array.isArray(premiumJourney?.CurrentAssessment?.SWOTAnalysis?.Opportunities) &&
                                        <div>
                                            <h3 className="text-[#0778B6] mb-1 font-sans text-base font-semibold  mt-2">Opportunities:</h3>
                                            <ul className="list-disc pl-5 space-y-1">
                                                {premiumJourney.CurrentAssessment.SWOTAnalysis.Opportunities.map((item: string, index: number) => (
                                                    <li key={index} className="text-base font-normal font-sans leading-5 text-gray-700">{item}</li>
                                                ))}
                                            </ul>
                                        </div>}
                                    {premiumJourney.CurrentAssessment.SWOTAnalysis.Threats.length > 0 &&
                                        Array.isArray(premiumJourney?.CurrentAssessment?.SWOTAnalysis?.Threats) &&
                                        <div>
                                            <h3 className="text-[#0778B6] mb-1 text-base font-semibold font-sans mt-2">Threats:</h3>
                                            <ul className="list-disc pl-5 space-y-1">
                                                {premiumJourney.CurrentAssessment.SWOTAnalysis.Threats.map((item: string, index: number) => (
                                                    <li key={index} className="text-base font-normal font-sans leading-5 text-gray-700">{item}</li>
                                                ))}
                                            </ul>
                                        </div>}
                                </div>
                            </div>
                        </div>
                        {premiumJourney.Milestones.length > 0 &&
                            Array.isArray(premiumJourney.Milestones) &&
                            <div className="px-2">
                                {premiumJourney.Milestones
                                    .filter((year: any) => year) // Filter out null, undefined, or empty year data
                                    .map((year: any, i: number) => {
                                        return i === 0 ? (
                                            <YearWithQuoters key={i} year={year} premiumJourney={premiumJourney} i={i} isValid={isValid} />
                                        ) : (
                                            <YearWithoutQuoters key={i} year={year} premiumJourney={premiumJourney} i={i} isValid={isValid} />
                                        );
                                    })}
                            </div>}
                        {/* Alternate Career Journey Section */}
                        {premiumJourney.Overall["Potential Alternate Career Journey Options"].length > 0 &&
                            Array.isArray(premiumJourney.Overall["Potential Alternate Career Journey Options"]) &&
                            <div id={'Alternate Journeys'}>
                                <div className="rounded-lg px-2 mt-1">
                                    <div data-section="Alternate Journey">
                                        <h2 className="bg-[#CAF0F8] rounded-lg px-4 py-4 text-xl text-[#141736] font-semibold mb-4 ">
                                            Alternate Career Journeys
                                        </h2>
                                        {premiumJourney.Overall["Potential Alternate Career Journey Options"]
                                            .some((option: { Title: string;What:string; Why: string }) => option.Title || option?.What || option.Why) && (
                                                <ul className="list-disc pl-7 md:pl-9 space-y-1">
                                                    {Array.isArray(premiumJourney.Overall["Potential Alternate Career Journey Options"]) &&
                                                        premiumJourney.Overall["Potential Alternate Career Journey Options"].map(
                                                            (option: { Title: string; What?:any; Why: string }, index: number) => {
                                                                if (!option.Title && !option.Why) return null;
                                                                return (
                                                                    
                                                                        <li key={index} className="text-base font-sans leading-5 py-1 text-gray-700">
                                                                        {option.Title &&

                                                                        <div className="font-semibold">
                                                                            <ReactMarkdown>{option.Title + ':'}</ReactMarkdown>
                                                                         </div>}
                                                                        {
                                                                                option.What &&
                                                                                <ReactMarkdown>{option.What}</ReactMarkdown>
                                                                                
                                                                        }
                                                                        {option.What && <br />}
                                                                        {option.Why}
                                                                        </li>
                                                                );
                                                            }
                                                        )}
                                                </ul>
                                            )}
                                    </div>
                                </div>
                            </div>}
                        {premiumJourney.Overall["Industry Trends"].length > 0 &&
                            Array.isArray(premiumJourney.Overall["Industry Trends"]) &&
                            <div data-section="Industry Trends" className="container mx-auto px-4 rounded-lg bg-white mt-4" id={'Industry Trends'}>
                                <h2 className="text-base mb-1 font-bold pl-0 lg:pl-2">Industry Trends</h2>
                                {premiumJourney.Overall["Industry Trends"]
                                    .filter((trend: string) => trend && trend.trim() !== "") // Filter out null, undefined, or empty trends
                                    .length > 0 && (
                                        <ul className="list-disc pl-5 md:pl-7 space-y-1">
                                            {Array.isArray(premiumJourney.Overall["Industry Trends"]) &&
                                                premiumJourney.Overall["Industry Trends"].map((trend: string, index: number) => (
                                                    <li key={index} className="text-base font-sans leading-5 text-gray-700">
                                                        <ReactMarkdown>{trend}</ReactMarkdown>
                                                    </li>
                                                ))}
                                        </ul>
                                    )}
                            </div>
                        }
                        {/* Personal Growth Section */}
                        {premiumJourney.Overall["Personal Development Opportunities"].length > 0 &&
                            Array.isArray(premiumJourney.Overall["Personal Development Opportunities"]) &&
                            <div>
                                <div
                                    data-section="Personal Growth"
                                    className="personal-growth-section container mx-auto px-4 rounded-lg bg-white mt-4"
                                    id={'Personal Growth'}
                                >
                                    <h2 className="text-base mb-1 font-bold pl-0 lg:pl-2 ">Personal Growth</h2>
                                    {premiumJourney.Overall["Personal Development Opportunities"]
                                        .filter((growth: string) => growth && growth.trim() !== "") // Filter out null, undefined, or empty growth opportunities
                                        .length > 0 && (
                                            <ul className="list-disc pl-5 md:pl-7 space-y-1">
                                                {Array.isArray(premiumJourney.Overall["Personal Development Opportunities"]) &&
                                                    premiumJourney.Overall["Personal Development Opportunities"].map(
                                                        (growth: string, index: number) => (
                                                            <li key={index} className="text-base font-sans leading-5 text-gray-700">
                                                                <ReactMarkdown>{growth}</ReactMarkdown>
                                                            </li>
                                                        )
                                                    )}
                                            </ul>
                                        )}
                                </div>
                            </div>
                        }
                        {premiumJourney.Overall.Summary && premiumJourney.Overall.Summary.trim() !== "" && (
                            <div
                                className="bg-[#CAF0F8] flex flex-col-2 my-5 rounded-lg mx-2 justify-between"
                                id={'Summary'}
                            >
                                <div className="tab-custom w-10/12 py-5 pl-3 md:pl-5">
                                    <p className="text-base sm:text-lg font-semibold text-black mb-1 sm:mb-2">Summary</p>
                                    <p className="text-base sm:text-base text-black">
                                        {premiumJourney.Overall.Summary}
                                    </p>
                                </div>
                                <div className="w-2/12 flex justify-end items-end">
                                    <img src={SummaryFrame} className="" alt="SummaryFrame" />
                                </div>
                            </div>
                        )}
                        <div className="flex flex-col justify-center items-center my-2">
                            <p className="text-base font-semibold text-black mt-1 ">Please Rate Your Experience</p>
                            <div className="flex space-x-1">
                                {[1, 2, 3, 4, 5].map((star) => (
                                    <svg
                                        key={star}
                                        className={`w-6 h-6 cursor-pointer ${star <= rating ? 'text-yellow-500' : 'text-gray-300'}`}
                                        fill="currentColor"
                                        viewBox="0 0 20 20"
                                        xmlns="http://www.w3.org/2000/svg"
                                        onClick={() => { !isIframe && handleStarClick(star) }}
                                    >
                                        <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.562 4.82a1 1 0 00.95.69h5.063c.969 0 1.372 1.24.588 1.81l-4.097 2.984a1 1 0 00-.364 1.118l1.562 4.82c.3.921-.755 1.688-1.539 1.118l-4.097-2.984a1 1 0 00-1.176 0l-4.097 2.984c-.784.57-1.838-.197-1.539-1.118l1.562-4.82a1 1 0 00-.364-1.118L2.586 9.447c-.784-.57-.381-1.81.588-1.81h5.063a1 1 0 00.95-.69l1.562-4.82z" />
                                    </svg>
                                ))}
                            </div>
                        </div>
                        <footer className='flex justify-center p-2 mt-5 text-xs text-gray-500'>
                            All rights reserved by @skilljourney {new Date().getFullYear()}
                        </footer>
                    </div>
                </div>
                <div className="hidden md:block w-1/4 pl-7">
                    {/* <Sidebar visible={isResponsive} onSelectQuarter={handleSelectQuarter} activeQuarter={1} /> */}
                    <PremiumSidebar visible={isResponsive} />
                </div>
            </div>
        </div>
    );
}
export default PremiumJourneyBody;