import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { isUserTokenValid } from '../components/common/ValidateToken';

export const useTokenValidator = (redirectPath: string, state?: object) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [isTokenValid, setIsTokenValid] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      try {
        setLoading(true);
        const valid = await isUserTokenValid();

        if (!valid) {
          navigate(redirectPath, { state }); 
          return;
        }
        setIsTokenValid(true);
      } catch (error: any) {
        console.error(error.message);
      } finally {
        setLoading(false);
      }
    };

    validateToken();
  }, [navigate, redirectPath, state]);

  return { loading, isTokenValid };
};
