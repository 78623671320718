import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import key from "../../assets/img/key.svg";
import emailicon from "../../assets/img/Emailicon.svg";
import { TextField, InputAdornment } from "@mui/material"; 
import { send_Otp, validate_otp } from "../../api/UserMetadata";
import Loginnav from "../Navbar/loginNav";
import CollegeMobile from "../../assets/img/College/CollegeMobile.svg";
import { checkIfTPO } from "../../api/College";
import defaultAvtar from '../../assets/img/Avatar.png';


const CollegeLoginMobile: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [otp, setOtp] = useState<boolean>(false);
  const [emailError, setEmailError] = useState<string>("");
  const [otpValue, setOtpValue] = useState<string>("");
  const [otpError, setOtpError] = useState<string>("");
  const [isOtpSent, setIsOtpSent] = useState<boolean>(false); 
  const [timeLeft, setTimeLeft] = useState<number>(0); 
  const [otpAttempts, setOtpAttempts] = useState<number>(0); 
  const [Error, setError] = useState<string>(""); 
  const navigate = useNavigate();
  const [tpoError, setTpoError] = useState<string>("");
  const [collegeName, setCollegeName] = useState<string | null>(null);


  const isValidEmail = (email: string) => {
    const regex = /^[a-zA-Z0-9._-]+@[a-zAZ0-9.-]+\.[a-zA-Z]{2,6}$/;
    return regex.test(email);
  };

  const isValidOtp = async (otp: string) => {
    const isValid=await validate_otp(email,otp)
    
    return isValid;
  };

  const sendOtp = async () => {
    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return;
    }
    setEmailError(""); 
  
    try {
      const tpoStatus = await checkIfTPO(email);
      if (!tpoStatus.is_collegeAdmin?.isCollegeAdmin) {
        setTpoError('You are not authorized to log in as a TPO.');
        return;
      }
  
      const { college_name, name, photo_url, college_image} = tpoStatus.is_collegeAdmin;
      setCollegeName(college_name);
      localStorage.setItem("collegeName", college_name);
      localStorage.setItem("Name", name);
      localStorage.setItem("profileIcon", photo_url);
      localStorage.setItem("college_image", college_image);
  
      const response: any = await send_Otp(email);
      if (response.success) {
        alert(`OTP successfully sent to ${email}!`);
        setOtp(true);
        setIsOtpSent(true);
        setTimeLeft(30);
      } else {
        alert('Something went wrong. Please try again!');
      }
    } catch (error) {
      console.error('Failed to check TPO status:', error);
      setTpoError('Failed to verify TPO status. Please try again.');
    }
  };

  useEffect(() => {
    let timer: ReturnType<typeof setInterval> | undefined; 

    if (isOtpSent && timeLeft > 0) {
      timer = setInterval(() => {
        setTimeLeft((prevTime) => prevTime - 1);
      }, 1000); 
    } else if (timeLeft === 0) {
      clearInterval(timer); 
    }

    return () => {
      if (timer) {
        clearInterval(timer); 
        setIsOtpSent(true);
      }
    };
  }, [isOtpSent, timeLeft]);

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
      setTpoError("");
      setEmailError("");
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtpValue(e.target.value);
  };

  const validateOtp = async () => {
    try {
      const response = await isValidOtp(otpValue); 
      const { isValidOtp: isOtpValid, accessToken, name, profileIcon } = response;
  
      if (!isOtpValid) {
        setOtpError("Invalid OTP. Please enter the correct OTP.");
      } else {
        setOtpError("");
        
        const avatar = profileIcon || defaultAvtar;
        localStorage.setItem('accessToken', `${accessToken}`);  
        localStorage.setItem("AdminEmail", email);
        localStorage.setItem('AccessToken', `Bearer ${accessToken}`);  
        localStorage.setItem('LMSToken', `${accessToken}`); 
        localStorage.setItem('isCollegeAdmin', 'true'); 
        navigate("/college-dashboard", { state: { collegeName } }); 
      }
    } catch (error) {
      console.error("Error during OTP validation:", error);
      setOtpError("An error occurred while validating the OTP. Please try again.");
    }
  };

  const resendOtp = async () => {
    if (otpAttempts >= 3) {
      alert("You have reached the maximum resend attempts.");
      return;
    }

    if (!isValidEmail(email)) {
      setEmailError("Please enter a valid email address.");
      return; 
    }
    setEmailError(""); 
    sendOtp()
    setOtpAttempts((prevAttempts) => prevAttempts + 1); 
  };

  return (
    <div className="overflow-y-auto flex flex-col md:flex-row md:h-screen h-screen">
      <div className="flex flex-col md:w-1/2">
        <Loginnav bgColor="bg-[#FEF5E6] " />
        <div className="flex flex-col items-center mb-5">
            <span className="relative max-h-[300px] rounded-b-[700px] bg-[#FEF5E6] min-w-auto">
                <span className="absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl font-semibold md:text-4xl font-sans bg-custom-gradient text-transparent bg-clip-text">
                    LOGIN
                </span>
                <img
                src={CollegeMobile}
                alt="Login"
                className=" w-[600px] h-[340px] top-[-100px] "
                />
            </span>
        </div>

        <div className="flex  md:bottom-0 md:w-1/2 flex flex-col justify-start items-center ">
          <div className="w-full max-w-sm pl-2 pr-2 pt-[100px] ">
            <TextField
              id="email"
              label="Email"  
              value={email}
              onChange={handleEmailChange}
              placeholder="Please enter your email"  
              fullWidth
              disabled={isOtpSent}
              className="my-4"  
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                   <img
                        src={emailicon}
                        alt="Login"
                        className="object-cover"
                      />
                  </InputAdornment>
                ),
              }}
              error={!!emailError} 
              helperText={emailError} 
            />
          </div>
          {tpoError && (
              <div className="text-red-500 text-sm mt-2">{tpoError}</div>
          )}

          {otp && (
            <div className="flex flex-col w-full max-w-sm pl-2 pr-2  mt-5">
              <TextField
                id="OTP"
                label="OTP"  
                value={otpValue}
                onChange={handleOtpChange}
                placeholder="Please enter OTP here"  
                fullWidth
                className="my-4"  
                InputLabelProps={{
                  shrink: true, 
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <img
                        src={key}
                        alt="Login"
                        className="object-cover"
                      />
                    </InputAdornment>
                  ),
                }}
                error={!!otpError} 
                helperText={otpError} 
              />
              <button
                className={`flex justify-end text-right font-sans text-sm ${otpAttempts >= 3? "text-gray-500":"text-black-500"} cursor-pointer mt-1 mr-2`}
                onClick={resendOtp}
                disabled={timeLeft > 0 || otpAttempts >= 3} 
              >
                {timeLeft > 0 ? `Resend in ${timeLeft}s` : "Resend OTP"}
              </button>
            </div>
          )}

          {otp ? (
            <div className="flex items-center justify-center mt-4 md:py-3">
              <button
                onClick={validateOtp}
                className="relative w-[180px] h-[50px] font-sans text-lg font-semibold tracking-wider text-white bg-[#0778B6] rounded-full"
              >
                LOGIN
              </button>
            </div>
          ) : (
            <div className="flex items-center justify-center py-5 mt-10 pt-10 md:py-3">
              <button
                onClick={sendOtp}
                className="relative w-[180px] h-[50px] font-sans text-lg font-semibold tracking-wider text-white bg-[#0778B6] rounded-full"
              >
                SEND OTP
              </button>
            </div>
          )}

          <p className="md:text-base justify-center pt-2 text-sm font-normal font-sans text-center">
            Want to switch to User Login?
            <Link to="/login" className="pl-1 text-custom-blue">
              Click here to continue.
            </Link>
          </p>
          <div className="flex flex-col mt-auto  pt-auto">
            <p className="md:text-base justify-center text-sm font-semibold font-sans text-center pt-10 mt-10">
                Having issue with your login?
                <Link to="/contactUs" className="pl-1 text-custom-blue underline">
                Contact Us.
                </Link>
            </p>
          </div>
          
        </div>
      </div>
    </div>
  );
};

export default CollegeLoginMobile;
