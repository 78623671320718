"use client";
import React, { useState, useEffect } from "react";
import next from "../../../../../assets/img/User/Questionnaire/next1.svg";
import previous from "../../../../../assets/img/User/Questionnaire/Previous.svg";
import climb from "../../../../../assets/img/User/Questionnaire/climb.png";
import Swal from "sweetalert2";
import CreateNavbar from "../../../../Navbar/Navbar";
import SectionRenderer from "../SectionRenderer";
import {
  fetchUserAnswer,
  updateUserAnswer,
} from "../../../../../api/DynamicQuestionnaire";
import { useNavigate } from "react-router-dom";
import { fetchUserSearchLogs } from "../../../../../api/Questionnaire";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { motion } from "framer-motion";
import TypingEffect from "../DynamicInput1/TypingEffect";
import { insertStudentRegister } from "../../../../../api/College";

export default function CreateJourneyDesktop() {
  const navigate = useNavigate();
  const [selectedSectionId, setSelectedSectionId] = useState<number>(1);
  const [progressdata, setProgress] = useState<number>(0);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [isJourneyComplete, setIsJourneyComplete] = useState<boolean>(false);
  const [initialFormData, setInitialFormData] = useState<Record<string, any>>(
    {}
  );
  const [formData, setFormData] = useState<Record<string, any>>(() => {
    const savedData = localStorage.getItem("createJourneyData");
    return savedData ? JSON.parse(savedData) : {};
  });
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(
    () => {
      const savedIndex = localStorage.getItem("activeQuestionIndex");
      return savedIndex ? parseInt(savedIndex) : 0;
    }
  );
  const [errorMessage, setErrorMessage] = useState("");
  const [jsonData, setJsonData] = useState<{ sections: any[] }>({
    sections: [],
  });
  const [loading, setLoading] = useState<boolean>(true);
  const [bypassResumeCheck, setBypassResumeCheck] = useState<boolean>(false);
  const [resumeValidationError, setResumeValidationError] =
    useState<boolean>(false);
  const [ResumeErrorMessage, setResumeErrorMessage] = useState("");
  const [initialLoadComplete, setInitialLoadComplete] =
    useState<boolean>(false);
  useEffect(() => {
    localStorage.setItem("activeSection", selectedSectionId.toString());
    localStorage.setItem(
      "activeQuestionIndex",
      currentQuestionIndex.toString()
    );
  }, [selectedSectionId, currentQuestionIndex]);

  const setErrorMessageWithTimeout = (message: any) => {
    setErrorMessage(message);
    setTimeout(() => setErrorMessage(""), 3000);
  };

  useEffect(() => {
    const handleKeyPress = (event: KeyboardEvent) => {
      const isInputFocused =
        document.activeElement instanceof HTMLInputElement ||
        document.activeElement instanceof HTMLTextAreaElement;

      if (isInputFocused) {
        return;
      }

      if (
        (event.key === "Enter" || event.key === "ArrowRight") &&
        selectedSectionId !== 0 &&
        selectedSection
      ) {
        handleNext();
      } else if (event.key === "Enter" && progressdata === 100) {
        handleSubmit();
      }

      if (
        event.key === "ArrowLeft" &&
        selectedSectionId !== 0 &&
        selectedSection
      ) {
        handlePrevious();
      }
    };

    window.addEventListener("keydown", handleKeyPress);

    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  });

  useEffect(() => {
    const fetchData = async () => {
      const token = localStorage.getItem("AccessToken");
      const email = localStorage.getItem("Email");

      if (!token || !email || jsonData.sections.length === 0) {
        console.error("No token or email found.");
        return;
      }
      try {
        setLoading(true);
        const response = await fetchUserAnswer(token, email);
        if (response && response.data) {
          setInitialFormData(JSON.parse(JSON.stringify(response.data)));
          setFormData(response.data);
          localStorage.setItem(
            "createJourneyData",
            JSON.stringify(response.data)
          );
          if ("northStar" in response.data) {
            setSelectedSectionId(0);
          }

          let totalQuestions = 0;
          let answeredQuestions = 0;

          jsonData.sections.forEach((section) => {
            section.questions.forEach((question: any) => {
              if (question.isRequired) {
                totalQuestions++;
                if (response.data[question.name]?.length > 0) {
                  answeredQuestions++;
                }
              }
            });
          });

          const initialProgress =
            totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;
          setProgress(initialProgress);
          setIsJourneyComplete(Math.round(initialProgress) === 100);

          if (Math.round(initialProgress) === 100) {
            setSelectedSectionId(0);
          }
        }
      } catch (error) {
        console.error("Failed to fetch user answers:", error);
      } finally {
        setLoading(false);
        setInitialLoadComplete(true);
      }
    };

    if (jsonData.sections.length > 0) {
      fetchData();
    }
  }, [jsonData.sections]);

  useEffect(() => {
    const fetchQuestionSet = async () => {
      try {
        const data = await fetchUserSearchLogs(2);
        setJsonData({ sections: data.questions });
      } catch (error) {
        console.error("Failed to fetch question set:", error);
      }
    };
    fetchQuestionSet();
  }, []);

  useEffect(() => {
    setIsJourneyComplete(progressdata === 100);
  }, [progressdata]);

  useEffect(() => {
    Object.entries(formData).forEach(([name, value]) => {
      const question = jsonData.sections
        .flatMap(s => s.questions)
        .find(q => q.name === name && q.type === 'file');
        
      if (question && !value) {
        setFormData(prev => {
          const newData = { ...prev };
          delete newData[name];
          return newData;
        });
      }
    });
  }, [formData, jsonData.sections]);

  const isResumeSection = (section: any) => {
    return section?.questions.some((question: any) => question.type === "file");
  };

  const hasDataChanged = () => {
    const currentData = JSON.parse(
      localStorage.getItem("createJourneyData") || "{}"
    );

    return Object.keys({ ...initialFormData, ...currentData }).some(
      (key) =>
        JSON.stringify(initialFormData[key]) !==
        JSON.stringify(currentData[key])
    );
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("AccessToken");
    const createJourneyData = localStorage.getItem("createJourneyData");

    if (!token || !createJourneyData) {
      console.error("No data or token found.");
      return;
    }

    try {
      const currentSection = jsonData.sections.find(
        (section) => section.sectionId === selectedSectionId
      );

      const shouldBypassCheck =
        currentSection && isResumeSection(currentSection)
          ? bypassResumeCheck || selectedFile === null
          : true;
      const dataChanged = hasDataChanged();
      localStorage.setItem(
        "QuestionnaireUpdated",
        dataChanged ? "true" : "false"
      );

      const payload = {
        answers_data: JSON.parse(createJourneyData),
        sectionComplete: true,
        bypassResumeCheck: shouldBypassCheck,
      };
  
      const fileToSubmit = selectedFile || undefined;
      
      const response = await updateUserAnswer(token, payload, fileToSubmit);
      if (response) {
        setFormData({});
        setIsJourneyComplete(true);
        localStorage.setItem("journeyVersion", "v2");
        localStorage.removeItem("activeSection");
        localStorage.removeItem("activeQuestionIndex");
        navigate("/comparison");
      }
    } catch (error) {
      console.error("Failed to update user answer:", error);
    }
  };

  const handleSectionChange = (sectionId: number) => {
    setSelectedSectionId(sectionId);
    setCurrentQuestionIndex(0); // Reset question index

    const newSection = jsonData.sections.find(
      (section) => section.sectionId === sectionId
    );
    if (newSection && !isResumeSection(newSection)) {
      setBypassResumeCheck(true);
    }

    localStorage.setItem("activeSection", sectionId.toString());
    localStorage.setItem("activeQuestionIndex", "0");
  };

  const handleInputChange = (
    name: string,
    value: any,
    bypassCheck?: boolean
  ) => {
    const selectedSection = jsonData.sections.find(
      (section) => section.sectionId === selectedSectionId
    );
    if (!selectedSection) {
      Swal.fire({
        title: "Error",
        text: "Selected section not found.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const currentQuestion = selectedSection.questions[currentQuestionIndex];

    if (
      (currentQuestion.type === "checkbox" ||
        currentQuestion.type === "dropdown") &&
      currentQuestion.validations?.maxSelect &&
      Array.isArray(value) &&
      value.length > currentQuestion.validations.maxSelect
    ) {
      setErrorMessageWithTimeout(
        `Select up to ${currentQuestion.validations.maxSelect} option only`
      );
      return false;
    }
    if (value === null) {
      setSelectedFile(null);
      setFormData(prev => {
        const newData = { ...prev };
        delete newData[name]; 
        localStorage.setItem("createJourneyData", JSON.stringify(newData));
        return newData;
      });
      return;
    }
  
    if (value instanceof File) {
      setSelectedFile(value);
      if (isResumeSection(selectedSection)) {
        setBypassResumeCheck(bypassCheck || false);
      }
      setFormData(prev => {
        const updatedFormData = { ...prev, [name]: value.name };
        localStorage.setItem("createJourneyData", JSON.stringify(updatedFormData));
        return updatedFormData;
      });
      return;
    }

    if (
      currentQuestion?.subquestions &&
      Array.isArray(currentQuestion.subquestions)
    ) {
      const selectedOption = Array.isArray(value) ? value : [value];
      const selectedOptionDetails = currentQuestion.options.filter(
        (option: any) => selectedOption.includes(option.value)
      );

      const allSubquestionIds = currentQuestion.subquestions.map(
        (subq: any) => subq.subquestionId
      );

      const visibleSubquestionIds = selectedOptionDetails.flatMap(
        (option: any) => option.selectsubquestions || []
      );

      const subquestionsToClear = allSubquestionIds.filter(
        (id: any) => !visibleSubquestionIds.includes(id)
      );

      setFormData((prev) => {
        const updatedFormData = { ...prev };

        currentQuestion.subquestions.forEach((subquestion: any) => {
          if (
            subquestionsToClear.includes(subquestion.subquestionId) &&
            visibleSubquestionIds.length > 0
          ) {
            delete updatedFormData[subquestion.name];
            localStorage.removeItem(subquestion.name);
          }
        });
        localStorage.setItem(
          "createJourneyData",
          JSON.stringify(updatedFormData)
        );
        return updatedFormData;
      });
    }

    setFormData((prev) => {
      const updatedFormData = { ...prev, [name]: value };
      localStorage.setItem(
        "createJourneyData",
        JSON.stringify(updatedFormData)
      );
      return updatedFormData;
    });

    if (value instanceof File) {
      setFormData((prev) => {
        const updatedFormData = { ...prev, [name]: value.name };
        localStorage.setItem(
          "createJourneyData",
          JSON.stringify(updatedFormData)
        );
        return updatedFormData;
      });
    }
  };

  const handleNext = async () => {
    window.scrollTo(0, 0);
    const selectedSection = jsonData.sections.find(
      (section) => section.sectionId === selectedSectionId
    );

    if (!selectedSection) {
      Swal.fire({
        title: "Error",
        text: "Selected section not found.",
        icon: "error",
        confirmButtonText: "OK",
      });
      return;
    }

    const currentQuestion = selectedSection.questions[currentQuestionIndex];
    const isResumeQuestion =
      selectedSection.sectionId === 2 &&
      currentQuestion.type === "file" &&
      currentQuestionIndex === selectedSection.questions.length - 1;

    if (isResumeQuestion && selectedFile && !bypassResumeCheck) {
      const token = localStorage.getItem("AccessToken");
      const createJourneyData = localStorage.getItem("createJourneyData");

      if (!token || !createJourneyData) {
        console.error("No data or token found.");
        return;
      }

      try {
        const payload = {
          answers_data: JSON.parse(createJourneyData),
          sectionComplete: false,
          bypassResumeCheck: false,
        };

        const response = await updateUserAnswer(token, payload, selectedFile);

        if (!response.success) {
          setResumeValidationError(true);
          setResumeErrorMessage(
            response.errorResponse || "Resume validation failed"
          );
          return;
        }
        setResumeValidationError(false);
        setResumeErrorMessage("");
      } catch (error: any) {
        console.error("Failed to validate resume:", error);
        const errorResponse = error.errorResponse || "Resume validation failed";
        setResumeValidationError(true);
        setResumeErrorMessage(errorResponse);
        return;
      }
    }

    const validateQuestion = (question: any) => {
      const value = formData[question.name];

      if (question.isRequired) {
        if (typeof value === "string" && value.trim() === "") {
          setErrorMessageWithTimeout(`Please fill all requires fields.`);
          return false;
        }

        if (Array.isArray(value) && value.length === 0) {
          setErrorMessageWithTimeout(`Please select at least one option`);
          return false;
        }

        if (value == null) {
          setErrorMessageWithTimeout(`Please fill all requires fields.`);
          return false;
        }
      }

      if (question.name === "email") {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(value)) {
          setErrorMessageWithTimeout("Please enter a valid email address.");
          return false;
        }
      }

      if (["fullName", "collegeUniversityName"].includes(question.name)) {
        const nameRegex = /^[A-Za-z\s]+$/;
        if (!nameRegex.test(value)) {
          setErrorMessageWithTimeout(
            `${
              question.name === "fullName" ? "Full name" : "College name"
            } should only contain letters and spaces.`
          );
          return false;
        }
      }

      if (question.name === "contactNumber") {
        if (question.validations?.onlyNumbers && !/^\d+$/.test(value)) {
          setErrorMessageWithTimeout(
            "Contact number should contain only digits."
          );
          return false;
        }

        if (
          question.validations?.maxDigits &&
          value.length !== question.validations.maxDigits
        ) {
          setErrorMessageWithTimeout(
            `Contact number must be exactly ${question.validations.maxDigits} digits long.`
          );
          return false;
        }
      }

      if (
        (question.type === "checkbox" || question.type === "dropdown") &&
        question.validations?.maxSelect &&
        Array.isArray(value) &&
        value.length > question.validations.maxSelect
      ) {
        setErrorMessageWithTimeout(
          `Select upto ${question.validations.maxSelect} option only`
        );
        return false;
      }

      if (question.subquestions && value) {
        const selectedOption = Array.isArray(formData[question.name])
          ? formData[question.name]
          : [formData[question.name]];

        // Find matching options based on selected values
        const selectedOptionDetails = question.options.filter((option: any) =>
          selectedOption.includes(option.value)
        );

        // Collect subquestions to check based on selectedOptionDetails
        const visibleSubquestionsIds = selectedOptionDetails.flatMap(
          (option: any) => option.selectsubquestions || []
        );

        // Filter only the subquestions that are visible
        const visibleSubquestions = question.subquestions.filter((subq: any) =>
          visibleSubquestionsIds.includes(subq.subquestionId)
        );

        for (const subQuestion of visibleSubquestions) {
          if (!validateQuestion(subQuestion)) {
            return false;
          }
        }
      }
      setErrorMessage("");
      return true;
    };

    // Validate the current question (and its subquestions)
    if (!validateQuestion(currentQuestion)) {
      return; // Stop navigation if validation fails
    }

    let totalQuestions = 0;
    let answeredQuestions = 0;

    jsonData.sections.forEach((section) => {
      section.questions.forEach(
        (question: { isRequired: any; name: string | number }) => {
          if (question.isRequired) {
            totalQuestions++;
            if (formData[question.name]?.length > 0) {
              answeredQuestions++;
            }
          }
        }
      );
    });

    const progress =
      totalQuestions > 0 ? (answeredQuestions / totalQuestions) * 100 : 0;
    setProgress(progress);

    if (Math.round(progress) === 100) {
      setIsJourneyComplete(true);
    } else {
      setIsJourneyComplete(false);
    }

    // Proceed to the next question or section
    let nextIndex = currentQuestionIndex + 1;

    // Check skip logic based on selected options for the current question
    const selectedOption = formData[currentQuestion.name];
    let skipQuestions: string[] = []; // Initialize an empty array to collect skip questions
    if (selectedOption) {
      // Iterate through options using forEach
      currentQuestion.options.forEach((option: any) => {
        if (option.value === selectedOption) {
          // If the option matches, collect the skipquestions
          skipQuestions = option.skipquestions || [];
        }
      });
    }

    if (skipQuestions.length > 0) {
      skipQuestions.forEach((skipQuestionId) => {
        const skippedQuestionIndex = selectedSection.questions.findIndex(
          (q: { questionId: string }) => q.questionId === skipQuestionId
        );
        if (skippedQuestionIndex > -1) {
          nextIndex = skippedQuestionIndex + 1; // Move to the question after the skipped one
        }
      });
      skipQuestions.forEach((skipQuestionId) => {
        const skippedQuestion = selectedSection.questions.find(
          (q: { questionId: string }) => q.questionId === skipQuestionId
        );
        setFormData((prev) => {
          const updatedFormData = { ...prev };

          delete updatedFormData[skippedQuestion.name];
          localStorage.removeItem(skippedQuestion.name);

          localStorage.setItem(
            "createJourneyData",
            JSON.stringify(updatedFormData)
          );
          return updatedFormData;
        });
      });
    }

    if (nextIndex < selectedSection.questions.length) {
      setCurrentQuestionIndex(nextIndex); // Move to the next question
      localStorage.setItem("activeQuestionIndex", nextIndex.toString());
    } else {
      handleSectionCompletion(selectedSectionId);
      setSelectedSectionId(0);
      setCurrentQuestionIndex(0);
      localStorage.setItem("activeSection", "0");
      localStorage.setItem("activeQuestionIndex", "0");
    }
  };

  const handleSectionCompletion = async (sectionId: number) => {
    const token = localStorage.getItem("AccessToken");
    const createJourneyData = localStorage.getItem("createJourneyData");

    if (!token || !createJourneyData) {
      console.error("No data or token found.");
      return;
    }

    try {
      const currentSection = jsonData.sections.find(
        (section) => section.sectionId === sectionId
      );

      const shouldBypassCheck =
        currentSection && isResumeSection(currentSection)
          ? bypassResumeCheck || selectedFile === null
          : true;

      const payload = {
        answers_data: JSON.parse(createJourneyData),
        sectionComplete: true,
        bypassResumeCheck: shouldBypassCheck,
      };

      const response = await updateUserAnswer(token, payload, selectedFile);
  
      if (currentSection && (currentSection.sectionId === 2 || currentSection.title === "Educational details")
      ) {
        const journeyData = JSON.parse(createJourneyData);
        const studentRegisterData = {
          name: journeyData.fullName || "",
          email: localStorage.getItem("Email") || "",
          contact_number:journeyData.contactNumber || "",
          college_name: journeyData.collegeUniversityName || "",
          department: journeyData.education_specialization || "",
          degree: journeyData.degreeDiploma || "",
          year: journeyData.courseCompletionTime || "",
          cgpa: 0,
          photo_url: localStorage.getItem("profileIcon"),
          highlights: journeyData.extracurricularDetailsSubQuestionResearch || "-",
        };
        await insertStudentRegister(studentRegisterData);
      }
    } catch (error) {
      console.error("Failed to update section:", error);
    }
  };

  const selectedSection = jsonData.sections.find(
    (section) => section.sectionId === selectedSectionId
  );
  const handleResumeValidationErrorClear = () => {
    setResumeValidationError(false);
    setBypassResumeCheck(true);
  };

  if (!initialLoadComplete || loading) {
    return <LoadingSpinner />;
  }

  const handlePrevious = () => {
    window.scrollTo(0, 0);
    if (currentQuestionIndex === 0) {
      setSelectedSectionId(0);
      return;
    }
    const selectedSection = jsonData.sections.find(
      (section) => section.sectionId === selectedSectionId
    );

    let previndex: any = currentQuestionIndex - 1;
    let prevQuestion = selectedSection.questions[previndex];

    let nextIndex;
    let emptyarr: any = [];
    while (true) {
      let data: any = formData[prevQuestion.name];

      const prev_prev_Question = selectedSection.questions[previndex - 1];

      if (
        data === undefined &&
        prev_prev_Question?.isDynamic &&
        previndex > 0
      ) {
        previndex = previndex - 1;
      } else {
        nextIndex = previndex;
        break;
      }
      prevQuestion = selectedSection.questions[previndex];
    }

    const selectedOption = formData[prevQuestion.name];
    let skipQuestions: string[] = []; // Initialize an empty array to collect skip questions

    if (nextIndex < selectedSection.questions.length) {
      setCurrentQuestionIndex(nextIndex); // Move to the next question
    } else {
      setSelectedSectionId(0);
      setCurrentQuestionIndex(0);
    }
  };

  return (
    <div className="scroll-smooth overflow-y-auto">
      {selectedSectionId === 0 ? (
        <div className="createContainer">
          <h1 className="h1class ">Journey Builder</h1>
          <div>
            <div
              className="grid grid-cols-2 md:grid-cols-3 gap-3"
              style={{
                gap: "20px",
                marginBottom: "20px",
                marginTop: "30px",
                justifyItems: "center",
                textAlign: "center",
              }}
            >
              {jsonData.sections.map((section) => {
                let isSectionComplete = true;

                for (const question of section.questions) {
                  if (
                    question.isRequired &&
                    (!formData[question.name] ||
                      formData[question.name].toString().trim() === "")
                  ) {
                    isSectionComplete = false;
                    break;
                  }
                }
                return (
                  <div
                    key={section.sectionId}
                    className="box-border h-32 w-32  border-4"
                    style={{
                      padding: "24px",
                      border: isSectionComplete
                        ? "1.5px solid #0778B6" // Light green for completed sections
                        : selectedSectionId === section.sectionId
                        ? "1.5px solid #828282" // Light blue for selected section
                        : "1.5px solid #828282",
                      borderRadius: "8px",
                      cursor: "pointer",
                      backgroundColor: isSectionComplete
                        ? "#ECFAFD" // Light green for completed sections
                        : selectedSectionId === section.sectionId
                        ? "#f0f8ff" // Light blue for selected section
                        : "#fff", // White for default
                    }}
                    onClick={() => handleSectionChange(section.sectionId)}
                  >
                    {isSectionComplete ? (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{
                            maxWidth: "50%",
                            paddingBottom: "10px",
                          }}
                          src={section.selected_image}
                          alt={`${section.title} icon`}
                        />
                      </div>
                    ) : (
                      <div
                        style={{ display: "flex", justifyContent: "center" }}
                      >
                        <img
                          style={{
                            maxWidth: "50%",
                            paddingBottom: "10px",
                          }}
                          src={section.image}
                          alt={`${section.title} icon`}
                        />
                      </div>
                    )}
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                        height: "100px",
                      }}
                    >
                      <h3
                        style={{
                          fontWeight: "500",
                          color: isSectionComplete
                            ? "#0778B6"
                            : selectedSectionId === section.sectionId
                            ? "#828282"
                            : "#828282",
                          textAlign: "center",
                        }}
                      >
                        {section.title}
                      </h3>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="create-journey-button">
            <button
              type="button"
              className={`font-medium rounded-full text-sm px-5 py-2.5 me-2 mb-2 focus:outline-none 
              ${
                isJourneyComplete
                  ? "text-white enable-btn-color bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  : "disabled-btn-color bg-gray-400 cursor-not-allowed focus:ring-4 focus:ring-gray-300 dark:bg-gray-500 dark:focus:ring-gray-600"
              }`}
              onClick={handleSubmit}
              disabled={!isJourneyComplete}
            >
              Create Journey
            </button>
          </div>
        </div>
      ) : null}
      {selectedSectionId !== 0 && <div></div>}
      {selectedSectionId !== 0 && selectedSection && (
        <div className="relative top-0 createContainer createJourneyContainer">
          <div className="">
            <span className=" " onClick={handlePrevious}>
              <img
                src={previous}
                className=" h-6 sm:h-6 lg:h-6"
                alt="Logo"
              />
            </span>
          </div>
          <h2 className="headerclass pt-5">
            <TypingEffect text={`"${selectedSection?.sectionStartMessage}"`} />
          </h2>
          <div style={{ marginBottom: "20px" }}>
            <p className="text-right">{Math.round(progressdata || 0)}%</p>
            <div className="progress-container">
              <div
                className="progress-bar"
                style={{ width: `${progressdata || 0}%` }}
              ></div>
            </div>
          </div>

          {/* Make sure SectionRenderer is on top if no error */}

          <div
            className={`labelSection inset-0 ${errorMessage ? "z-0" : "z-10"}`}
          >
            <motion.div
              key={selectedSection.questions[currentQuestionIndex].name}
              initial={{ opacity: 0, x: 0 }}
              animate={{ opacity: 5, x: 0 }}
              exit={{ opacity: 0, x: 100 }}
              transition={{ duration: 0.8 }}
            >
              <SectionRenderer
                section={{
                  ...selectedSection,
                  questions: [selectedSection.questions[currentQuestionIndex]], // Render current question only
                }}
                formData={formData}
                onInputChange={handleInputChange}
                resumeValidationError={resumeValidationError}
                onResumeValidationErrorClear={handleResumeValidationErrorClear}
                resumeErrorMessage={ResumeErrorMessage}
              />
              <div className="col-span-3">
                <button className="float-end mt-20" onClick={handleNext}>
                  <img
                    src={next}
                    className=" h-6 sm:h-6 lg:h-6"
                    alt="Logo"
                  />
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      )}

      <div className="footer">
        {selectedSectionId !== 0 && selectedSection && (
          <div className="grid grid-cols-12 gap-3">
            <div className="col-span-3">
              <span className="mt-8">
                <img
                  src={climb}
                  style={{ height: "150px" }}
                  className="mr-3 h-8 sm:h-12 lg:h-9"
                  alt="Logo"
                />
              </span>
            </div>
            <div
              className={`col-span-6 inset-0 ${errorMessage ? "z-10" : "z-0"} `}
            >
              {errorMessage ? (
                <div className="bg-red-100 border-l-20 border-red-500 text-red-700 p-2 rounded-lg">
                  <div className="flex items-center gap-3">
                    <div className="flex justify-center items-center">
                      <svg
                        style={{
                          position: "relative",
                        }}
                        className="w-30 h-8 m-4"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
                      </svg>
                    </div>
                    <div style={{ color: "black" }} className="flex-1">
                      <div className="text-lg font-semibold">Error</div>
                      <div>{errorMessage}</div>
                    </div>
                    <div
                      className="flex justify-center items-center cursor-pointer"
                      onClick={() => setErrorMessage("")}
                    >
                      <svg
                        className="fill-current h-6 w-6"
                        role="button"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                      >
                        <title>Close</title>
                        <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                      </svg>
                    </div>
                  </div>
                </div>
              ) : (
                <span></span>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
