import React from 'react';

interface IframeCompProps {
    className?: string;
    endpoint?: string;
    style?: React.CSSProperties;
}

const IframeComp: React.FC<IframeCompProps> = ({ className, endpoint,style  }) => {

    // Construct the iframe URL and append the clientId as a query parameter if it exists
    const baseUrl = window.location.href.replace(/(https?:\/\/[^\/]+).*/, '$1');
    const iframeSrc = `${baseUrl}${endpoint}`;

    return (
            <iframe
                src={iframeSrc}
                title="Embedded Page"
                className={className}
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                style={style}
            />
    );
};

export default IframeComp;
