import React, { useEffect, useState } from 'react';
import ArrowLeft from '../../assets/img/journey/arrow-left.svg'
import MobileArrow from '../../assets/img/journey/arrow-mobile.svg'
import CreateNavbar from '../Navbar/Navbar';
import { Button } from '@mui/material';
import GridImageSteaper from '../steapers/GridImageSteaper';
import roadImage1 from '../../assets/img/journey/stop-01.svg'
import roadImage2 from '../../assets/img/journey/stop-02.svg'
import roadImage3 from '../../assets/img/journey/stop-03.svg'
import roadImage4 from '../../assets/img/journey/stop-04.svg'
import roadImage5 from '../../assets/img/journey/stop-05.svg'
import { CellToJourneyPart } from './Shutter';
import streetImage1 from '../../assets/img/journey/street-1.svg'
import streetImage2 from '../../assets/img/journey/street-2.svg'
import streetImage3 from '../../assets/img/journey/street-3.svg'
import streetImage4 from '../../assets/img/journey/street-4.svg'
import streetImage5 from '../../assets/img/journey/street-5.svg'
import ConvertJourney from './ConvertJourney';
import { isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';
import { getQueryParam } from "../../components/CounsellorUser/CounsellorsClientPortFolio/common/util";
import Banner1 from "../../assets/img/Banner/Banner1.svg"
import Banner2 from "../../assets/img/Banner/Banner2.svg";
import Banner3 from "../../assets/img/Banner/Banner3.svg";
import Banner4 from "../../assets/img/Banner/Banner4.svg";
import Banner5 from "../../assets/img/Banner/Banner5.svg";
import BannerMobile1 from "../../assets/img/BannerMobile/BannerMobile1.svg";
import BannerMobile2 from "../../assets/img/BannerMobile/BannerMobile2.svg";
import BannerMobile3 from "../../assets/img/BannerMobile/BannerMobile3.svg";
import BannerMobile4 from "../../assets/img/BannerMobile/BannerMobile4.svg";
import BannerMobile5 from "../../assets/img/BannerMobile/BannerMobile5.svg";
import BannerIframe1 from "../../assets/img/BannerIframe/BannerIframe1.svg";
import BannerIframe2 from "../../assets/img/BannerIframe/BannerIframe2.svg";
import BannerIframe3 from "../../assets/img/BannerIframe/BannerIframe3.svg";
import BannerIframe4 from "../../assets/img/BannerIframe/BannerIframe4.svg";
import BannerIframe5 from "../../assets/img/BannerIframe/BannerIframe5.svg";


import { fetchUserDetails } from '../../api/UserMetadata';

interface BasicJourneyBodyProps {
    userName: string;
    journeyPart: number;
    setShutter: (History: boolean) => void;
    setJourneyPart: (History: number) => void;
    standardJourney: any;
    hideNavbar:boolean;
    isIframe: boolean;
}

const BasicJourneyBody: React.FC<BasicJourneyBodyProps> = ({ userName, journeyPart, setShutter, setJourneyPart, standardJourney, isIframe, hideNavbar}) => {
    const [isVisible, setIsVisible] = useState(true);
    const navigate = useNavigate();
    const user_info = JSON.parse(getQueryParam('user_info') as string) || null;
    const banners = [Banner1, Banner2, Banner3, Banner4, Banner5];
    const bannersMobile = [BannerMobile1, BannerMobile2, BannerMobile3, BannerMobile4, BannerMobile5];
    const bannersIframe = [BannerIframe1,BannerIframe2,BannerIframe3,BannerIframe4,BannerIframe5 ];
    const [pcj_attempt,setpcjAttempt]=useState(0);
    const [isPremiumuser,setPremiumUser]=useState(false);
    const [pcjStatus,setPCJStatus]=useState('');
    const [version, setVersion] = useState(0);
    const [name, setName] = useState('');

    const cellToJourneyPart: { web: CellToJourneyPart; mobile: CellToJourneyPart } = {
        web: {
            31: 0, 36: 0,
            24: 1, 29: 1,
            16: 2, 21: 2, 22: 2,
            13: 3, 18: 3,
            7: 4, 12: 4
        },
        mobile: { 1: 0, 2: 1, 3: 2, 4: 3, 5: 4 }
    };

    const handleCellClick = (cellNumber: number) => {
        const journeyPart = !isMobile ? cellToJourneyPart.web[cellNumber] : cellToJourneyPart.mobile[cellNumber];
        if (journeyPart !== undefined) {
            setJourneyPart(journeyPart);
        }
    };
    const imageMapping: { [key: number]: string } = { 0: roadImage1, 1: roadImage2, 2: roadImage3, 3: roadImage4, 4: roadImage5 };
    const streetMapping: { [key: number]: string } = { 0: streetImage1, 1: streetImage2, 2: streetImage3, 3: streetImage4, 4: streetImage5 };
    const selectedImage = !isMobile ? (imageMapping[journeyPart] || roadImage1) : (streetMapping[journeyPart] || streetImage1);
    const fetchData = async () => {
        try {
            const email:any = localStorage.getItem('Email');
            const data:any = await fetchUserDetails(email, navigate);
            setpcjAttempt(data.pcj_count);
            setPremiumUser(data.userLevel === 'Premium');
            setPCJStatus(data.PremiumJourney.journey_status);
            setVersion(data.PremiumJourney.version);
            setName(data.name); // Add this line
        } catch (error) {
            console.error("Error fetching journey data:", error);
        }
    };
    useEffect(() => {
        fetchData();
        if (!isVisible) {
            setTimeout(() => setShutter(true), 500);
        }
    }, [isVisible, setShutter]);

    console.log(user_info ? user_info.name ? user_info.name : user_info.email : userName)
    return (
        <div className={`flex transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className={`flex flex-col justify-between md:gap-2 ${isMobile ? '' : 'h-full'} w-dvw`}>
                {!isIframe && <CreateNavbar page={'home'} />}
                {!isMobile
                    ? (
                        <div className="flex w-screen">
                            {!isIframe && <div className="flex flex-col justify-between w-2/5 ">
                                <div className='flex px-8 gap-1 text-base text-description-text items-center'>
                                    <img src={ArrowLeft} alt='ArrowLeft' className='size-6 cursor-pointer' onClick={() => setIsVisible(false)} />
                                </div>
                                <GridImageSteaper
                                    src={selectedImage}
                                    rows={10} // Adjust the number of rows as necessary
                                    cols={5} // Adjust the number of columns as necessary
                                    onCellClick={handleCellClick}
                                    containerClassName=""
                                    imageClassName=""
                                />
                            </div>}
                            <div className={`flex flex-col justify-start ${!isIframe && 'w-3/5 py-3'} gap-4 `}>
                                <div className={`flex ${!isIframe && 'pr-8'}`} >
                                    <div className='cointainer shadow-xl p-4 rounded-lg' style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}>
                                        <div className='flex gap-1 pb-2'>
                                            <div className='bg-blue-1000 w-1' />
                                            <div>
                                                <div className='text-xs'>Standard Career Journey for</div>
                                                <div className='font-bold text-xl leading-5 w-64 break-words'>
                                                    {name || (user_info ? user_info.name : userName)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' flex-grow overflow-y-auto' style={{ maxHeight: '74vh' }}>
                                            <ConvertJourney
                                                standardJourney={standardJourney}
                                                journeyPart={journeyPart}
                                                setJourneyPart={setJourneyPart}
                                                isIframe={isIframe}
                                                banners={banners}
                                                bannersMobile={isIframe ? bannersIframe : bannersMobile}
                                                pcj_attempt={pcj_attempt}
                                                isPremium={isPremiumuser}
                                                pcjStatus={pcjStatus} 
                                                version={version}/>
                                                
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    : (
                        <div className={`flex flex-col gap-4`} >
                            {!isIframe && <div className='flex justify-between pt-1'>
                                <div className='flex items-center cointainer shadow-xl p-3 rounded-lg' style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}><img src={MobileArrow} alt='ArrowLeft' className='size-4 cursor-pointer' onClick={() => setIsVisible(false)} /></div>
                                <GridImageSteaper
                                    src={selectedImage}
                                    rows={1} // Adjust the number of rows as necessary
                                    cols={5} // Adjust the number of columns as necessary
                                    onCellClick={handleCellClick}
                                    containerClassName=""
                                    imageClassName=""
                                />
                            </div>}
                            <div className=''>
                                <div className=' flex justify-center items-center'>
                                    <div className='cointainer shadow-xl p-4 rounded-lg' style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}>
                                        <div className='flex gap-1 pb-2'>
                                            <div className='bg-blue-1000 w-1' />
                                            <div>
                                                <div className='text-xs'>Standard Career Journey for</div>
                                                <div className='font-bold text-xl leading-5 w-64 break-words'>
                                                    {name || (user_info ? user_info.name : userName)}
                                                </div>
                                            </div>
                                        </div>
                                        <div className=' flex-grow overflow-y-auto' style={{ maxHeight: isIframe ? '80vh' : window.innerHeight < 860 ? '60vh' : '70vh' }}>
                                            <ConvertJourney
                                                standardJourney={standardJourney}
                                                journeyPart={journeyPart}
                                                setJourneyPart={setJourneyPart}
                                                isIframe={isIframe} 
                                                banners={banners}
                                                bannersMobile={bannersMobile}
                                                pcj_attempt={pcj_attempt}
                                                isPremium={isPremiumuser}
                                                pcjStatus={pcjStatus}
                                                version={version}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Footer for Mobile View */}
                            {!isIframe && <footer className='flex justify-center p-2 mt-2 text-xs text-gray-500'>
                                All rights reserved by @skilljourney {new Date().getFullYear()}
                            </footer>}
                        </div>
                    )}
            </div>
        </div>
    );
}

export default BasicJourneyBody;
