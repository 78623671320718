import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import CreateNavbar from '../components/Navbar/Navbar';
import checkIcon from '../assets/img/Comparison/checkIcon.png';
import Journey from '../assets/img/Contact/standard.svg';
import premium from '../assets/img/Contact/premium.svg';
import { useNavigate } from 'react-router-dom';
import { JourneyStatusCheck } from '../api/JourneyStatusCheck';
import { SectionWiseInsertion } from '../api/SectionWiseInsertion';
import { fetchUserDetails } from '../api/UserMetadata';
import { SnackbarUi } from '../components/Snackbar/SnackbarUi';
import { triggerStandardJourney } from '../api/DynamicQuestionnaire';
import { fetchCoupons } from '../api/InitiatePayment';
import CryptoJS from 'crypto-js';

const Comparison: React.FC = () => {
  const navigate = useNavigate();
  const [activeSectionIndex, setActiveSectionIndex] = useState<number | null>(null);
  const [selectedFile, setSelectedFile] = useState<any>(null);
  const [draftData, setDraftData] = useState<any>(null);
  const [searchLogs, setSearchLogs] = useState<any[]>([]);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [backendMessage, setBackendMessage] = useState("");
  const [initialJourneyStatus, setInitialJourneyStatus] = useState<
    string | null
  >(null)
  const [standardCouponCode, setStandardCouponCode] = useState<string>('');
  const [standardDiscount, setStandardDiscount] = useState<number>(0);
  const [dynamicStandardPrice, setDynamicStandardPrice] = useState<number>(199);

  const [premiumCouponCode, setPremiumCouponCode] = useState<string>('');
  const [premiumDiscount, setPremiumDiscount] = useState<number>(0);
  const [dynamicPremiumPrice, setDynamicPremiumPrice] = useState<number>(1199);

  // Load data from localStorage on component mount
  useEffect(() => {
    const storedActiveSectionIndex = localStorage.getItem("activeSectionIndex");
    const storedSelectedFile = localStorage.getItem("selectedFile");
    const storedDraftData = localStorage.getItem("draftData");
    const storedSearchLogs = localStorage.getItem("searchLogs");

    if (storedActiveSectionIndex) {
      setActiveSectionIndex(JSON.parse(storedActiveSectionIndex));
    }
    if (storedSelectedFile) {
      setSelectedFile(JSON.parse(storedSelectedFile));
    }
    if (storedDraftData) {
      setDraftData(JSON.parse(storedDraftData));
    }
    if (storedSearchLogs) {
      setSearchLogs(JSON.parse(storedSearchLogs));
    }
  }, []);

  useEffect(() => {
    let intervalId: NodeJS.Timeout
    if (initialJourneyStatus === 'initiated') {intervalId = setInterval(async () => {await checkUserJourneyStatus()}, 10000)}
    return () => {if (intervalId) clearInterval(intervalId)}
  }, [initialJourneyStatus])

  const checkUserJourneyStatus = async () => {
    try {
      const useremail = localStorage.getItem('Email')
      if (!useremail) return
      const userDetails = await fetchUserDetails(useremail, navigate)
      const currentStatus = userDetails.StandardJourney?.journey_status
      setInitialJourneyStatus(currentStatus)
      if (
        initialJourneyStatus === 'initiated' &&
        currentStatus === 'completed'
      ) {
        navigate('/myjourney')
      }
    } catch (error) {
      console.error('Error checking journey status:', error)
    }
  }

  const handleStandardJourneyClick = async () => {
    try {
      const useremail:any = localStorage.getItem("Email");
      if (!useremail) navigate('/login');

        const userDetails = await fetchUserDetails(useremail, navigate)
        const currentJourneyStatus = userDetails.StandardJourney?.journey_status

        const journeyCheck = await JourneyStatusCheck();
        if (currentJourneyStatus === "generating") {
          setIsSnackbarOpen(true);
          setBackendMessage("A journey is already under creation. Please wait for it to complete.");
          return;
        }

      navigate("/standardAdvantages");
    } catch (error) {
      setIsSnackbarOpen(true);
      setBackendMessage(error instanceof Error ? error.message : "An unexpected error occurred.");
    }
  };

  const handlePremiumJourneyClick = async () => {
    try {
      const useremail:any = localStorage.getItem("Email");
      if (!useremail) navigate('/login');
      
      navigate("/premiumAdvantages");
    } catch (error) {
      setIsSnackbarOpen(true);
      setBackendMessage(error instanceof Error ? error.message : "An unexpected error occurred.");
    }
  };

  const secretKey = "WjbO6aasKeNx1orwjtpNBuSfrD7lLcoFE3jPOnX2ApU=";
  const decryptCoupons = (encryptedData: { iv: string; ciphertext: string }) => {
    try {
      if (!secretKey) {
        throw new Error("Decryption key not found.");
      }
      const keyBytes = CryptoJS.enc.Utf8.parse(secretKey);
      let key: CryptoJS.lib.WordArray;
      if ([16, 24, 32].includes(keyBytes.sigBytes)) {
        key = keyBytes;
      } else {
        key = CryptoJS.SHA256(secretKey);
      }
      const iv = CryptoJS.enc.Base64.parse(encryptedData.iv);
      const ciphertext = CryptoJS.enc.Base64.parse(encryptedData.ciphertext);
      const cipherParams = CryptoJS.lib.CipherParams.create({ ciphertext });
      const decrypted = CryptoJS.AES.decrypt(cipherParams, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      });
      const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
      if (!decryptedText) {
        throw new Error("Decryption failed.");
      }
      const coupons = JSON.parse(decryptedText);
      return coupons;
    } catch (error) {
      console.error("Error decrypting coupons:", error);
      return null;
    }
  };

  const fetchAndHandleCoupons = async () => {
    try {
      const encryptedCoupons = await fetchCoupons();
      const decryptedCoupons = decryptCoupons(encryptedCoupons);
      if (decryptedCoupons) {
        if (decryptedCoupons.Standard_Coupon) {
          setStandardCouponCode(decryptedCoupons.Standard_Coupon.Coupon);
          setStandardDiscount(decryptedCoupons.Standard_Coupon.Value);
        }
        if (decryptedCoupons.Dynamic_Pricing && decryptedCoupons.Dynamic_Pricing.Standard) {
          setDynamicStandardPrice(decryptedCoupons.Dynamic_Pricing.Standard);
        }
        if (decryptedCoupons.Premium_Coupon) {
          setPremiumCouponCode(decryptedCoupons.Premium_Coupon.Coupon);
          setPremiumDiscount(decryptedCoupons.Premium_Coupon.Value);
        }
        if (decryptedCoupons.Dynamic_Pricing && decryptedCoupons.Dynamic_Pricing.Premium) {
          setDynamicPremiumPrice(decryptedCoupons.Dynamic_Pricing.Premium);
        }
      }
    } catch (error) {
      console.error("Error fetching premium coupons:", error);
    }
  };

  useEffect(() => {
    fetchAndHandleCoupons();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const standardDiscountAmount = ((dynamicStandardPrice * standardDiscount) / 100).toFixed(0);
  const discountedStandardPrice = dynamicStandardPrice - parseFloat(standardDiscountAmount);

  const premiumDiscountAmount = ((dynamicPremiumPrice * premiumDiscount) / 100).toFixed(0);
  const discountedPremiumPrice = dynamicPremiumPrice - parseFloat(premiumDiscountAmount);

  return (
    <div className="min-h-screen bg-white">
      <div className="top-0 left-0 right-0 z-20">
        <CreateNavbar page="home" />
      </div>
      <div className="flex flex-col items-center mt-5">
        <h2 className="text-lg md:text-3xl font-semibold text-back-prev-text mb-4 px-2 text-center">
          Find The Best Plan For Your Personalised Career Journey
        </h2>
        <div className="w-full max-w-4xl px-4 md:p-4">
          <div className=" border "style={{ boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)' }}>
            <table className="w-full bg-white text-center">
              <thead>
              <tr>
                <th className="px-1 md:px-4 py-2 border-b border-[#cccccc] text-xs md:text-base"></th>
                <th className="px-1 md:px-4 py-2 border-b border-[#cccccc] text-gray-700 font-bold text-xs md:text-base">
                  <div className="flex flex-col items-center">
                    <img src={Journey} alt="Standard Journey" className="w-4 md:w-8 mb-2" />
                    <span>Standard Journey</span>
                  </div>
                </th>
                <th
                  className="px-1 md:px-4 py-2 font-bold text-back-prev-text text-xs md:text-base border-l-2 border-r-2"
                  style={{ borderBottom: '1px solid #cccccc', borderTop: '2px solid #FCB017', borderColor: '#FCB017' }}
                >
                  <div className="flex flex-col items-center">
                    <img src={premium} alt="Premium Journey" className="w-4 md:w-8 mb-2" />
                    <span>Premium Journey</span>
                  </div>
                </th>
              </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Yearly Actionable Plan</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc]">
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Qtr. Actionable Plan</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Alt. Career Journeys</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc]">
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Profile Analysis</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">NorthStar</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#FCB017' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Industry Trends *</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#75916E' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Growth Opportunities</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}
                  >
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Summary</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}
                  >
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">
                    Job Matching <span className="text-[10px] md:text-xs">(Coming Soon)</span>
                  </td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}>                    
                  <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">
                    Journey Tracking <span className="text-[10px] md:text-xs"><br />(Coming Soon)</span>
                  </td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">—</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2"
                    style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}>
                    <img src={checkIcon} alt="Check Icon" className="w-3 md:w-5 h-3 md:h-5 mx-auto" />
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Report Pages</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Two - Three</td>
                  <td
                    className="px-1 md:px-4 py-1 md:py-2 border-l-2 border-r-2 text-xs md:text-base"
                    style={{ borderBottom: '1px solid  #cccccc', borderColor: '#0778B6' }}
                  >
                    Ten - Twelve
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] text-xs md:text-base">Pricing *</td>
                  <td className="px-1 md:px-4 py-1 md:py-2 border-b border-[#cccccc] font-semibold text-xs md:text-base">
                    <span className="line-through text-black underline">₹{dynamicStandardPrice}</span>
                    <span className="text-secondary"> ₹{discountedStandardPrice}/-</span>
                  </td>
                  <td className="px-1 md:px-4 py-1 md:py-2 font-semibold text-back-prev-text border-l-2 border-r-2 text-xs md:text-base"style={{ borderBottom: '1px solid #cccccc', borderColor: '#0778B6' }}>
                    <span className="line-through text-black underline">₹{dynamicPremiumPrice}</span>
                    <span className="text-secondary"> ₹{discountedPremiumPrice}/-</span>
                  </td>
                </tr>
                <tr>
                  <td className="px-1 md:px-4 py-2 md:py-4"></td>
                  <td className="px-1 md:px-4 py-2 md:py-4">
                    <button
                      className="bg-custom-orange text-white px-2 md:px-6 py-1 md:py-2 rounded-full font-semibold text-xs md:text-base"
                      onClick={handleStandardJourneyClick}
                    >
                      Standard Journey
                    </button>
                  </td>
                  <td className="px-1 md:px-4 py-2 md:py-4 border-l-2 border-r-2 "
                  style={{ borderBottom: '2px solid  #0778B6', borderColor: '#0778B6' }}>
                    <button className="bg-secondary text-white px-2 md:px-6 py-1 md:py-2 rounded-full font-semibold text-xs md:text-base"
                    onClick={handlePremiumJourneyClick}>
                      Premium Journey
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
       <SnackbarUi
        isSnackbarOpen={isSnackbarOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}
        backendMessage={backendMessage}
      />
    </div>
  );
};

export default Comparison;