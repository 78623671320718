import React, { useEffect, useRef, useState } from "react";
import { Tooltip } from "@mui/material";
import InfoTwoToneIcon from "@mui/icons-material/InfoTwoTone";
import ReactMarkdown from 'react-markdown';

interface InputTextAreaProps {
  label: string;
  name: string;
  tooltip?: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  isRequired: boolean;
  description?: string;
}

const InputTextArea: React.FC<InputTextAreaProps> = ({
  label,
  name,
  tooltip,
  value,
  onChange,
  isRequired,
  description,
}) => {
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [open, setOpen] = useState(false); 
  const [isExpanded, setIsExpanded] = useState(false);
  const toggleExpanded = () => setIsExpanded(prev => !prev);
  const tooltipRef = useRef<HTMLDivElement>(null); 
  const initialRows = 5; // Default number of rows
  const maxRows = 1500; // Maximum number of rows allowed

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const textarea = textAreaRef.current;

    if (textarea) {
      // Reset the rows to the initial value before recalculating
      textarea.rows = initialRows;

      // Calculate the number of rows based on scrollHeight and lineHeight
      const lineHeight =
        parseInt(window.getComputedStyle(textarea).lineHeight, 10) || 24; // Default lineHeight
      const newRows = Math.ceil(textarea.scrollHeight / lineHeight);

      // Prevent the textarea from growing beyond maxRows
      const rowsToSet = Math.min(newRows, maxRows);
      textarea.rows = rowsToSet; // Adjust rows based on content height
    }

    onChange(e); // Trigger the parent onChange function
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={{ zIndex: "999999999999999" }}>
      <div className="block mb-5 text-lg font-medium text-gray-900">
      <span>
        {label}
        {isRequired && "*"}
      </span>
        {tooltip && ( 
          <div ref={tooltipRef} className="inline-block ml-2">
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpen(false)}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={
                <span className="block leading-normal">
                  {tooltip}
                </span>
              }
              onClick={(e) => {setOpen(true);
                e.preventDefault();
                e.stopPropagation();
              }}
              componentsProps={{
                tooltip: {sx: {width: "350px",maxWidth: "400px"}},
              }}
            >
              <InfoTwoToneIcon sx={{color: "#0778B6",height: "3vh",width: "3vw",paddingBottom: "2px",cursor: "pointer"}}/>
            </Tooltip>
          </div>
        )}
      </div>
      {description && (
        <div className="bg-[#FEF5E6] text-black text-sm p-2 rounded-md my-1">
          <div className={`${!isExpanded ? 'line-clamp-2' : ''}`}>
            <ReactMarkdown>{description}</ReactMarkdown>
          </div>
          <span
            onClick={toggleExpanded}
            className="text-black cursor-pointer block text-right mt-1"
          >
            {isExpanded ? 'See less' : 'See more'}
          </span>
        </div>
      )}
      <textarea
        id={name}
        name={name}
        aria-label={label} 
        value={value}
        ref={textAreaRef}
        onChange={handleInput}
        required={isRequired}
        style={{
          overflowY: "auto",
          resize: "none", // Disable manual resizing
        }}
        rows={initialRows} // Initial rows count
        className="block p-2.5 w-full text-lg text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
      ></textarea>
    </div>
  );
};

export default InputTextArea;
