import { BackendApi } from "./apiConfig/ApiFormatter";

interface CreateMeetingPayload {
    subject: string;
    Date: string;
    Time: string;
    counsellor_email: string[];
    user_email: string[];
    order_id:any;
    content:any;
}

export const createMeeting = async (
    subject: string,
    Date: string,
    Time: string,
    counsellor_email: string[],
    user_email: string[],
    order_id:any,
    content:any
): Promise<any> => {
    const apiUrl = `${BackendApi()}createMeeting`;
    
    const payload: CreateMeetingPayload = {
        subject,
        Date,
        Time,
        counsellor_email,
        user_email,
        order_id,
        content
    };

    try {
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage
        const response = await fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `${idToken}`
            },
            body: JSON.stringify(payload)  // Convert the payload to JSON string
        });

        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        return data.data;
    } catch (error) {
        console.error('Failed to Schedule a meeting', error);
        throw error;  // Optionally, rethrow the error
    }
};
