import React, { useEffect, useState } from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Rating from '@mui/material/Rating'; // Import the Rating component from Material-UI
import { get_counsellor } from '../../../api/Counsellor';

// Define the structure for counselor data from the API
interface AvailableSlot {
  date: string;
  slots: string[];
}

interface CounselorApiResponse {
  email: string;
  name: string;
  contact: number | null;
  profile_url: string;
  bio: string;
  additional_info: string;
  address: string;
  available_slots: {
    AvailableDates: AvailableSlot[];
  }| null;
  specialty: string;
  rating: number; // Ensure rating is a number
}

// Define the structure for counselor data in the app
export interface Counselor {
  id: number;
  name: string;
  email: string;
  specialty: string;
  rating: number;
  photoUrl: string;
  bio: string;
  availableSlots: AvailableSlot[]; // Correctly reflect available slots
  additional_info: string;
}

const CounselorCarousel: React.FC<{}> = () => {
  const [counselors, setCounselors] = useState<Counselor[]>([]); // State for counselors
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  // Function to fetch and transform data
  const fetchData = async () => {
    try {
      setLoading(true); // Start loading
      
      const fetchedData: CounselorApiResponse[] = await get_counsellor(); // Fetch data from API
      // Transform data to fit the Counselor interface
      const transformedCounselors: Counselor[] = fetchedData.map((counselor, index) => ({
        id: index + 1, // Assign a unique ID
        name: counselor.name,
        email: counselor.email,
        specialty: counselor.specialty,
        rating: counselor.rating,
        photoUrl: counselor.profile_url,
        bio: counselor.bio,
        availableSlots: counselor.available_slots?.AvailableDates || [],
        additional_info: counselor.additional_info
      }));

      setCounselors(transformedCounselors); // Update state with transformed data
    } catch (error: any) {
      console.error(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div className='md:mx-3 mx-4 pt-3'>
        <div className='text-center'>
                <div className='md:text-3xl text-xl font-bold'><span className='text-sky-600'>Meet Our</span> <span className='text-custom-orange'>Expert Counsellors</span></div>
                <div className=''>Our diverse team of professionals will guide<br/> you to career success</div>
            </div>
    <div className=' text-white w-100 xl:my-4 my-1  bg-pattern bg-contain bg-no-repeat bg-right-bottom rounded-md'>
        <div className='p-8'>
			<Carousel
        responsive={{
            superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 2 },
            desktop: { breakpoint: { max: 3000, min: 1024 }, items: 3},
            tablet: { breakpoint: { max: 1024, min: 464 }, items: 2 },
            mobile: { breakpoint: { max: 464, min: 0 }, items: 1 },
        }}
        infinite={true}
        autoPlay={true}
        autoPlaySpeed={3000}
        itemClass="p-4" // Apply padding class here
        >
        {counselors.map((counselor) => (
            <div
            key={counselor.id}
            className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-xl flex flex-col justify-between h-[400px] mx-2 overflow-hidden"
            >
            <img
                src={counselor.photoUrl}
                alt={counselor.name}
                className="w-24 h-24 rounded-full border-4 border-orange-500 mx-auto mb-4" // Reduced image size
            />
            <h3 className="text-lg md:text-xl font-semibold mb-2 text-gray-800 text-center truncate">{counselor.name}</h3> {/* Adjusted text size */}
            <p className="text-gray-600 text-center mb-2 truncate">{counselor.specialty}</p> {/* Added truncate */}
            <div className="text-center mb-2 flex flex-col items-center">
                <div className="flex items-center mt-1">
                <div className="text-gray-600 text-center mb-2">
                    {/* Rating label if needed */}
                </div>
                <div className="py-1 pl-2">
                    <Rating
                    name={`rating-${counselor.id}`}
                    value={counselor.rating}
                    precision={0.5}
                    readOnly
                    size="small"
                    />
                </div>
                </div>
            </div>
            <p className="text-gray-600 text-center mb-4 text-xs md:text-sm overflow-hidden text-ellipsis">{counselor.bio}</p> {/* Added text ellipsis */}
            </div>
        ))}
        </Carousel>

         </div>
        </div>
    </div>

  );
};

export default CounselorCarousel;
