import { BackendApi } from "./apiConfig/ApiFormatter";

export const updateUserAnswer = async (token: string, userAnswerData: any, selectedFile: any | null) => {
  const apiUrl = `${BackendApi()}updateUserAnswer`;

  const formDataObj = new FormData();

  if (selectedFile) {
    formDataObj.append('file', selectedFile);
  }

  formDataObj.append('data', JSON.stringify(userAnswerData));

  try {
    const response = await fetch(apiUrl, {
      method: 'PUT',
      headers: {
        Authorization: `${token}`, // Note: Don't set 'Content-Type', FormData handles it automatically
      },
      body: formDataObj,
    });

    const responseData = await response.json();

    if (!response.ok) {
      return {
        success: false,
        errorResponse: responseData.message || responseData.error,
      };
    }

    return { success: true, data: responseData };
  } catch (error) {
    console.error('Failed to update user answer:', error);
    throw error;
  }
};

export const fetchUserAnswer = async (token: string, email: string) => {
  const apiUrl = `${BackendApi()}fetchUserAnswer`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${token}`,
      },
      body: JSON.stringify({ email }),  
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to fetch user answer:', error);
    throw error;
  }
};

export const triggerStandardJourney = async (token: string, email: string) => {
  const apiUrl = `${BackendApi()}triggerStandardJourney`;

  try {
    const response = await fetch(apiUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ email }),
    });

    if (!response.ok) {
      const errorResponse = await response.json();
      console.error('API Error:', errorResponse);
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Failed to trigger standard journey:', error);
    throw error;
  }
};

